import { createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';

const getInfo = createAsyncAction<{ id: string, shopId?: string | null }>(PREFIX_ACTIONS + 'getInfo');

const giftCardPaidActions = {
  getInfo,
};

export default giftCardPaidActions;

