import Text from 'components/Text';
import checkInActions from 'features/checkIn/services/actions';
import checkInApis from 'features/checkIn/services/apis';
import { TICKET_TYPE } from 'features/checkIn/services/constants';
import checkInSelectors from 'features/checkIn/services/selectors';
import { IAppointmentCustomerDetail } from 'features/checkIn/services/types/customer';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ShopInfo from 'widgets/ShopInfo';
import Welcome from '../../components/Welcome';
import iconCalendar from './icon-calendar.png';
import iconClock from './icon-clock.png';
import toast from 'utils/toast';
import { IInfoPrinterWaitingResData } from 'features/checkIn/services/types/ticket';
const ChooseTypeCheckIn = () => {
  const { shop_id = '', station_number = '', } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const setLoadingPage = useSetLoadingPage();
  const customer = checkInSelectors.getCustomerData();

  const handleClickWalkIn = () => {
    navigate(`/store/${shop_id}/check-in/${station_number}/choose-service`);
    dispatch(checkInActions.reset());
  };

  const handleAppointment = async () => {
    if (!customer?.id) return;
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<IAppointmentCustomerDetail> = await checkInApis.getCustomerAppointment(customer.id, station_number);
      const data = res.data.data;
      if (data) {
        const type = TICKET_TYPE.PENDING_TICKET;
        const id = data.id;
        const res2: IResponseDataBody<IInfoPrinterWaitingResData> = await checkInApis.getInfoPrintWaiting(type, id);
        if (res2.data.data) {
          dispatch(checkInActions.getInfoCheckInTicket.success(res2.data.data));
          navigate(`/store/${shop_id}/check-in/${station_number}/success/${id}/${type}`);
        }
      }
    } catch (error) {
      toast.info('not available appointment, please select services!');
      handleClickWalkIn();
    }
    finally {
      setLoadingPage(false);
    }

  };

  return (
    <Container>
      <ShopInfo />
      <Welcome message='Please select client type' />
      <Content>
        <button className="box-type walk-in" onClick={handleClickWalkIn}>
          <div className='content'>
            <img src={iconClock} />
            <Text className="text">Walk-In</Text>
          </div>
        </button>
        <button className="box-type appointment" onClick={handleAppointment}>
          <div className='content'>
            <img src={iconCalendar} />
            <Text className="text">Appointment</Text>
          </div>
        </button>
      </Content>
    </Container>
  );
};

export default ChooseTypeCheckIn;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;
`;
const Container = styled.div`
  .text {
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .box-type {
    display: flex;
    width: 100%;
    height: 136px;
    padding: 24px 46px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
    }
    &.walk-in {
      border-radius: 5px;
      background: #F5767F;
      .text {
        color: #FFF;
      }
    }
    &.appointment {
      border-radius: 5px;
      background: #C6E9EF;
      .text {
        color: #488C98;
      }
    }
    img {
      width: 60px;
      height: 60px;
    }
  }
`;