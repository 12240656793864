import { styled } from 'styled-components';

const PageStyled = styled.div`
    background: #F7F7F7;
    min-height: 100vh;
    .banner {
        margin-bottom: 2rem;
    }
    @media only screen and (max-width: 768px) {
      .banner {
        margin-bottom: 8px;
      }
    }
`;

export default PageStyled;
