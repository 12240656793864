
export const NAME_REDUCER = 'bill';

export const PREFIX_ACTIONS = 'bill_feature_';


export const PATH_LOADING = {
  getData: `loading.${NAME_REDUCER}.getData`,
};

export enum PAYMENT_TYPE {
  CASH = 'CASH',
  CREDIT_CARD = 'CREDIT_CARD',
  CHECK = 'CHECK',
  GIFT_CARD = 'GIFT_CARD',
  LOYALTY_POINT = 'LOYALTY_POINT',
}

export enum CREDIT_CARD_METHODS {
  MANUAL = 'MANUAL',
  X_TERMINAL = 'X_TERMINAL',
  SWIPE = 'SWIPE'
}

export enum OTHER_PAYMENT {
  CHECK = 'CHECK',
  VENMO = 'VENMO',
  CASH_APPS = 'CASH_APPS',
  ZELLE = 'ZELLE',
  PAYPAL = 'PAYPAL',
}

export const ParamsStatus = {
  OPEN_TICKET: 'OPEN_TICKET',
  CLOSED: 'CLOSED',
  VOID: 'VOID',
  REFUND: 'REFUND',
  DELETE: 'DELETE',
  CLEAR: 'CLEAR',
  MERGED: 'MERGED',
};
