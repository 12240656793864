
import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import loading from './animation_lntwnftg.json';
import styled from 'styled-components';

function Loading() {
  return (
    <>
      <Player
        autoplay={true}
        loop={true}
        controls={true}
        src={loading}
        style={{ height: '300px', width: '300px' }}
      ></Player>
    </>
  );
}

export default Loading;

export const LoadingFull = () => (
  <LoadingFullStyled>
    <Loading />
  </LoadingFullStyled>
);

const LoadingFullStyled = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;