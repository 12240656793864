import Icon from 'assets/Icons';
import React, { CSSProperties } from 'react';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import { formatPhone } from 'utils/formatPhone';
import banner from './banner.png';
type Props = {
  style?: CSSProperties;
};
const ShopInfo = ({ style }: Props) => {
  const shopInfo = shopSelectors.getShopInfo();

  return (
    <Banner className='banner' banner={banner} style={style}>
      <div className="box">
        <Icon type='logo' />
        <div className="title">{shopInfo?.shopName || ''}</div>
        <p className='info'>{shopInfo?.address || '--'}</p>
        <p className='info'>{[shopInfo?.city, shopInfo?.state, shopInfo?.zip].filter(o => !!o).join(', ')}</p>
        <p className='info'>{formatPhone(shopInfo?.phone || '')}</p>
      </div>
    </Banner>
  );
};

export default ShopInfo;


const Banner = styled.div<{ banner: string }>`
display: flex;
justify-content: center;
align-items: center;
position: relative;
background: ${({ banner }) => `url(${banner})`};
padding: 1.125rem 0;
background-repeat: no-repeat;
background-size: cover;

  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.40);
    backdrop-filter: blur(15px); 
    padding: 1rem 0;
    width: 40%;
  }

  .title {
    color: #FFF;
    text-align: center;
    font-family: Poller One;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .info {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  @media only screen and (max-width: 768px) {
    .box {
      width: 80%;
      svg {
        width: 30%;
      }
      .title {
        font-size: 16px;
      }
      .info {
        font-size: 13px;
        padding: 0 8px;
      }
    }
    .section-title {
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .container {
      padding: 0 8px;
    }
    .gift-card-item {
      .content {
        text-align: left;
        padding: 0.5rem 0.5rem;
        .name {
          font-size: 12px;
        }
        .desc {
          font-size: 10px;
        }
      }
    }
  }
`;
