import { Form, Input, InputRef, Row, Spin } from 'antd';
import Button from 'components/Button';
import PhoneInput from 'components/PhoneInput';
import Text from 'components/Text';
import checkInActions from 'features/checkIn/services/actions';
import checkInApis from 'features/checkIn/services/apis';
import { ISignUpForm } from 'features/checkIn/services/types/api';
import { ICustomerSignInResData, IStateCustomerInfo } from 'features/checkIn/services/types/customer';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import shopActions from 'services/shop/actions';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatPhone } from 'utils/formatPhone';
import ShopInfo from 'widgets/ShopInfo';
import Welcome from '../components/Welcome';
export type ICustomerInfo = {
  id?: string | null;
  phone?: string;
  name?: string;
  point?: number;
};

type IFormCustomerValues = {
  phone: string;
  name: string;
};
const InfoCustomer = () => {
  const { shop_id = '' } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [cusInfo, setCusInfo] = useState<ICustomerInfo | null>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const setLoadingPage = useSetLoadingPage();
  const inputName = useRef<InputRef>(null);

  useEffect(() => {
    dispatch(shopActions.init.checkInOnline(shop_id || ''));
  }, []);

  const handleDone = (data: IStateCustomerInfo) => {
    dispatch(checkInActions.setCustomerInfo(data));
    navigate('choose-type');
  };

  const handleCreate = async (data: ICustomerInfo) => {
    setLoadingPage(true);
    const payload: ISignUpForm = {
      phone: data.phone || null,
      name: data.name || null,
      email: null,
      birthday: null,
    };
    try {
      const res: IResponseDataBody<ICustomerSignInResData> = await checkInApis.customerSignUp(payload);
      const info = res.data.data;
      if (info) {
        const cus: IStateCustomerInfo = {
          id: info.id,
          name: info.name,
          phone: info.phone,
          point: info.point,
        };
        handleDone(cus);
      }
    } catch (error) {

    } finally {
      setLoadingPage(false);
    }

  };

  const handleFinish = (values: IFormCustomerValues) => {
    const cusData: ICustomerInfo = {
      id: cusInfo?.id || null,
      name: values.name,
      phone: values.phone,
    };

    if (!cusInfo?.id) return handleCreate(cusData);

    const info: IStateCustomerInfo = {
      id: cusInfo.id,
      name: cusInfo.name || '',
      phone: cusInfo.phone || '',
      point: cusInfo.point || 0,
    };
    return handleDone(info);
  };

  const _debounce = useCallback(debounce(async (text: string) => {
    try {
      const res: IResponseDataBody<ICustomerInfo | null> = await checkInApis.getCustomerInfo(text, shop_id);
      const resData = res?.data?.data;
      if (resData) {
        setCusInfo(resData);
        form.setFieldValue('name', resData.name);
        form.validateFields();
      } else {
        setCusInfo({});
        form.setFieldValue('name', '');
        // setTimeout(() => inputName.current?.focus(), 100);
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  }, 200), []);

  const handleChangePhoneInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCusInfo(null);
    const rawPhone = e.target.value?.replace(/[^0-9]/g, '');

    if (rawPhone?.length < 10) return;
    setLoading(true);
    _debounce(rawPhone);
  };

  return (
    <Container>
      <ShopInfo />
      <ContentStyled>
        <Welcome />
        <Spin spinning={loading}>
          <Form form={form} layout='vertical' onFinish={handleFinish} validateTrigger={['onSubmit']}  >
            <Content>
              <Row>
                <Form.Item className='w-100' label="" name={'phone'} rules={[
                  {
                    validateTrigger: ['onSubmit'],
                    validator(rule, value) {
                      if (!value) return Promise.reject(new Error('Please enter your phone number'));
                      const rawPhone = value?.replace(/[^0-9]/g, '');

                      if (rawPhone?.length < 10) return Promise.reject(new Error('Invalid phone number'));

                      return Promise.resolve();
                    },
                  }
                ]}>
                  <PhoneInput iconPhone placeholder={'Phone number'} isNormal onChange={handleChangePhoneInput} />
                </Form.Item>
              </Row>
              {
                cusInfo ? <>
                  {cusInfo.id ?
                    <Row className='w-100'>
                      <Form.Item name={'name'} noStyle />
                      <Row justify={'space-between'} className='w-100'>
                        <Text className='customer-name'>{cusInfo.name}</Text>
                        <Text className='customer-phone'>{formatPhone(cusInfo.phone || '')}</Text>
                      </Row>
                    </Row>
                    : <Row>
                      <Form.Item className='name-input' label='' name={'name'} rules={[{ required: true, message: 'Please enter your name' }]}>
                        <Input ref={inputName} placeholder='Name' prefix={<IconAccount />} />
                      </Form.Item>
                    </Row>}
                </> : null
              }
            </Content>
            <Actions>
              <Button htmlType='submit' ntype='BLACK'>
                Continue
              </Button>
            </Actions>
          </Form>
        </Spin>
      </ContentStyled>
    </Container>
  );
};

export default InfoCustomer;
const Container = styled.div`
  min-height: 100vh;
`;
const ContentStyled = styled.div`
  max-width: 400px;
  
  .header-content {
    padding: 1.5rem 0;
  }
`;
const Content = styled.div`
  min-height: 60vh;
  padding: 0 1rem;
  .w-100 {
    width: 100%;
  }
  .customer-name, .customer-phone {
    color: #1D2129;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 137.5%; /* 22px */
  }
  .customer-phone {
    color: #1D2129;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 137.5%; /* 22px */
  }
  .name-input {
    width: 100%;
    input {
      height: auto;
    }
    .ant-input-affix-wrapper {
      height: 44px;
      .ant-input-prefix {
        margin-inline-end: 8px;
      }
    }
  }
`;
const Actions = styled(Row)`
  position: sticky;
  bottom:0;
  right:0;
  left:0;
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 12px;
  border-top: 1px solid #E5E5E5;
  background: #FFF;
  button {
    flex: 1;
  }
`;

const IconAccount = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M7 3.75C6.30964 3.75 5.75 4.30964 5.75 5C5.75 5.69036 6.30964 6.25 7 6.25C7.69036 6.25 8.25 5.69036 8.25 5C8.25 4.30964 7.69036 3.75 7 3.75ZM4.25 5C4.25 3.48122 5.48122 2.25 7 2.25C8.51878 2.25 9.75 3.48122 9.75 5C9.75 6.51878 8.51878 7.75 7 7.75C5.48122 7.75 4.25 6.51878 4.25 5Z" fill="#1D2129" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.507 10.2566C3.86288 10.5787 3.75002 10.8848 3.75002 11C3.75002 11.6045 3.78851 11.6972 3.93979 11.8067C4.06184 11.895 4.31312 12.0134 4.82033 12.1047C5.32182 12.1949 6.02281 12.25 7.00002 12.25C7.97724 12.25 8.67823 12.1949 9.17972 12.1047C9.68692 12.0134 9.93821 11.895 10.0603 11.8067C10.2115 11.6972 10.25 11.6045 10.25 11C10.25 10.8848 10.1372 10.5787 9.49303 10.2566C8.89375 9.95697 8.01339 9.75 7.00002 9.75C5.98663 9.75 5.10627 9.95697 4.507 10.2566ZM3.83619 8.91495C4.68463 8.49073 5.80427 8.25 7.00002 8.25C8.19576 8.25 9.31539 8.49073 10.1638 8.91495C10.9674 9.31674 11.75 10.0106 11.75 11C11.75 11.0202 11.7501 11.041 11.7501 11.0625C11.7517 11.5729 11.7543 12.4322 10.9398 13.0218C10.5618 13.2953 10.0631 13.4698 9.44533 13.581C8.82182 13.6932 8.02281 13.75 7.00002 13.75C5.97724 13.75 5.17823 13.6932 4.55472 13.581C3.93692 13.4698 3.43821 13.2953 3.06025 13.0218C2.24578 12.4322 2.24836 11.5729 2.24989 11.0625C2.24996 11.041 2.25002 11.0202 2.25002 11C2.25002 10.0106 3.03259 9.31675 3.83619 8.91495Z" fill="#1D2129" />
    <path fillRule="evenodd" clipRule="evenodd" d="M18.8284 1.17157C17.6569 -1.14441e-07 15.7712 0 12 0H8C4.22876 0 2.34315 -1.14441e-07 1.17157 1.17157C1.14441e-07 2.34315 0 4.22876 0 8C0 11.7712 1.14441e-07 13.6569 1.17157 14.8284C2.34315 16 4.22876 16 8 16H12C15.7712 16 17.6569 16 18.8284 14.8284C20 13.6569 20 11.7712 20 8C20 4.22876 20 2.34315 18.8284 1.17157ZM17.7677 2.23224L17.7677 2.2322C17.5071 1.9716 17.1122 1.75716 16.1892 1.63307C15.2231 1.50319 13.928 1.5 12 1.5H8C6.07197 1.5 4.77686 1.50319 3.81078 1.63307C2.88787 1.75715 2.49287 1.97159 2.23223 2.23223C2.23223 2.23223 2.23223 2.23223 2.23223 2.23223M2.23223 2.23223C1.97159 2.49287 1.75715 2.88787 1.63307 3.81078C1.50319 4.77686 1.5 6.07197 1.5 8C1.5 9.928 1.50319 11.2231 1.63307 12.1892C1.75716 13.1122 1.9716 13.5071 2.2322 13.7677L2.23224 13.7677C2.49288 14.0284 2.88787 14.2428 3.81079 14.3669C4.77686 14.4968 6.07197 14.5 8 14.5H12C13.928 14.5 15.2231 14.4968 16.1892 14.3669C17.1122 14.2428 17.5071 14.0284 17.7677 13.7678L17.7678 13.7677C18.0284 13.5071 18.2428 13.1122 18.3669 12.1892C18.4968 11.2231 18.5 9.92801 18.5 8C18.5 6.07197 18.4968 4.77686 18.3669 3.81079C18.2428 2.88787 18.0284 2.49288 17.7677 2.23224" fill="#1D2129" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.25 8C12.25 7.58579 12.5858 7.25 13 7.25H17C17.4142 7.25 17.75 7.58579 17.75 8C17.75 8.41421 17.4142 8.75 17 8.75H13C12.5858 8.75 12.25 8.41421 12.25 8Z" fill="#1D2129" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.25 5C11.25 4.58579 11.5858 4.25 12 4.25H17C17.4142 4.25 17.75 4.58579 17.75 5C17.75 5.41421 17.4142 5.75 17 5.75H12C11.5858 5.75 11.25 5.41421 11.25 5Z" fill="#1D2129" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.25 11C13.25 10.5858 13.5858 10.25 14 10.25H17C17.4142 10.25 17.75 10.5858 17.75 11C17.75 11.4142 17.4142 11.75 17 11.75H14C13.5858 11.75 13.25 11.4142 13.25 11Z" fill="#1D2129" />
  </svg>
);
