export const CreditCard = (props: any) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1196 4.0065C12.9996 3.44437 14.3062 3.62357 14.9078 4.50357C15.4604 5.55104 15.9969 6.6081 16.5025 7.6801C13.9542 7.7025 11.406 7.6961 8.8577 7.71744C7.46783 8.01397 6.2209 9.29184 6.3009 10.7713C6.2945 13.1905 6.2753 15.6097 6.31477 18.0278C6.2945 18.4822 6.49397 18.8929 6.66677 19.2982C5.9553 19.4337 5.1809 19.2172 4.68597 18.6796C4.44063 18.3734 4.28917 18.0065 4.10463 17.6641C3.11263 15.6929 2.11637 13.7249 1.15743 11.7388C0.802234 11.055 0.856634 10.1537 1.34943 9.5489C1.58623 9.19157 1.99903 9.03904 2.36063 8.84597C5.59797 7.20224 8.85023 5.5873 12.1196 4.0065Z"
        fill="#1D2129"
      />
      <path
        d="M11.1339 9.00489C14.4608 9.00489 17.7888 8.92702 21.1157 9.02516C22.1973 8.96222 23.1893 10.0268 23.0603 11.0934C23.0517 13.6609 23.0741 16.2294 23.0496 18.7958C22.8096 19.4646 22.2923 20.1089 21.5605 20.2444C20.9408 20.3169 20.3157 20.3062 19.6928 20.3169C16.2965 20.3084 12.8981 20.3585 9.50188 20.2849C8.44054 20.3318 7.41974 19.3004 7.56694 18.2316C7.56801 15.8177 7.57014 13.4038 7.56694 10.99C7.45708 10.1057 8.19734 9.33769 8.98988 9.07635C9.69814 8.98142 10.4192 9.04009 11.1339 9.00489ZM8.73708 11.2801C13.1712 11.2876 17.6053 11.279 22.0395 11.2844C22.0448 10.9846 22.0107 10.6454 21.7696 10.4364C21.5467 10.2038 21.1957 10.2753 20.9077 10.2497C17.0656 10.2401 13.2224 10.2454 9.38028 10.2465C8.91628 10.3286 8.71468 10.8534 8.73708 11.2801ZM8.76908 13.2257C8.75734 14.9356 8.76054 16.6465 8.78294 18.3564C8.74561 18.7041 9.06241 18.9036 9.30348 19.0849C12.8533 19.0785 16.4021 19.0764 19.952 19.071C20.4416 19.0604 20.9333 19.0806 21.4208 19.0316C21.7227 19.0092 21.9509 18.7233 22.0192 18.4524C22.032 16.686 22.0203 14.9206 22.0245 13.1542C18.6304 13.1553 15.2352 13.1521 11.84 13.1553C10.8171 13.1884 9.78988 13.1276 8.76908 13.2257Z"
        fill="#1D2129"
      />
      <path
        d="M9.66422 17.4454C9.33142 16.6912 9.88289 15.8048 10.669 15.6747C11.1298 15.6139 11.5405 15.8752 11.9064 16.0982C12.332 15.6128 13.1053 15.5424 13.6269 15.9104C14.2456 16.3606 14.3362 17.3984 13.74 17.903C13.2365 18.4267 12.3202 18.3286 11.8552 17.8038C11.2664 18.5184 9.97035 18.3392 9.66422 17.4454Z"
        fill="#1D2129"
      />
    </svg>
  );
};
