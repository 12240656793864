import moment from 'moment';
import { IStaffItemData } from 'services/shop/types/staff';

export const NAME_REDUCER = 'appointment';

export const PREFIX_ACTIONS = 'appointment_feature_';


export const PATH_LOADING = {
  getAppointments: `loading.${NAME_REDUCER}.getAppointments`,
  getStaffs: `loading.${NAME_REDUCER}.getStaffs`,
  getLockBreakTimes: `loading.${NAME_REDUCER}.getLockBreakTimes`,
  initServices: `loading.${NAME_REDUCER}.initServices`,
  getDetailApt: `loading.${NAME_REDUCER}.getDetailApt`,
};

export const CALENDAR_DISTANCE_TIMELINE = {
  ONE_HOUR: 60,
  HAlF_HOUR: 30,
  QUARTER_HOUR: 15,
};

export enum CALENDAR_VIEW_TYPE {
  DAY_VIEW = 'DAY_VIEW',
  WEEK_VIEW = 'WEEK_VIEW',
  MONTH_VIEW = 'MONTH_VIEW',
}

export enum STAFF_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
export enum STAFF_TYPE {
  ADMIN = 'ADMIN',
  OTHER = 'OTHER',
  TECHNICAL = 'TECHNICAL',
  CASHIER = 'CASHIER',
}

export enum APPOINTMENT_LAYOUT {
  CALENDAR = 'CALENDAR',
  LIST = 'LIST',
}

export enum CALENDAR_TIME_TYPE {
  BLOCK = 'BLOCK',
  BREAK = 'BREAK',
}

export enum CALENDAR_ITEM_TYPES {
  APPOINTMENT = 'APPOINTMENT',
  // MULTIPLE_APPOINTMENT = 'MULTIPLE_APPOINTMENT',
  APPOINTMENT_CANCEL = 'APPOINTMENT_CANCEL',
  // APPOINTMENT_NO_SHOW = 'APPOINTMENT_NO_SHOW',
  BREAK_STAFF = 'BREAK_STAFF',
  BLOCK_HOURS = 'BLOCK_HOURS',
}
export const listCalendarItemTypes = [
  {
    label: 'Appointments',
    value: CALENDAR_ITEM_TYPES.APPOINTMENT,
    fill: '#C6E9EF',
    stroke: '#6FABB6',
  },
  {
    label: 'Delete',
    value: CALENDAR_ITEM_TYPES.APPOINTMENT_CANCEL,
    fill: '#F8D9BB',
    stroke: '#FF7D00',

  },
  {
    label: 'On Break',
    value: CALENDAR_ITEM_TYPES.BREAK_STAFF,
    fill: '#FEEDBF',
    stroke: '#FFDC7C',

  },
  {
    label: 'Block Hours',
    value: CALENDAR_ITEM_TYPES.BLOCK_HOURS,
    fill: '#ADADAD',
    stroke: '#ADADAD',
  },
];

export const daysOfWeek = Array.from(Array(7).keys()).map(o => {
  const day = moment().set('day', o + 1);
  return ({
    label: day.format('dddd'),
    value: day.isoWeekday(),
  });
});

export enum APPOINTMENT_SOURCE_TYPE {
  WEBSITE = 'WEBSITE',
  CALl_IN = 'CALL_IN'
}

export const CALENDAR_TIME_SET = {
  open: moment().set({ hour: 8, minute: 0, second: 0 }),
  close: moment().set({ hour: 22, minute: 0, second: 0 }),
};

export enum PROLONGED_TIME_STATUS {
  ADD = 'ADD',
  MINUS = 'MINUS',
}

export enum APPOINTMENT_STATUS {
  NEW = 0,
  CONFIRM = 1,
  CANCEL = 2,
  NO_SHOW = 3,
  DELETE = 4,
}

export const ANY_BODY: IStaffItemData = {
  id:'any-body',
  firstName:'Anybody',
  lastName:'',
  anyBody:true,
  birthday:'',
  createdDate:'',
  email:'',
  phone:'',
  staffSalaryType:'',
  staffType:'',
  status:'',
  urlImage:'',
};