import React from 'react';
import { getQueryStrings } from 'utils/stringQuery';
import BuyGiftCardFail from './BuyGiftCardFail';
import BuyGiftCardSuccess from './BuyGiftCardSuccess';
import { IPaymentGifCardResult } from './services/types/giftCard';
type IBuyGiftCardCompletedProps = any;

const BuyGiftCardCompleted: React.FC<IBuyGiftCardCompletedProps> = () => {
  const query = getQueryStrings<IPaymentGifCardResult>();

  if (query.responseCode === '200') return <BuyGiftCardSuccess data={query} />;

  return <BuyGiftCardFail />;
};

export default BuyGiftCardCompleted;

