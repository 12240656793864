import { Col, Row } from 'antd';
import Text from 'components/Text';
import set from 'lodash/set';
import { FC, SVGProps as RNSvgProps } from 'react';
import { styled } from 'styled-components';
import iconBack from './back';
import logo from './logo';
import tick from './tick';
import next from './next';
import checkOutline from './checkOutline';

export type SVGprops = RNSvgProps<any>;
// eslint-disable-next-line
const getIcon = (IconComponent: FC<SVGprops>) => (props: SVGprops) =>
  <IconComponent {...props} />;

const data = {
  iconBack,
  back: iconBack,
  logo,
  tick,
  next,
  checkOutline
};
type IResult = {
  [key in keyof typeof data]: FC;
};
const mappingIcons = () => {
  const result = {};
  for (const [key, value] of Object.entries(data)) {
    set(result, [key], getIcon(value));
  }
  return result as IResult;
};

const icons = mappingIcons();
export type typeIcon = keyof typeof icons;

interface Props extends SVGprops {
  type: typeIcon;
}
const Icon: FC<Props> = ({ type, ...props }) => {
  return icons[type](props);
};

export default Icon;

export const IconStoryBoard = () => {
  return (
    <>
      <Text variant="H3">Icons</Text>
      <IconStoryBoardStyled>
        <Row gutter={[25, 25]}>
          {Object.keys(data).map((key: any) => (
            <Col key={key} span={6}>
              <Icon type={key} />
              <Text variant="H9">{key}</Text>
            </Col>
          ))}
        </Row>
      </IconStoryBoardStyled>
    </>
  );
};
const IconStoryBoardStyled = styled.div`
  padding: 2%;
  margin: 2%;
  border: 2px solid black;
  background: rgba(0, 0, 0, 0.125);
`;
