import PrivateLayout from 'components/layout/PrivateLayout';
import useRefreshToken from 'hooks/useRefreshToken';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import GiftCardDetail from './GiftCardDetail';
import NotFoundPage from './NotFound';
import BookingOnlinePage from './booking';
import BookingCompleted from './booking/BookingCompleted';
import BookingDetail from './booking/Detail';
import CheckInShieldLayout from './checkIn/pages/CheckInShieldLayout';
import ChooseServicesCheckIn from './checkIn/pages/ChooseServices';
import ChooseTypeCheckIn from './checkIn/pages/ChooseTypeCheckIn';
import InfoCustomer from './checkIn/pages/InfoCustomer';
import CouponPage from './coupon';
import ReceiptPage from './receipt';
import BuyGiftCardPage from './shopping/GiftCard/BuyGiftCard';
import BuyGiftCardCompleted from './shopping/GiftCard/BuyGiftCardCompleted';
import GiftCardListPage from './shopping/GiftCard/GiftCardList';
import ChooseStaffCheckIn from './checkIn/pages/ChooseStaff';
import SuccessCheckIn from './checkIn/pages/Success';
import CreditPayment from './payment/pages/CreditPayment';
import CreditPaymentSuccess from './payment/pages/Success';
import CreditPaymentFail from './payment/pages/Fail';
import ConfirmSuccessCus from './payment/pages/ConfirmSuccess';
import WrappingPayment from './payment/components/WrappingPayment';
import ReviewDetail from './review';
import SalonSummaryReport from './report/SalonSummaryReport';

const RootScreens = () => {
  const { shield } = useRefreshToken();
  return (
    <React.Fragment>
      <Routes>
        <Route path="store/:shop_id" element={shield(PrivateLayout)}>
          <Route path="bill/:bill_id">
            <Route path="receipt" element={<ReceiptPage />} />
          </Route>
          <Route path="review/:review_id" element={<ReviewDetail />} />
          <Route path="coupon/:coupon_id" element={<CouponPage />} />
          <Route path="booking">
            <Route path="detail/:booking_id" element={<BookingDetail />} />
            <Route path="online" element={<BookingOnlinePage />} />
            <Route path="online/completed" element={<BookingCompleted />} />
          </Route>
          <Route path="shopping/gift-card" element={<GiftCardListPage />} />
          <Route path="gift-card/:id" element={<GiftCardDetail />} />
          <Route path="shopping/gift-card/:id">
            <Route path="" element={<BuyGiftCardPage />} />
            <Route path="completed" element={<BuyGiftCardCompleted />} />
          </Route>
          <Route path="payment/:ticketId" element={<WrappingPayment />}>
            <Route path="" element={<CreditPayment />} />
            <Route path="add-tip">
              <Route path="" element={<CreditPaymentSuccess />} />
              <Route path="success" element={<ConfirmSuccessCus />} />
            </Route>
            <Route path="fail" element={<CreditPaymentFail />} />
          </Route>
          <Route path='check-in/:station_number' element={<InfoCustomer />} />
          <Route path='check-in/:station_number' element={<CheckInShieldLayout />}>
            <Route path='choose-type' element={<ChooseTypeCheckIn />} />
            <Route path='choose-service' element={<ChooseServicesCheckIn />} />
            <Route path='choose-staff' element={<ChooseStaffCheckIn />} />
            <Route path='success/:id/:type' element={<SuccessCheckIn />} />
          </Route>
        </Route>
        <Route path='store/:shop_id/report/summary' element={<SalonSummaryReport />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </React.Fragment>
  );
};

export default RootScreens;
