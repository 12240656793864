import { Divider } from 'antd';
import Button from 'components/Button';
import LineSubtract from 'features/payment/components/LineSubtract';
import React from 'react';
import styled from 'styled-components';
import { RejectIcon } from './components/RejectIcon';
import { useNavigate, useParams } from 'react-router-dom';

type IFailProps = {};
const Fail: React.FC<IFailProps> = () => {
  const { shop_id = '', ticketId = '' } = useParams();
  const navigate = useNavigate();
  const retry = () => {
    navigate(`/store/${shop_id}/payment/${ticketId}`, { replace: true });
  };
  return (
    <Container>
      <FailStyled>
        <RejectIcon />
        <FirstHeaderContent>Payment Failed!</FirstHeaderContent>
      </FailStyled>
      <LineSubtract />
      <FailBodyStyled>
        <BodyContentStyled>
          Unfortunately, the payment for this order has failed!
        </BodyContentStyled>
        <BodyTitleStyled>
          An issuing bank will often decline an attempt to charge a card if the
          name, expiry date, or post code you entered doesn’t match the bank’s
          information.
        </BodyTitleStyled>
        <Divider />
        <Button width="100%" ntype="PRIMARY_DEFAULT_MOBILE" onClick={retry}>
          UPDATE YOUR PAYMENT METHOD
        </Button>
      </FailBodyStyled>
    </Container>
  );
};

export default Fail;
const Container = styled.div`
  padding: 1rem;
`;
const FailStyled = styled.div`
  text-align: center;
  display: flex;
  padding: 1rem 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;
const FailBodyStyled = styled.div`
  text-align: center;
  display: flex;
  padding: 1rem 1rem 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;
const BodyContentStyled = styled.div`
  color: #1d2129;
  text-align: center;
  padding: 0 3rem 2rem;
  /* Body/Body 1 - 14 medium */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const BodyTitleStyled = styled(BodyContentStyled)`
  color: #566a7f;
  font-weight: 400;
  padding: 0;
`;

const FirstHeaderContent = styled.div`
  color: #1d2129;
  text-align: center;

  /* Headline/H6 */
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 137.5%; /* 24.75px */
`;
