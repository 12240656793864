import React, { useMemo } from 'react';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import uuid from 'utils/uuid';
import Category, { IUIQuickServiceCategoryItem, IUIQuickServiceItem } from './Category';
import { ButtonActions } from '../helpers';
import aptSelectors from '../services/selectors';
import { useAppDispatch } from 'store/hooks';
import { aptUIActions } from '../services/reducers';
type Props = {
  onBack: () => void;
  onDone: () => void;
  isAddMoreService: boolean;
};
const QuickServices = ({ onBack, isAddMoreService, onDone }: Props) => {
  const dispatch = useAppDispatch();
  const services = aptSelectors.quickServices();
  const servicesIds = useMemo(() => services.map(o => o.id), [services]);
  const categoriesStore = shopSelectors.data.quickBookingService();
  const categories = useMemo(() => categoriesStore.map(o => ({
    categoryName: o.serviceName,
    code: o.code,
    id: o.id,
    services: o.optionDetails.map(s => ({
      id: uuid(),
      masterId: o.id,
      serviceName: s,
      serviceNameValue: o.serviceName + ' + ' + s,
    }))
  } as IUIQuickServiceCategoryItem)), [categoriesStore]);

  const handler = (action: 'addQuickService' | 'subtractQuickService') => (o: IUIQuickServiceItem) => {
    dispatch(aptUIActions[action](o));
  };

  return (
    <>
      <Container>
        {categories.map(o => (
          <Category
            key={o.id} data={o}
            serviceIds={servicesIds}
            onAddService={handler('addQuickService')}
            onSubtractService={handler('subtractQuickService')}
          />
        ))}
      </Container>
      <ButtonActions
        onBack={onBack}
        onOk={onDone}
        disabledOk={!services.length}
        addMoreSer={isAddMoreService}
      />
    </>
  );
};

export default QuickServices;
const Container = styled.div`
min-height: 50vh;
display: grid;
grid-template-columns: 1fr 1fr 1fr;
gap: 16px;
@media only screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
}
`;
