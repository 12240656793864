import { Calendar as CalendarAnt, CalendarProps, Col, Row, Select } from 'antd';
import type { Moment, Moment as MomentType } from 'moment';
import moment from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import styled from 'styled-components';

const CalendarAntUI = CalendarAnt.generateCalendar<MomentType>(momentGenerateConfig);

type Props = {
  value: Moment;
  onChange: (val: Moment) => void;
};

const CalendarPicker = ({ value, onChange }: Props) => {
  const onFullRender: CalendarProps<Moment>['fullCellRender'] = (date) => {
    const day = date.date();
    const now = moment();
    return (
      <DateItemStyled
        today={now.isSame(date, 'date')}
        selected={value.isSame(date, 'date')}
        onClick={() => onChange(date)}
      >
        <div className="box">{day}</div>
      </DateItemStyled>
    );
  };

  return (
    <Wrapper>
      <CalendarAntUI
        defaultValue={value}
        fullscreen={false}
        headerRender={({ value, onChange, }) => {
          const start = 0;
          const end = 12;
          const monthOptions = [];

          let current = value.clone();
          // @ts-ignore
          const localeData = value?.localeData();
          const months = [];
          for (let i = 0; i < 12; i++) {
            current = current.month(i);
            months.push(localeData.monthsShort(current));
          }

          for (let i = start; i < end; i++) {
            monthOptions.push(
              <Select.Option key={i} value={i}
                label={
                  <Row align={'middle'} justify={'center'}>
                    <div className='label-picker'>
                      {months[i]}
                    </div>
                    <SuffixIcon />
                  </Row>
                }
                className="month-item">
                {months[i]}
              </Select.Option>,
            );
          }

          const year = value.year();
          const month = value.month();
          const options = [];
          for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
              <Select.Option key={i} value={i} label={
                <Row align={'middle'} justify={'center'}>
                  <div className='label-picker'>
                    {i}
                  </div>
                  <SuffixIcon />
                </Row>
              }
                className="year-item">
                {i}
              </Select.Option>,
            );
          }
          return (
            <>
              <Row align={'middle'} wrap={false} style={{ width: '100%' }}>
                <Col flex={1}>
                  <Select
                    size="small"
                    value={month}
                    optionLabelProp="label"
                    showArrow={false}
                    onChange={(newMonth) => {
                      const now = value.clone().month(newMonth);
                      onChange(now);
                    }}
                  >
                    {monthOptions}
                  </Select>
                </Col>
                <div className='divider' />
                <Col flex={1}>
                  <Select
                    size="small"
                    className="my-year-select"
                    value={year}
                    optionLabelProp="label"
                    showArrow={false}
                    onChange={(newYear) => {
                      const now = value.clone().year(newYear);
                      onChange(now);
                    }}
                  >
                    {options}
                  </Select>
                </Col>
              </Row>
            </>
          );
        }}
        fullCellRender={onFullRender}
        disabledDate={(date) => {
          if (date.endOf('d').valueOf() < moment().valueOf()) {
            return true;
          }
          return false;
        }}
      />
    </Wrapper>
  );
};

export default CalendarPicker;

const SuffixIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path d="M12.7497 13.1727L17.6997 8.22266L19.1137 9.63666L12.7497 16.0007L6.38574 9.63666L7.79974 8.22266L12.7497 13.1727Z" fill="#1D2129" />
  </svg>
);

const Wrapper = styled.div`
  .ant-select {
    width: 100%;
    border: none;
    box-shadow: none;
    background: transparent;
    .ant-select-selection-item {
      text-align: center;
      display: flex;
      flex: 1;
      justify-content: center;
      color: #000 !important;
    }
    &.ant-select-focused,
    &.ant-select-open {
      border: none;
      box-shadow: none;
      .ant-select-selector {
        border: none;
        box-shadow: none;
      } 
    }
    .ant-select-selector {
    border: none;
    }
  }
  .divider {
    background:#C9CDD4;
    width: 1px;
    height: 22px;
  }
  .label-picker {
    color: var(--text-text-3, #1D2129);
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-size: 1.25rem;
  }
  .ant-picker-calendar .ant-picker-panel {
    border: none;
  }
  .ant-picker-content thead th {
    color: var(--text-text-2, #767676);
    text-align: center;
    font-family: Open Sans;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }
  .ant-picker-content tbody td {
    color: var(--text-text-3, #1D2129);
    text-align: center;

    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    .ant-picker-calendar .ant-picker-cell-in-view {
      color: var(--text-text-3, #1D2129);
    }
    width: 40px;
    height: 40px;
  }
  .ant-picker-cell {
    &.ant-picker-cell-disabled::before {
      background: #fff;
      opacity: 0.8;
    }
  }
`;

const DateItemStyled = styled.button<{ selected?: boolean, today?: boolean }>`
width: 100%;
display: flex;
text-align: center;

align-items: center;
justify-content: center;
font-size: 1.25rem;
text-align: center;

font-family: Open Sans;
font-style: normal;
font-weight: 400;
line-height: normal;


-webkit-user-select: none;
/* Safari */
-ms-user-select: none;
/* IE 10 and IE 11 */
user-select: none;
/* Standard syntax */

  .box {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ today }) => today && `
      color: #0561AE;
    `}
    ${({ selected }) => selected && `
    color: var(--fill-fill-0, #FFF);
    text-align: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 166.667% */
    text-transform: capitalize;
    border-radius: 100px;
    background: #0561AE;
    `}
  }

`;