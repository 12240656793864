import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';

const initialState: IState = {
  dataDemo: [],
  creditDetails: null,
  transactionBillDetails: null,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(actions.getDemoData.success, (state, { payload }) => {
        state.dataDemo = payload;
      })
      .addCase(actions.getDetailBill.success, (state, { payload }) => {
        state.creditDetails = payload;
      })
      .addCase(
        actions.getTransactionBillDetails.success,
        (state, { payload }) => {
          state.transactionBillDetails = payload;
        }
      );
  },
});

const paymentServiceReducer = Slice.reducer;
export default paymentServiceReducer;
