import { Col, Row } from 'antd';
import Text from 'components/Text';
import { find, get } from 'lodash';
import React, { useMemo } from 'react';
import shopSelectors from 'services/shop/selectors';
import { formatCurrency } from 'utils/formatCurrency';
import { PHONE_MASKED } from 'utils/mask';
import { momentTimezone } from 'utils/time';
import { OTHER_PAYMENT, PAYMENT_TYPE } from './services/constants';
import { IBillDetailData } from './services/types/bill';
import { IPaymentCreditCardInfo } from './services/types/payment';
import ReceiptPageStyled, { CardBox, CountingBox, FinalBox, IconLogo, LineDot, MoneyBox, PaddingBox, PriceBoxStyled, PrintBodyStyled, ServiceBoxStyled, ShopText, SpaceBetweenBox, StaffHeaderBox, StyledDivider, TicketBox, TicketCodeBox, TotalBox } from './styles';

type IPrintBodyProps = {
  isPrinter?: boolean;
  billRef?: React.RefObject<HTMLDivElement>;
  data: IBillDetailData | null;
};
const VoidReceipt: React.FC<IPrintBodyProps> = ({ data, isPrinter }) => {
  const shopInfo = shopSelectors.getShopInfo();

  const describePayment = useMemo(() => {
    const creditPayment = find(data?.payments, o => o.paymentType === PAYMENT_TYPE.CREDIT_CARD);
    return [
      creditPayment ? { name: 'Credit Fee', price: get(creditPayment, 'feeCreditCard', 0) } : null,
      { name: 'Sale Tax', price: data?.saleTax },
      { name: 'Use Tax', price: data?.useTax },
      { name: 'Disc. By Item', price: data?.discountItem },
      { name: 'Disc. By Ticket', price: data?.discountTicket },
    ].filter(o => !!o);
  }, [data]);

  const havePayments = useMemo(() => {
    let loyalty = false;
    let giftCard = false;
    let creditCard = false;
    let check = false;
    data?.payments.map(o => {
      if (o.paymentType === PAYMENT_TYPE.LOYALTY_POINT) {
        loyalty = true;
      }
      if (o.paymentType === PAYMENT_TYPE.GIFT_CARD) {
        giftCard = true;
      }
      if (o.paymentType === PAYMENT_TYPE.CREDIT_CARD) {
        creditCard = true;
      }
      if (o.paymentType === PAYMENT_TYPE.CHECK && o.otherMethod === OTHER_PAYMENT.CHECK) {
        check = true;
      }
    });

    return ({
      loyalty,
      giftCard,
      isHaveSignature: creditCard || check,
    });

  }, [data]);

  const moneyCard = useMemo(() => {
    return [
      { name: 'Loyalty Program', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.LOYALTY_POINT)?.amount },
      { name: 'Gift Card', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.GIFT_CARD)?.amount },
      { name: 'Cash', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CASH)?.amount },
      { name: 'Change Amount', price: data?.chance ? -data?.chance : null },
      { name: 'Check', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CHECK)?.amount },
      { name: 'Cash pay', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CHECK && o.otherMethod === OTHER_PAYMENT.CASH_APPS)?.amount },
      { name: 'Zelle', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CHECK && o.otherMethod === OTHER_PAYMENT.ZELLE)?.amount },
      { name: 'Paypal', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CHECK && o.otherMethod === OTHER_PAYMENT.PAYPAL)?.amount },
      { name: 'Venmo', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CHECK && o.otherMethod === OTHER_PAYMENT.VENMO)?.amount },
    ].filter(o => !!o.price);
  }, [data]);

  const creditCard = useMemo(() => {
    const creditData = data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CREDIT_CARD) as (IPaymentCreditCardInfo | undefined);
    if (!creditData) return null;
    return {
      endCode: creditData.last4,
      cardType: creditData.cardType,
      authCode: creditData.authCode,
      trans: creditData.transNumber,
      entryType: creditData.entryType,
      amount: creditData?.amount,
    };
  }, [data]);


  if (!data) return <PrintBodyStyled />;

  return (
    <ReceiptPageStyled>
      <PrintBodyStyled id='receipt' >
        <TicketBox>
          <CountingBox>
            <Row justify={'space-between'} align={'middle'}>
              <Col>
                <IconLogo />
                <TicketCodeBox>
                  #{data.ticketNumber}
                </TicketCodeBox>
              </Col>
              <Col>
                <ShopText className='bold' style={{ fontSize: '1.5rem' }}>{shopInfo?.shopName || ''}</ShopText>
                <ShopText className={isPrinter ? 'bold' : ''} style={{ fontSize: '0.75rem' }}>
                  {shopInfo?.address || ''}{', ' + shopInfo?.city || ''}
                </ShopText>
                <ShopText style={{ fontSize: '0.75rem' }}>
                  {PHONE_MASKED.resolve(shopInfo?.phone || '')}
                </ShopText>
              </Col>
            </Row>
            <Text mt={0.5} />
            <Row justify={'center'} align={'middle'}>
              <TicketCodeBox>
                VOIDED
              </TicketCodeBox>
            </Row>
            <Text mt={0.5} />
            <Row justify={'center'} align={'middle'}>
              <Text printMode variant="CONTENT_2">
                Reason: {data?.reason}
              </Text>
            </Row>
            <Text mt={0.5} />
            <Text printMode mb={1} />
            <SpaceBetweenBox>
              <Text printMode variant="CONTENT_2" className='customer_name'>{data.customerName || 'Unknown Customer'}</Text>
              {!!data?.customerPoint && <Text printMode variant="CONTENT_1" color="text_2">
                {data?.customerPoint || 0} Point{(data?.customerPoint || 0) > 1 ? 's' : ''}
              </Text>}
            </SpaceBetweenBox>
            <Text printMode variant="CONTENT_1" color="text_2">
              {momentTimezone(data.startTime).format('MM-DD-YYYY LT')} - {momentTimezone(data.endTime).format('LT')}
            </Text>
            <Text printMode mb={1} />

            {data?.items?.map((item) => (
              <div key={item.staffId + data?.billId}>
                <StaffHeaderBox>{item.staffName}</StaffHeaderBox>
                <ServiceBoxStyled>
                  {item.services.map((service) => (
                    <div key={service.itemId}>
                      <SpaceBetweenBox>
                        <Text printMode variant="CONTENT_2">
                          {service.itemName}
                        </Text>
                        <PriceBoxStyled>
                          <Text printMode variant="CONTENT_2">
                            {formatCurrency(service.price)}
                          </Text>
                        </PriceBoxStyled>
                      </SpaceBetweenBox>
                    </div>
                  ))}
                </ServiceBoxStyled>
              </div>
            ))}

            <StyledDivider my={0.5} />
            <PaddingBox>
              {describePayment.map((item) => (
                <SpaceBetweenBox key={item?.name}>
                  <Text printMode variant="CONTENT_2">
                    {item?.name}
                  </Text>
                  <Text printMode variant="CONTENT_2">
                    {formatCurrency(item?.price)}
                  </Text>
                </SpaceBetweenBox>
              ))}
            </PaddingBox>
            <StyledDivider mt={0.5} mb={1} />
            <SpaceBetweenBox>
              <Text printMode variant="CONTENT_2">
                Subtotal
              </Text>

              <Text printMode variant="CONTENT_2">
                {formatCurrency(data?.subTotal)}
              </Text>
            </SpaceBetweenBox>
            <StyledDivider mt={0.5} mb={1} />

            <SpaceBetweenBox style={creditCard ? { height: '4rem', alignItems: 'unset', paddingBottom: '1rem' } : {}}>
              <Text printMode variant="CONTENT_2">
                Tip
              </Text>
              {creditCard ?
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'end', flex: 1 }}>
                  <Text printMode variant="H7">
                    {formatCurrency(data?.tip)}
                  </Text>
                  <LineDot style={{ transform: 'translateY(10px)' }} />
                </div> :
                <Text printMode variant="H7">
                  {formatCurrency(data?.tip)}
                </Text>
              }
            </SpaceBetweenBox>
          </CountingBox>
          <TotalBox>
            <SpaceBetweenBox style={creditCard ? { height: '4rem', alignItems: 'unset', paddingBottom: '1rem' } : {}}>
              <Text printMode variant="CONTENT_2">
                Total
              </Text>
              {creditCard ?
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'end', flex: 1 }}>
                  <Text printMode variant="H7">
                    {formatCurrency(data?.total)}
                  </Text>
                  <LineDot style={{ transform: 'translateY(10px)' }} />
                </div> :
                <Text printMode variant="H7">
                  {formatCurrency(data?.total)}
                </Text>
              }
            </SpaceBetweenBox>
          </TotalBox>
          <CardBox>
            {moneyCard.length > 0 && <MoneyBox>
              {moneyCard.map((item) => (
                <SpaceBetweenBox key={item.name}>
                  <Text printMode variant="CONTENT_2">
                    {item.name}
                  </Text>
                  <Text printMode variant="CONTENT_2">
                    {formatCurrency(item.price)}
                  </Text>
                </SpaceBetweenBox>
              ))}
            </MoneyBox>}

            {(havePayments.loyalty || havePayments.giftCard) &&
              <>
                {havePayments.loyalty &&
                  <SpaceBetweenBox my={0.5}>
                    <Text printMode variant="CONTENT_2">
                      Available Loyalty Points
                    </Text>
                    <Text printMode variant="CONTENT_2">
                      {(data?.availableLoyaltyPoint)} point
                    </Text>
                  </SpaceBetweenBox>}

                {havePayments.giftCard &&
                  <SpaceBetweenBox mb={0.5}>
                    <Text printMode variant="CONTENT_2">
                      Gift Card Balance
                    </Text>

                    <Text printMode variant="CONTENT_2">
                      {formatCurrency(data?.availableGiftCard)}
                    </Text>
                  </SpaceBetweenBox>}
              </>}

            {creditCard && <>
              <PaddingBox>
                <SpaceBetweenBox>
                  <Text printMode variant="CONTENT_2">
                    Credit Card {creditCard?.endCode || ''}
                  </Text>
                  <Text printMode variant="CONTENT_2">
                    {formatCurrency(creditCard?.amount)}
                  </Text>
                </SpaceBetweenBox>
                <Text my={0.5} printMode variant="CONTENT_2">
                  Card Type: {creditCard?.cardType}
                </Text>
                <Text mb={0.5} printMode variant="CONTENT_2">
                  authCode: {creditCard?.authCode}
                </Text>
                <Text mb={0.5} printMode variant="CONTENT_2">
                  Trans Number #: {creditCard?.trans}
                </Text>
                <Text mb={0.5} printMode variant="CONTENT_2">
                  Entry Type: {creditCard?.entryType}
                </Text>
              </PaddingBox>
            </>}
          </CardBox>
          <FinalBox border={!havePayments.isHaveSignature}>
            <Text style={{ color: 'black', fontSize: 16 }}>Thank you for your business.</Text>
            <Text className='bold' printMode variant='BODY_1' color='text_3'>©Powered by Fozito</Text>
          </FinalBox>
        </TicketBox>
      </PrintBodyStyled>
    </ReceiptPageStyled>
  );
};

export default VoidReceipt;
