import React from 'react';
import styled from 'styled-components';
import { Form, Rate } from 'antd';
import TextArea from 'antd/es/input/TextArea';
// import { UserOutlined } from '@ant-design/icons';

type IRatingViewProps = any;
const RatingView: React.FC<IRatingViewProps> = () => {
  return (
    <MainContainer>
      <RatingViewStyled>
        {/* <FlexBox>
          <Avatar size={48} icon={<UserOutlined />} />
          <AvatarName>Ankur</AvatarName>
        </FlexBox> */}
        <SubTitle>
          Thank You for your payment, your quick rating will be impact to our
          performance on your next service.
        </SubTitle>
        <FormItem name="stars" style={{ textAlign: 'center' }}>
          <Rate style={{ fontSize: '3rem', width: '100%' }} />
        </FormItem>
        <LineDivine />
        <BodyContentSub>
          Are you satisfied with staff? Let us know your opinion
        </BodyContentSub>
        <FormItem name="note">
          <TextArea
            placeholder="Leave message"
            autoSize={{ minRows: 3, maxRows: 5 }}
            style={{ width: '100%' }}
          />
        </FormItem>
      </RatingViewStyled>
    </MainContainer>
  );
};

export default RatingView;
type RatingViewStyledProps = {};
const MainContainer = styled.div`
  padding: 1rem;
`;
const RatingViewStyled = styled.div<RatingViewStyledProps>`
  display: inline-flex;
  padding: 16px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  background: #fff;
`;

// const FlexBox = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 0.5rem;
// `;
// const AvatarName = styled.div`
//   color: #1d2129;

//   /* Headline/H7_semibold */
//   font-family: Poppins;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: 137.5%; /* 22px */
// `;

const SubTitle = styled.div`
  color: #686868;
  text-align: center;

  /* Body/Small_1_regular */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.5%; /* 16.5px */
`;

const LineDivine = styled.div`
  background: #e5e5e5;
  width: 100%;
  height: 1px;
`;

const BodyContentSub = styled.div`
  color: #1d2129;
  text-align: center;

  /* Body/Body */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 137.5%; /* 19.25px */
  padding: 0.5rem 0;
`;

const FormItem = styled(Form.Item)`
  margin: 0;
  width: 100%;
`;
