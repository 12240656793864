import { Col, Row } from 'antd';
import AvatarBox from 'components/AvatarBox';
import shopSelectors from 'services/shop/selectors';
import { IStaffItemData } from 'services/shop/types/staff';
import styled from 'styled-components';
import StaffSearchInput from 'widgets/StaffSearchInput';
import { APPOINTMENT_TABS, ButtonActions } from './helpers';
import { ITabItemProps } from './types';
import { useMemo } from 'react';
interface Props extends ITabItemProps {
  staff: IStaffItemData | null;
  setStaff: (staff: IStaffItemData) => void;
}
const StaffTab = ({ setStaff, setTab, staff }: Props) => {
  const staffs = shopSelectors.data.staffsBookingOnline();

  const _staffs = useMemo(() => staffs.filter(o => !o.anyBody), [staffs]);

  const handleBack = () => setTab(APPOINTMENT_TABS.CUSTOMER);
  const handleNext = () => setTab(APPOINTMENT_TABS.TIME);
  const handlePickStaff = (val: IStaffItemData) => {
    setStaff(val);
    handleNext();
  };

  return (
    <>
      <StaffTabStyled>
        <StaffSearchInput preventAnybody onPickStaff={handlePickStaff} />
      </StaffTabStyled>
      <StaffContainerStyled justify="start" gutter={[16, 16]}>
        {_staffs.map(o => (
          <Col
            key={o.id}
            xs={8}
            sm={6}
            lg={3}
            onClick={() => handlePickStaff(o)}
            className='staff-item'
          >
            <AvatarBox
              src={o.urlImage}
              name={o.firstName}
              selected={staff?.id === o.id}
            />
          </Col>
        ))}
      </StaffContainerStyled>
      <ButtonActions first onBack={handleBack} onOk={handleNext} disabledOk={!staff} />
    </>
  );
};

export default StaffTab;

const StaffTabStyled = styled.div``;

const StaffContainerStyled = styled(Row)`
  overflow-y: auto;
  max-height: 40vh;
  padding-top: 1rem;
  padding-bottom: 1rem;
  .staff-item {
    display: flex;
    justify-content: center;
  }

  @media only screen and (max-width: 768px) {
    max-height: unset;
  }
`;

