import styled from 'styled-components';

export const GiftCardItem = styled.button`
border-radius: 10px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
padding: 10px;
width: 60%;
display: flex;
flex-direction: column;
align-items: start;
text-align: left;
gap: 16px;
height: 20%;

  img {
    height: 100%;
    width: 100%;
  }
  .ant-image {
    width: 100%; 
    height: 9.375rem;  
  }
  .content {
    padding: 0.5rem 1rem;
  }
  .name {
    color: var(--text-text-3, #1D2129);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .desc {
    color: var(--text-text-2, #566A7F);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &.gift-card-img-result {
    height: auto;
    width: 100%;
    background: transparent;
    padding: 0;
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 4px',
  }
`;
