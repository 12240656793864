import { QRCode, Row } from 'antd';
import Text from 'components/Text';
import checkInSelectors from 'features/checkIn/services/selectors';
import { useMemo } from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { maskPhone } from 'utils/formatPhone';
import { momentTimezone } from 'utils/time';

const UIDetail = () => {
  const data = checkInSelectors.getInfoWalkInTicket();
  const date = useMemo(() => momentTimezone(data?.createdDate), [data]);
  return (
    <CheckInPrinterStyled>
      <TicketBox className="ticket-detail-box">
        <Row justify={'space-between'} align={'middle'} className='container'>
          <div>
            <Text className='time-text'>{date.format('DD-MM-YYYY')}</Text>
            <Text className='time-text'>{date.format('HH:mm A')}</Text>
          </div>
          <div className="ticket-number"><p>#{data?.ticketNumber}</p></div>
        </Row>
        <Text mb={0.5} />
        {data?.qrCode && <Row align={'middle'} justify={'center'} className='container'>
          <QRCode size={170} value={data.qrCode} />
        </Row>}
        <Text mb={0.5} />
        <Row align={'middle'} className='container' justify={'center'}>
          <ShopText printMode variant="CONTENT_2" className='bold' style={{ fontSize: '18px' }}>{data?.customerName} - {maskPhone(data?.phone || '')}</ShopText>
        </Row>
        <Text mb={1} />
        <div className='container'>
          {data?.items?.map((item) => (
            <div key={item.staffId}>
              <StaffHeaderBox>{item.staffName || 'Next Available'}</StaffHeaderBox>
              <ServiceBoxStyled>
                {item.services.map((service, index) => (
                  <div key={service.itemId}>
                    <SpaceBetweenBox noBorder={index === (item.services?.length - 1)}>
                      <Text printMode variant="CONTENT_2" className='service-name'>
                        {service.itemName}
                      </Text>
                      <div style={{ flex: 1 }} />
                      <Text printMode variant="CONTENT_2" className='duration'>
                        {service.duration}m
                      </Text>
                      <PriceBoxStyled style={{ width: '5rem', justifyContent: 'right' }}>
                        <Text printMode variant="CONTENT_2" className='price' textAlign='right'>
                          {formatCurrency(service.price)}
                        </Text>
                      </PriceBoxStyled>
                    </SpaceBetweenBox>
                  </div>
                ))}
              </ServiceBoxStyled>
            </div>
          ))}
        </div>
        <Text style={{ height: '1rem', width: '100%' }} />
      </TicketBox>
    </CheckInPrinterStyled>
  );
};

export default UIDetail;

const CheckInPrinterStyled = styled.div`
  .container {
    padding:0 1rem;
  }

  .ticket-detail-box {
    padding-top: 1rem;
    border-radius: 5px;
    background: #fff;

    .time-text {
      color: #767676;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .ticket-number {
      border-radius: 5px;
      background: #1D2129;
      display: flex;
      align-self: stretch;
      padding: 0 8px;
      p {
        color: #FFF;
        text-align: right;
        font-family: Open Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
  .text-point {
    color: #767676;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .service-name, .duration, .price {
    color: #1D2129;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  .price {
    color: #1D2129;
    text-align: right;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
const TicketBox = styled.div`
  background: #fff;
  height: fit-content;
`;

const ShopText = styled(Text)`
  color: black;
  font-style: normal;
  font-family: 'Roboto', serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: right;

  &.bold {
    font-size: 0.8rem;
    font-weight: 700;
  }
`;

const StaffHeaderBox = styled.div`
  border-bottom: 1px solid #C9CDD4;
  color: black;
  font-family: 'Roboto', serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
`;

const ServiceBoxStyled = styled.div`
  padding: 0.5rem 0;
`;

const SpaceBetweenBox = styled(Text) <{ noBorder?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #DDD;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  ${({ noBorder }) => noBorder ? `
    border-bottom: none;
  `: ''}
`;
const PriceBoxStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;