
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { setLoading } from 'services/UI/sagas';
import { ISagaFunc } from 'services/actionConfigs';
import { IResponseDataBody } from 'services/response';
import storage from 'utils/sessionStorage';
import actions from './actions';
import apis from './apis';
import { PATH_LOADING } from './constants';
import { IShopInfo } from './types/shop';
import { MyState, getCurrentState } from './selectors';
import { get } from 'lodash';
import { IStaffItemData } from './types/staff';
import { ICategoryItemData } from './types/categories';
import { ISocialMedia } from './types/socialMedia';
import { IShopAllSetting } from './types/setting';

const checkExist = function* (key: keyof MyState) {
  const store: MyState = yield select(root => getCurrentState(root));
  return get(store, key);
};


const getShopInfo: ISagaFunc<string> = function* ({ payload }) {
  yield storage.shop_id.set(payload);
  yield setLoading(PATH_LOADING.getShopInfo, true);
  yield delay(100);
  yield put(actions.get.socialMedia.fetch(payload));
  try {
    const res: IResponseDataBody<IShopInfo> = yield call(apis.getShopInfo, payload);
    if (res.data.data) {
      yield put(actions.getShopInfo.success(res.data.data));
    }
  } catch (error) { }
  finally {
    yield setLoading(PATH_LOADING.getShopInfo, false);
  }
};

const getStaffList: ISagaFunc<string> = function* ({ payload }) {
  const storeData: MyState['staffs'] = yield checkExist('staffs');
  if (storeData.length > 0) return;

  yield setLoading(PATH_LOADING.getStaffList, true);
  yield delay(100);
  try {
    const resData: IResponseDataBody<{ content: IStaffItemData[] }> = yield call(
      apis.getStaffs, payload
    );
    if (resData?.data?.data?.content) {
      yield put(actions.get.staffs.success(resData?.data?.data?.content));
    }
  } catch (error) {
    yield put(actions.get.staffs.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getStaffList, false);
  }
};

const getStaffsBookingOnline: ISagaFunc<string> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getStaffList, true);
  yield delay(100);
  try {
    const resData: IResponseDataBody<{ content: IStaffItemData[] }> = yield call(
      apis.getStaffsBookingOnline, payload
    );
    if (resData?.data?.data) {
      yield put(actions.get.staffsBookingOnline.success(resData?.data?.data));
    }
  } catch (error) {
    yield put(actions.get.staffsBookingOnline.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getStaffList, false);
  }
};

const getQuickServices: ISagaFunc<string> = function* ({ payload }) {
  try {
    const resData: IResponseDataBody<ICategoryItemData[]> = yield call(
      apis.getQuickServices, payload
    );
    if (resData?.data?.data) {
      yield put(actions.get.quickServices.success(resData?.data?.data));
    }
  } catch (error) {
    yield put(actions.get.quickServices.fail({}));
  }
};

const getShopAllSetting: ISagaFunc<string> = function* ({ payload }) {
  try {
    const res: IResponseDataBody<IShopAllSetting> = yield call(
      apis.getShopAllSetting, payload
    );
    if (res.data.data) {
      yield put(actions.get.allSetting.success(res.data.data));
    }
  } catch (error) { }
};

const getCategories: ISagaFunc<string> = function* ({ payload }) {
  const storeData: MyState['categories'] = yield checkExist('categories');
  if (storeData.length > 0) {
    yield put(actions.get.categories.success(storeData));
    return;
  }

  yield setLoading(PATH_LOADING.getCategories, true);
  yield delay(100);
  try {
    const resData: IResponseDataBody<ICategoryItemData[]> = yield call(
      apis.getCategories, payload
    );
    if (resData?.data?.data) {
      yield put(actions.get.categories.success(resData?.data?.data));
    }
  } catch (error) {
    yield put(actions.get.categories.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getCategories, false);
  }
};

const initBookingOnline: ISagaFunc<string> = function* ({ payload }) {
  yield delay(100);
  yield all([
    put(actions.get.staffs.fetch(payload)),
    put(actions.get.staffsBookingOnline.fetch(payload)),
    put(actions.get.categories.fetch(payload)),
    put(actions.get.quickServices.fetch(payload)),
    put(actions.get.allSetting.fetch(payload)),
  ]);
};

const initCheckInOnline: ISagaFunc<string> = function* ({ payload }) {
  yield all([
    put(actions.get.staffs.fetch(payload)),
    put(actions.get.categories.fetch(payload)),
  ]);
};

const getSocialMedia: ISagaFunc<string> = function* ({ payload }) {
  try {
    const res: IResponseDataBody<ISocialMedia[]> = yield call(apis.getSocialMedia, payload);
    if (res.data.data) {
      yield put(actions.get.socialMedia.success(res.data.data));
    }
  } catch (error) { }
};

export default function* shopServiceSagas() {
  yield takeLatest(actions.init.bookingOnline, initBookingOnline);
  yield takeLatest(actions.init.checkInOnline, initCheckInOnline);
  yield takeLatest(actions.getShopInfo.fetch, getShopInfo);
  yield takeLatest(actions.get.staffs.fetch, getStaffList);
  yield takeLatest(actions.get.staffsBookingOnline.fetch, getStaffsBookingOnline);
  yield takeLatest(actions.get.categories.fetch, getCategories);
  yield takeLatest(actions.get.socialMedia.fetch, getSocialMedia);
  yield takeLatest(actions.get.quickServices.fetch, getQuickServices);
  yield takeLatest(actions.get.allSetting.fetch, getShopAllSetting);
}
