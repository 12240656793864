
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { ISagaFunc } from 'services/actionConfigs';
import { IResponseDataBody } from 'services/response';
import actions from './actions';
import apis from './apis';
import storage from 'utils/sessionStorage';
import { IActFetchSummaryReportParams } from './types/report';

const fetchSummaryReport: ISagaFunc<IActFetchSummaryReportParams> = function* ({ payload }) {
  yield storage.shop_id.set(payload.shopId);
  yield delay(200);
  try {
    const res: IResponseDataBody = yield call(apis.getSalonSummary, { endDate: payload.endDate, startDate: payload.startDate });
    if (res.data.data) {
      yield put(actions.getSalonSummary.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.getSalonSummary.fail({}));
  }
};
export default function* reportServiceSagas() {
  yield takeLatest(actions.getSalonSummary.fetch, fetchSummaryReport);
}
