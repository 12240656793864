import RootScreens from 'features/RootScreens';
import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingPage from 'services/UI/LoadingPage';
import store from 'store';

const AppLayout: React.FC = () => <RootScreens />;

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <AppLayout />
      <LoadingPage />
      <ToastContainer />
    </Provider>
  );
};

export default App;
