import { Row } from 'antd';
import Text from 'components/Text';
import styled from 'styled-components';

export const ButtonActions = ({ confirm, onBack, onOk, disabledOk, addMoreSer }: { first?: boolean, confirm?: boolean, onOk: () => void, onBack: () => void, disabledOk?: boolean, addMoreSer?: boolean }) => {
  if (addMoreSer) {
    return (
      <WrapStyled>
        <ButtonActionsStyled className='sticky-box' align={'middle'}>
          <button className='btn btn-primary' onClick={onOk} disabled={disabledOk}>ADD</button>
        </ButtonActionsStyled>
        <ButtonActionsStyled className='actions-bottom' align={'middle'}>
          <button className='btn btn-primary' onClick={onOk} disabled={disabledOk}>ADD</button>
        </ButtonActionsStyled>
      </WrapStyled>
    );
  }

  return (
    <WrapStyled>
      <ButtonActionsStyled className='sticky-box' align={'middle'}>
        <button className='btn btn-default' onClick={onBack}>{'Back'}</button>
        <button className='btn btn-primary' onClick={onOk} disabled={disabledOk}>{confirm ? 'Confirm' : 'Next'}</button>
      </ButtonActionsStyled>
      <ButtonActionsStyled className='actions-bottom' align={'middle'}>
        <button className='btn btn-default' onClick={onBack}>{'Back'}</button>
        <button className='btn btn-primary' onClick={onOk} disabled={disabledOk}>{confirm ? 'Confirm' : 'Next'}</button>
      </ButtonActionsStyled>
    </WrapStyled>
  );
};
const WrapStyled = styled.div`
  .sticky-box {
    display: none;
  }
  .actions-bottom {
    padding: 0 10%;
  }
  @media only screen and (max-width: 768px) {
    position: sticky;
    bottom: 0;
    height: 70px;
    z-index: 99;
    display: flex;
    align-items: center;
    right: 0;
    left: 0;
    background: #fff;
    .actions-bottom {
      opacity: 0;
      pointer-event: none;
    }
    .sticky-box {
      width: 100%;
      display: flex;
      padding: 16px;
      position: fixed;
      height: 70px;
      z-index: 1;
      bottom: 0;
      right: 0;
      left: 0;
      background: #fff;
    }
  }
`;
const ButtonActionsStyled = styled(Row)`
  margin-top: 1rem;
  width: 100%;
  gap: 1rem;
  flex-wrap: nowrap;
  .btn {
    text-transform: uppercase;
    flex:1;
  }
  
  @media only screen and (max-width: 768px) {
    .btn {
      text-transform: uppercase;
      width: unset;
      flex-grow: 1;
    }
  }
`;

export enum APPOINTMENT_TABS {
  STAFF = 'STAFF',
  TIME = 'TIME',
  SERVICES = 'SERVICES',
  CUSTOMER = 'CUSTOMER',
}

export const LabelTab = ({ label, valid = false }: { label: string, valid?: boolean }) => {
  if (!valid) {
    return (
      <Row align={'middle'} className='row-label-tab'>
        <Text className='label-tab'>{label}</Text>
      </Row>
    );
  }

  return (
    <Row align={'middle'} className='row-label-tab'>
      <Text className='label-tab valid'>{label}</Text>
      <TickValidIcon />
    </Row>
  );
};

const TickValidIcon = () => (
  <svg className='tick-valid-icon' xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10" fill="none">
    <path d="M5.38605 10C5.10591 10 4.83978 9.88839 4.64368 9.69306L0.679651 5.74468C0.27345 5.34008 0.27345 4.67039 0.679651 4.26578C1.08585 3.86118 1.75819 3.86118 2.16439 4.26578L5.38605 7.47471L12.5856 0.303453C12.9918 -0.101151 13.6641 -0.101151 14.0703 0.303453C14.4766 0.708057 14.4766 1.37775 14.0703 1.78235L6.12842 9.69306C5.93232 9.88839 5.66619 10 5.38605 10Z" fill="#0561AE" />
  </svg>
);