import React from 'react';
import styled from 'styled-components';
type IConfirmItemReviewProps = {
  label: string;
  hasBox?: boolean;
  value: string;
};
const ConfirmItemReview: React.FC<IConfirmItemReviewProps> = ({
  label,
  hasBox,
  value,
}) => {
  return (
    <ConfirmItemReviewStyled>
      <LeftLabel>{label}</LeftLabel>
      {hasBox ? (
        <SpecialBox>{value}</SpecialBox>
      ) : (
        <RightLabel>{value}</RightLabel>
      )}
    </ConfirmItemReviewStyled>
  );
};

export default ConfirmItemReview;
type ConfirmItemReviewStyledProps = {};
const ConfirmItemReviewStyled = styled.div<ConfirmItemReviewStyledProps>`
  display: flex;
  padding: 4px 0px;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
`;

const LeftLabel = styled.div`
  color: #686868;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.5%; /* 19.25px */
`;
const SpecialBox = styled.div`
  display: flex;
  padding: 2px 8px 0;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  background: #1d2129;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 137.5%; /* 19.25px */
`;
const RightLabel = styled.div`
  color: #1d2129;
  text-align: right;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 137.5%; /* 22px */
`;
