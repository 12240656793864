import { createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IGiftCardDetail } from './types/giftCard';

const getGiftCardToBuy = createAsyncAction<string>(PREFIX_ACTIONS + 'getGiftCardToBuy');

const getGiftCardDetail = createAsyncAction<{ id: string, shopId?: string | null }, IGiftCardDetail>(PREFIX_ACTIONS + 'getGiftCardDetail');

const giftCardActions = {
  getGiftCardToBuy,
  getGiftCardDetail,
};

export default giftCardActions;

