import { Row } from 'antd';
import styled from 'styled-components';
import CouponContainer from '../CouponContainer';
import LoyaltyTop from './LoyaltyTop';
import { ICouponPrintData } from 'features/coupon/types';
type Props = {
  data: ICouponPrintData['LOYALTY_POINT']
};
const CouponLoyaltyPoint = ({ data }: Props) => {
  return (
    <CouponContainer>
      <CouponContainerWrapStyled>
        <LoyalTopImg>
          <LoyaltyTop />
        </LoyalTopImg>
        <Title>$1 = {data.oneDollar} POINTS</Title>
        <Row align={'middle'} justify={'center'} style={{ gap: '4px' }}>
          <RewardBox style={{ transform: 'translateY(2px)' }}>REWARD</RewardBox>
          <Content>EARN <span className='percent'>{data.percent}%</span> FOR EVERY 100 POINTS</Content>
        </Row>
        <SubText>* You must register to become member by sign in system</SubText>
      </CouponContainerWrapStyled>
    </CouponContainer>
  );
};

export default CouponLoyaltyPoint;

const CouponContainerWrapStyled = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const Title = styled.div`
color: var(--text-text-3, #1D2129);
text-align: center;
font-family: Archivo Black;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 100% */
text-transform: uppercase;
`;

const RewardBox = styled.div`
color: white;
text-align: center;
font-family: Poppins;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: 11px; /* 73.333% */
text-transform: uppercase;
background: black;
padding: 2px;
`;

const SubText = styled.div`
color: black;
text-align: center;
font-size: 12px;
font-style: normal;
line-height: normal;
font-weight: 900;
font-family: 'Roboto', sans-serif;
`;

const Content = styled.div`
color: black;
text-align: center;
font-family: Poppins;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: capitalize;

.percent {
  color: black;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 900;
}
`;

const LoyalTopImg = styled.div`
  display: flex;
  align-items:center;
  justify-content: center;
  border-bottom: 2px solid black;
  margin-bottom: 11px;
  svg {
    height:135px;
  }
`;