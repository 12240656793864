import { Row } from 'antd';
import Button from 'components/Button';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ShopInfo from 'widgets/ShopInfo';
import Staffs from './Staffs';
import { IStaffItemData } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import checkInActions from 'features/checkIn/services/actions';
import checkInSelectors from 'features/checkIn/services/selectors';
import { IConfirmCheckIn } from 'features/checkIn/services/types/api';
import checkInApis from 'features/checkIn/services/apis';
import { IResponseDataBody } from 'services/response';
import { ICheckInConfirmResData } from 'features/checkIn/services/types/checkIn';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { TICKET_TYPE } from 'features/checkIn/services/constants';
import { IInfoPrinterWaitingResData } from 'features/checkIn/services/types/ticket';

const ChooseStaffCheckIn = () => {
  const { shop_id = '', station_number = '' } = useParams();
  const staff = checkInSelectors.getSelectedStaff();
  const customer = checkInSelectors.getCustomerData();
  const serviceIds = checkInSelectors.getSelectedServices();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const setLoadingPage = useSetLoadingPage();

  const handleBack = () => navigate(-1);

  const handleConfirm = async (_staff: IStaffItemData | null) => {
    setLoadingPage(true);

    const payload: IConfirmCheckIn = {
      customerId: customer?.id ?? '',
      checkInType: 'WALK_IN',
      staffId: _staff?.anyBody ? '' : (_staff?.id ?? ''),
      group: 0,
      serviceIds,
    };
    try {
      const res: IResponseDataBody<ICheckInConfirmResData> = await checkInApis.confirmWalkIn(payload, station_number);
      const data = res.data.data;
      if (data) {
        const type = _staff?.anyBody ? TICKET_TYPE.WALK_IN : TICKET_TYPE.PENDING_TICKET;
        const id = data.id;
        const res2: IResponseDataBody<IInfoPrinterWaitingResData> = await checkInApis.getInfoPrintWaiting(type, id);
        if (res2.data.data) {
          dispatch(checkInActions.getInfoCheckInTicket.success(res2.data.data));
          navigate(`/store/${shop_id}/check-in/${station_number}/success/${id}/${type}`);
        }
      }
    } catch (error) { } finally {
      setLoadingPage(false);
    }
  };

  const handleChooseStaff = (o: IStaffItemData) => {
    dispatch(checkInActions.setSelectedStaff(o));
    handleConfirm(o);
  };

  const handleContinue = () => {
    if (!staff) return;
    handleConfirm(staff);
  };

  return (
    <Container>
      <ShopInfo />
      <Content>
        <Staffs staff={staff} onChooseStaff={handleChooseStaff} />
      </Content>
      <Actions>
        <Button ntype='WHITE' onClick={handleBack}>Back</Button>
        <Button ntype='BLACK' disabled={!staff} onClick={handleContinue}>Continue</Button>
      </Actions>
    </Container>
  );
};

export default ChooseStaffCheckIn;

const Container = styled.div``;
const Content = styled.div`
min-height: 80vh;
padding: 1rem;
`;
const Actions = styled(Row)`
  position: sticky;
  bottom:0;
  right:0;
  left:0;
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 12px;
  border-top: 1px solid #E5E5E5;
  background: #FFF;
  button {
    flex: 1;
  }
`;
