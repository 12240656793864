import { Col, Row } from 'antd';
import AvatarBox from 'components/AvatarBox';
import Text from 'components/Text';
import React from 'react';
import shopSelectors from 'services/shop/selectors';
import { IStaffItemData } from 'services/shop/types/staff';
import styled from 'styled-components';
import StaffSearchInput from 'widgets/StaffSearchInput';
type Props = {
  onChooseStaff: (o: IStaffItemData) => void;
  staff: IStaffItemData | null;
};
const Staffs = ({ onChooseStaff, staff }: Props) => {
  const staffs = shopSelectors.data.staffs();
  return (
    <>
      <StaffSearchInput onPickStaff={onChooseStaff} />
      <Text mb={0.5} />
      <Row gutter={[16, 8]}>
        {staffs.map(o => (
          <StaffItem selected={staff?.id === o.id} key={o.id} data={o} onClick={() => onChooseStaff(o)} />
        ))}
      </Row>
    </>
  );
};

export default Staffs;
type StaffItemProps = {
  data: IStaffItemData;
  selected?: boolean;
  onClick: () => void;
};
const StaffItem = ({ data, selected, onClick }: StaffItemProps) => {
  return (
    <StaffItemStyled span={8}>
      <AvatarBox
        src={data.urlImage}
        name={data.firstName}
        selected={selected}
        className={'avatar-box'}
        onClick={onClick}
        labelOverflow
      />
    </StaffItemStyled>
  );
};

const StaffItemStyled = styled(Col)`
  .avatar-box {
    width: unset;
  }
`;