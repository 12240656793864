import giftCardPaidServiceSagas from 'features/GiftCardDetail/services/sagas';
import aptServiceSagas from 'features/booking/services/sagas';
import paymentServiceSagas from 'features/payment/services/sagas';
import checkInServiceSagas from 'features/checkIn/services/sagas';
import giftCardServiceSagas from 'features/shopping/GiftCard/services/sagas';
import { all } from 'redux-saga/effects';
import shopServiceSagas from 'services/shop/sagas';
import billServiceSagas from 'features/receipt/services/sagas';
import reportServiceSagas from 'features/report/services/sagas';
export default function* rootSaga() {
  yield all([
    shopServiceSagas(),
    giftCardServiceSagas(),
    aptServiceSagas(),
    giftCardPaidServiceSagas(),
    paymentServiceSagas(),
    checkInServiceSagas(),
    billServiceSagas(),
    reportServiceSagas(),    
  ]);
}
