import React from 'react';
import { CreditCard } from 'assets/Icons/CreditPaymentIcons';
import paymentSelectors from 'features/payment/services/selectors';
import { sumBy } from 'lodash';
import styled from 'styled-components';
import ItemDisplayStyled from './ItemDisplayStyled';

type IPaymentDetailsViewProps = {};
const PaymentDetailsView: React.FC<IPaymentDetailsViewProps> = () => {
  const creditDetails = paymentSelectors.getDetailBill();
  const ticketInfo = creditDetails?.ticketInfo;

  const sumItemDiscount = sumBy(creditDetails?.items, (staff) =>
    sumBy(staff.services, (service) => Number(service.discount))
  );

  return (
    <PaymentDetailsViewStyled>
      <SubHeaderStyled>Payment Detail</SubHeaderStyled>
      <Container>
        <ItemDisplayStyled
          label="Sub Total"
          value={ticketInfo?.subTotal || 0}
        />
        <ItemDisplayStyled label="Tip" value={ticketInfo?.tip || 0} />
        <ItemDisplayStyled label="Sale Tax" value={ticketInfo?.saleTax || 0} />
        <ItemDisplayStyled label="Used Tax" value={ticketInfo?.useTax || 0} />
        <ItemDisplayStyled label="Discount Item" value={sumItemDiscount || 0} />
        <ItemDisplayStyled
          label="Discount Ticket"
          value={ticketInfo?.discount || 0}
        />
      </Container>
      <MethodBox>
        <SubHeaderStyled>Payment Method</SubHeaderStyled>
        <CreditCardBox>
          <CreditCard />
          <CreditCardContent>Credit Card</CreditCardContent>
        </CreditCardBox>
      </MethodBox>
    </PaymentDetailsViewStyled>
  );
};

export default PaymentDetailsView;
const SubHeaderStyled = styled.div`
  color: #1d2129;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 137.5%; /* 22px */
  margin-bottom: 0.5rem;
`;
const PaymentDetailsViewStyled = styled.div`
  background-color: #f7f7f7;
  padding: 1rem 1rem 2rem;
`;
const Container = styled.div`
  border-radius: 5px;
  background: #fff;
  padding: 0.25rem 0.5rem;
`;

const MethodBox = styled.div`
  margin-top: 1rem;
`;
const CreditCardBox = styled.div`
  display: flex;
  padding: 0.75rem 1rem;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  background: #e5e6eb;
`;
const CreditCardContent = styled.div`
  color: #1d2129;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.5%; /* 19.25px */
`;
