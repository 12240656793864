import moment, { Moment } from 'moment';
import { ITabItemProps } from './types';
import { useEffect, useState } from 'react';
import { APPOINTMENT_TABS, ButtonActions } from './helpers';
import { IResponseDataBody } from 'services/response';
import appointmentApis from './services/apis';
import { DATE_FORMAT, FORMAT_TIME } from 'utils/format';
import { Col, Empty, Row, Spin } from 'antd';
import styled from 'styled-components';
import Text from 'components/Text';
import CalendarPicker from 'components/CalendarPicker';
import { ANY_BODY } from './services/constants';
interface Props extends ITabItemProps {
  staffId?: string | null;
  timeSlot: Moment | null;
  setTimeSlot: (timeSlot: Moment) => void;
}
const TimeTab = ({ setTab, setTimeSlot, timeSlot, staffId }: Props) => {
  const [slots, setSlots] = useState<Moment[]>([]);
  const [date, setDate] = useState<Moment>(() => timeSlot ? moment(timeSlot) : moment());
  const [loading, setLoading] = useState(false);

  const handleBack = () => setTab(APPOINTMENT_TABS.STAFF);

  const handleOk = () => setTab(APPOINTMENT_TABS.SERVICES);

  const fetchSlot = async () => {
    if (!date) return;

    setLoading(true);
    try {
      const resData: IResponseDataBody<string[]> = await appointmentApis.getStaffTimeSlot(staffId !== ANY_BODY.id ? staffId : '', date?.format(DATE_FORMAT) || '');
      const list = resData.data.data;

      if (list) {
        const obSetDate = {
          date: date.get('date'),
          month: date.get('month'),
          year: date.get('year'),
        };
        const data: Moment[] = list.map(d => moment(d, 'HH:mm').set(obSetDate));
        setSlots(data);
      } else {
        setSlots([]);
      }

    } catch (error) {
      setSlots([]);
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!staffId) return;
    fetchSlot();
  }, [staffId, date]);

  const onChooseTimeSlot = (time: Moment) => () => {
    setTimeSlot(time);
    handleOk();
  };

  const ListSlot = () => {
    if (loading) return <Row align={'middle'} justify={'center'}><Spin /></Row>;

    if (!slots.length) return (
      <div className='empty-container'>
        <Empty />
      </div>
    );

    return (
      <TimeSlotStyled>
        <p className='section-label'>Select your available time slot</p>
        <Row className='list' gutter={[8, 8]}>
          {
            slots.map((s, i) => (
              <Col xs={6} sm={6} lg={6} xl={4} key={i}>
                <button className={`time-slot ${s.format(FORMAT_TIME) === timeSlot?.format(FORMAT_TIME) ? 'active' : ''}`} onClick={onChooseTimeSlot(s)}>
                  <Text className='time-slot-text'>{s.format(FORMAT_TIME)}</Text>
                </button>
              </Col>
            ))
          }
        </Row>
      </TimeSlotStyled>
    );
  };

  return (
    <>
      <TimeTabStyled gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} ><CalendarPicker value={date} onChange={setDate} /></Col>
        <Col xs={24} sm={24} md={12} className='time-slots'>
          <div className={`list-slot ${slots.length === 0 ? 'empty' : 'absolute'}`}>
            <ListSlot />
          </div>
        </Col>
      </TimeTabStyled>
      <ButtonActions onBack={handleBack} onOk={handleOk} disabledOk={!timeSlot} />
    </>
  );
};

export default TimeTab;

const TimeTabStyled = styled(Row)`
  display: flex;
  
  .section-label {
    color: var(--text-text-3, #1D2129);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    position: sticky;
    top:0;
    background:#fff;
    z-index:99;
    padding-bottom: 0.5rem;
  }

  .ant-picker-cell {
    &.ant-picker-cell-selected {
      .ant-picker-cell-inner {
        border-radius: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        background: var(--primary-primary-hover, #0561AE);
        .ant-picker-calendar-date-value {
          color: #fff;
          background: var(--primary-primary-hover, #0561AE) !important;
          font-weight: 600;
          border-radius: 100px;
        }
      }
    }
  }
  .time-slots {
    position: relative;
     overflow-y: auto; 
     z-index: 1;
  }

  .list-slot {
    &.absolute {
      position: absolute;
    }
  }

  @media only screen and (max-width: 768px) {
    .section-label {
      position: relative;
    }

    .time-slots {
       overflow-y: unset; 
    } 
    .list-slot {
      &.absolute {
        position: relative;
      }
    }
  }
`;

const TimeSlotStyled = styled.div`
  .list {
    width: 100%;
    margin: 0 -4px;
    max-height: 100%;
    overflow-y: auto;
  }
  .time-slot {
    width:100%;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid var(--line-line-1, #DADADA);
    background: var(--fill-fill-1, #F6F7FC);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15) inset, 2px 2px 4px 0px rgba(0, 0, 0, 0.10);

    .time-slot-text {
      color: var(--text-text-3, #1D2129);
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */

    }
    &.active {
      background: var(--primary-primary-hover, #0561AE);
      .time-slot-text {
        color: #fff;
      }
    }
  }
  
`;