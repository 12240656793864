import Text from 'components/Text';
import React from 'react';
import styled from 'styled-components';

const Welcome = ({ title, message }: { title?: string, message?: string }) => {
  return (
    <WelcomeStyled>
      <Text className='title'>{title || 'Welcome,'}</Text>
      <div className="welcome">{message || 'Please enter your information'}</div>
    </WelcomeStyled>
  );
};

export default Welcome;

const WelcomeStyled = styled.div`
padding: 1.5rem 0;
.title {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.welcome {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
`;
