import { get } from 'lodash';
import uiSelector from 'services/UI/selectors';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { PATH_LOADING } from './constants';

type MyState = RootState['appointment'];
const getCurrentState = (state: RootState): MyState => state['appointment'];

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const detail = () => selector('detail') as MyState['detail'];
const quickServices = () => selector('quickServices') as MyState['quickServices'];

const detailLoading = () => uiSelector.getLoading(PATH_LOADING.getDetailApt) as boolean;

const aptSelectors = {
  detail,
  quickServices,
  detailLoading,
};
export default aptSelectors;
