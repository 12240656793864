import styled from 'styled-components';
import CouponContainer from '../CouponContainer';
import BottomGiftCard from './Bottom';
import TopGiftCard from './Top';
import { ICouponPrintGiftCard } from 'features/coupon/types';
type Props = {
  data: ICouponPrintGiftCard
};
const CouponGiftCardUI = ({ data }: Props) => {
  return (
    <CouponContainer>
      <CouponContainerWrapStyled>
        <TopGiftCard data={data} />
        <BottomGiftCard data={data} />
      </CouponContainerWrapStyled>
    </CouponContainer>
  );
};

export default CouponGiftCardUI;
const CouponContainerWrapStyled = styled.div`
  padding-top: 1rem;
`;
