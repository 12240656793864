export const CheckIcon = () => {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28.5" cy="28" r="28" fill="#FFF1F6" />
      <rect x="12.5" y="12" width="32" height="32" rx="16" fill="#D2464F" />
      <path
        d="M26.2269 34C25.9068 34 25.6026 33.8661 25.3785 33.6317L20.8482 28.8936C20.3839 28.4081 20.3839 27.6045 20.8482 27.1189C21.3124 26.6334 22.0808 26.6334 22.545 27.1189L26.2269 30.9697L34.455 22.3641C34.9192 21.8786 35.6876 21.8786 36.1518 22.3641C36.6161 22.8497 36.6161 23.6533 36.1518 24.1388L27.0753 33.6317C26.8512 33.8661 26.5471 34 26.2269 34Z"
        fill="white"
      />
    </svg>
  );
};
