import { Col, Row } from 'antd';
import _ from 'lodash';
import { useState } from 'react';
import { ICategoryItemData, IServiceItemData } from 'services/shop/types/categories';
import styled from 'styled-components';
import Categories from './Categories';
import Services from './Services';
import { APPOINTMENT_TABS, ButtonActions } from './helpers';
import { ITabItemProps } from './types';
import shopSelectors from 'services/shop/selectors';
import QuickServices from './QuickServices';

interface Props extends ITabItemProps {
  services: IServiceItemData[],
  setServices: (services: IServiceItemData[]) => void;
  onDone: () => void;
  isAddMoreService: boolean;
}
const ServicesTab = ({ setTab, services, setServices, onDone, isAddMoreService }: Props) => {
  const [activeCategory, setActiveCategory] = useState<ICategoryItemData | null>(null);
  const allSetting = shopSelectors.data.allSetting();
  const onBack = () => setTab(APPOINTMENT_TABS.TIME);
  const handleChooseServices = (s: IServiceItemData) => {
    const exist = _.find(services, (o: IServiceItemData) => o.id === s.id);
    const newSer = _.cloneDeep(services);
    if (exist) {
      _.remove(newSer, o => o.id === s.id);
    } else {
      newSer.push(s);
    }
    setServices(newSer);
  };

  const handleDone = () => {
    onDone();
  };

  if (allSetting?.quickBookCheckIn) {
    return <QuickServices
      onBack={onBack}
      isAddMoreService={isAddMoreService}
      onDone={onDone}
    />;
  }

  return (
    <>
      <ServicesTabStyled gutter={[24, 24]}>
        <Col xs={24} sm={24} md={10}>
          <Categories selectedServices={services} activeCategory={activeCategory} setActiveCategory={setActiveCategory} />
        </Col>
        <Col xs={24} sm={24} md={14}>
          <div className='wrap-services'>
            <Services
              onChooseServices={handleChooseServices}
              activeCategory={activeCategory}
              services={services}
            />
          </div>
        </Col>
      </ServicesTabStyled>
      <ButtonActions
        onBack={() => setTab(APPOINTMENT_TABS.TIME)}
        onOk={handleDone}
        disabledOk={!services.length}
        addMoreSer={isAddMoreService}
      />
    </>
  );
};

export default ServicesTab;

const ServicesTabStyled = styled(Row)`
min-height: 50vh;
  .arrow-btn {
    width: 2.5rem;
    height: 2.5rem;
  }
  .services {
    padding-bottom: 1rem;
  }

  .wrap-services {
    position: absolute;
    inset: 0;
    overflow-y: scroll;
  }

  .section-label {
    position: sticky;
    top:0;
    background:#fff;
    z-index:99;
    padding-bottom: 0.5rem;
  }
  @media only screen and (max-width: 768px) {
    .wrap-services {
      position: relative;
      overflow-y: unset;
    }
  }
`;
