import { Select } from 'antd';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';

const PhoneInput = (props: any) => {
  return (
    <PhoneInputStyled className={props.iconPhone ? 'icon-phone' : ''}>
      {!props.isNormal ? <Select defaultValue="+1" style={{ width: '5rem' }}>
        <Select.Option value="+1">+1</Select.Option>
      </Select> : null}
      {props.iconPhone && <div className="phone"><IconPhone /></div>}
      <MaskedInput
        {...props}
        className={'phone-input ' + props.className}
        mask={
          ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
        }
        placeholder={props.placeholder || '(000) 000-0000'}
      />
    </PhoneInputStyled>
  );
};

export default PhoneInput;

const PhoneInputStyled = styled.div`
display: flex;
flex:1;
position: relative;
align-items: center;


input {
  border-radius: 5px;
  border: 1px solid var(--line-line-1, #DADADA);
  background: #FFF;
  display: flex;
  width: 100%;
  height: 44px;
  align-items: center;
  padding: 0px 11px;

  color: var(--text-text-2, #566A7F);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal; 
  outline: none;
  &:focus, &.hover {
    outline: none;
  }
}
.ant-select:hover, .ant-select:focus {
  .ant-select-selector {
    border-color: var(--line-line-1, #DADADA) !important;
  }
}
.ant-select-selector {
  border-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

&.icon-phone {
  .phone {
    position: absolute;
    left: 1rem;
  }
  .phone-input {
    padding-left: 3rem;
  }
}
`;

const IconPhone = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M17 2H7C5.89543 2 5 2.89543 5 4V20C5 21.1046 5.89543 22 7 22H17C18.1046 22 19 21.1046 19 20V4C19 2.89543 18.1046 2 17 2Z" stroke="#1D2129" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 18H12.01" stroke="#1D2129" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
