import { Row } from 'antd';
import Button from 'components/Button';
import checkInActions from 'features/checkIn/services/actions';
import { useNavigate, useParams } from 'react-router-dom';
import uiSelector from 'services/UI/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ShopInfo from 'widgets/ShopInfo';
import UIDetail from './UIDetail';

const SuccessCheckIn = () => {
  const { shop_id = '', station_number = '' } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading: boolean = uiSelector.getLoadingPage();

  const handleNewCheckIn = () => {
    dispatch(checkInActions.reset());
    dispatch(checkInActions.setCustomerInfo(null));
    navigate(`/store/${shop_id}/check-in/${station_number}`);
  };

  if (loading) return null;

  return (
    <Container>
      <ShopInfo />
      <Content>
        <UIDetail />
      </Content>
      <Actions>
        <Button ntype='BLACK' onClick={handleNewCheckIn}>New check in</Button>
      </Actions>
    </Container>
  );
};

export default SuccessCheckIn;

const Container = styled.div`
  min-height: 100vh;
  background: #F3F3F7;
`;
const Content = styled.div`
  padding: 1rem;
`;
const Actions = styled(Row)`
  width: 100%;
  display: flex;
  padding: 16px;
  align-items: center;
  button {
    flex: 1;
  }
`;
