import React from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
type IItemDisplayStyledProps = {
  label: string;
  value: number;
};
const ItemDisplayStyled: React.FC<IItemDisplayStyledProps> = ({
  label,
  value,
}) => {
  return (
    <ItemDisplayStyledStyled>
      <SubItemLabelStyled>{label}</SubItemLabelStyled>
      <CurrencyStyled>{formatCurrency(value)}</CurrencyStyled>
    </ItemDisplayStyledStyled>
  );
};

export default ItemDisplayStyled;
type ItemDisplayStyledStyledProps = {};
const ItemDisplayStyledStyled = styled.div<ItemDisplayStyledStyledProps>`
  display: flex;
  padding: 0.25rem 0;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;
const SubItemLabelStyled = styled.div`
  color: #686868;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.5%; /* 19.25px */
`;
const CurrencyStyled = styled.div`
  color: #1d2129;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 137.5%; /* 22px */
`;
