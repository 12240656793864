import { AutoComplete, Avatar, Col, Input, InputRef, Row } from 'antd';
import { SearchIcon } from 'assets/Icons/AppointmentIcons';
import Text from 'components/Text';
import { get } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import { IStaffItemData } from 'services/shop/types/staff';
import styled from 'styled-components';

type Props = {
  preventAnybody?: boolean;
  onPickStaff: (option: IStaffItemData) => void;
};
const StaffSearchInput = ({ onPickStaff, preventAnybody }: Props) => {
  const inputRef = useRef<InputRef>(null);
  const _staffList = shopSelectors.data.staffs();
  const staffList = useMemo(() => preventAnybody ? _staffList.filter(o => !o.anyBody) : _staffList, [_staffList, preventAnybody]);
  const initialOptions = useMemo(() => {
    return staffList.map(o => {
      const staffName = o.firstName + ' ' + o.lastName;
      return ({
        value: o.id,
        name: staffName,
        keywords: [staffName?.toLocaleLowerCase()],
        label: <Row align={'middle'} gutter={[8, 8]}>
          <Col><Avatar src={o.urlImage}>{get(staffName, [0], '')}</Avatar></Col>
          <Col><Text>{staffName}</Text></Col>
        </Row>,
      });
    });
  }, [staffList]);

  const [searchText, setSearchText] = useState<string>('');

  const options = useMemo(() => {
    if (!searchText) return initialOptions;
    return initialOptions.filter((item) => item.keywords.some(kw => kw?.includes(searchText?.toLocaleLowerCase())));
  }, [initialOptions, searchText]);

  const handleSwitchCustomer = (data: string) => {
    setSearchText('');
    inputRef.current?.blur();
    const result = staffList?.find((item) => item.id === data);
    if (!result) return;
    onPickStaff(result);
  };


  return (
    <StaffInputStyled>
      <AutoComplete
        style={{ width: '100%' }}
        options={options}
        onSelect={handleSwitchCustomer}
        value={searchText}
        onChange={setSearchText}
      >
        <Input
          ref={inputRef}
          prefix={<SearchIcon />}
          placeholder='Staff name'
        />
      </AutoComplete>
    </StaffInputStyled>
  );
};

export default StaffSearchInput;

const StaffInputStyled = styled.div`
  input {
    display: flex;
    flex: 1;
    height: 40px;
    padding: 8px 16px;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;

    &::placeholder {
      color: var(--text-text-2, #566A7F);
      /* Body/Body 1 - 14 regular - 21 */
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    
  }
`;