
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { ISagaFunc } from 'services/actionConfigs';
import actions from './actions';
import apis from './apis';

import uiActions from 'services/UI/actions';
import { IResponseDataBody } from 'services/response';
import { PATH_LOADING } from './constants';

const getGiftCardPaidInfo: ISagaFunc<{ id: string, shopId?: string | null }> = function* ({ payload }) {
  yield put(uiActions.setLoading({ path: PATH_LOADING.getGiftCardDetail, result: true }));
  yield delay(1000);
  try {
    const res: IResponseDataBody<any> = yield call(apis.getInfo, payload.id, payload.shopId || '');
    const resData = res?.data?.data?.giftCard;
    if (resData) {
      yield put(actions.getInfo.success(resData));
    }
  } catch (error) {
    yield put(actions.getInfo.fail({}));
  } finally {
    yield put(uiActions.setLoading({ path: PATH_LOADING.getGiftCardDetail, result: false }));
  }
};

export default function* giftCardPaidServiceSagas() {
  yield takeLatest(actions.getInfo.fetch, getGiftCardPaidInfo);
}
