import { Row } from 'antd';
import Text from 'components/Text';
import React from 'react';
import { ICategoryItemData, IServiceItemData } from 'services/shop/types/categories';
import styled from 'styled-components';
type Props = {
  category: ICategoryItemData | null;
  onChooseService: (o: IServiceItemData) => void;
  selectedServices: string[];
};
const Services = ({ category, onChooseService, selectedServices }: Props) => {
  return (
    <WrapServices>
      {category?.services?.map(o => (
        <button key={o.id} onClick={(e) => [e.preventDefault(), e.stopPropagation(), onChooseService(o)]}>
          <ServiceItem data={o} selected={selectedServices.includes(o.id)} />
        </button>
      ))}
    </WrapServices>
  );
};

export default Services;

const WrapServices = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
type SerProps = {
  data: IServiceItemData;
  selected?: boolean;
};
const ServiceItem = ({ data, selected }: SerProps) => {
  return <ServiceItemStyled align={'middle'}>
    <Text className='service-name'>{data.serviceName}</Text>
    <div style={{ flex: 1 }} />
    <Text className='duration'>({data.duration}m)</Text>

    {!selected ? <BoxBlankCheck /> : <BoxChecked />}
  </ServiceItemStyled>;
};
const ServiceItemStyled = styled(Row)`
  width: 100%;
  gap: 16px;
  border-radius: 5px;
  border: 1px solid #E5E5E5;
  background: #F6F7FC;
  padding: 12px 8px;

  .service-name {
    overflow: hidden;
    color: #1D2129;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  .duration {
    color: #767676;
    text-align: right;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
`;
const BoxBlankCheck = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect x="0.5" y="0.5" width="23" height="23" rx="1.5" fill="white" stroke="#767676" />
  </svg>
);
const BoxChecked = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="2" fill="#D2464F" />
    <path d="M10.4846 16C10.2712 16 10.0684 15.9107 9.91902 15.7544L6.8988 12.5957C6.58932 12.2721 6.58932 11.7363 6.8988 11.4126C7.20829 11.0889 7.72054 11.0889 8.03003 11.4126L10.4846 13.9798L15.97 8.24276C16.2795 7.91908 16.7918 7.91908 17.1012 8.24276C17.4107 8.56645 17.4107 9.1022 17.1012 9.42588L11.0502 15.7544C10.9008 15.9107 10.6981 16 10.4846 16Z" fill="white" />
  </svg>
);
