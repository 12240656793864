import { Tabs } from 'antd';
import { get } from 'lodash';
import { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import shopActions from 'services/shop/actions';
import { IServiceItemData } from 'services/shop/types/categories';
import { IStaffItemData } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { FORMAT_TIME } from 'utils/format';
import toast from 'utils/toast';
import ShopInfo from 'widgets/ShopInfo';
import Confirm from './Confirm';
import CustomerTab, { ICustomerInfo } from './CustomerTab';
import ServicesTab from './ServicesTab';
import StaffTab from './StaffTab';
import TimeTab from './TimeTab';
import { APPOINTMENT_TABS, LabelTab } from './helpers';
import appointmentApis from './services/apis';
import { IBodyApiMakeAppointment, IBodyApiMakeQuickServicesAppointment } from './services/types/api';
import { IBookingOnlineData } from './services/types/booking';
import BookingOnlinePageStyled from './styles';
import shopSelectors from 'services/shop/selectors';
import { IUIQuickServiceItem } from './QuickServices/Category';
import { ANY_BODY } from './services/constants';

type IBookingOnlinePageProps = any;
const BookingOnlinePage: React.FC<IBookingOnlinePageProps> = () => {
  const { shop_id = '' } = useParams();
  const dispatch = useAppDispatch();
  const setLoadingPage = useSetLoadingPage();
  const navigate = useNavigate();

  const [tab, setTab] = useState<APPOINTMENT_TABS>(APPOINTMENT_TABS.CUSTOMER);
  const [visibleConfirm, setVisibleConfirm] = useState(false);

  const [isAddMoreService, setIsAddMoreService] = useState(false);

  const [customer, setCustomer] = useState<ICustomerInfo | null>(null);
  const [staff, setStaff] = useState<IStaffItemData | null>(null);
  const [timeSlot, setTimeSlot] = useState<Moment | null>(null);
  const [services, setServices] = useState<IServiceItemData[]>([]);

  const allSetting = shopSelectors.data.allSetting();

  useEffect(() => {
    dispatch(shopActions.init.bookingOnline(shop_id || ''));
  }, []);

  const timeEnd = useMemo(() => {
    const time = timeSlot?.clone();
    if (services.length === 0) {
      time?.add(15, 'minute');
    } else {
      services.forEach(o => {
        time?.add(o.duration, 'minute');
      });
    }
    return time || null;
  }, [timeSlot, services]);

  const handleDone = () => {
    setIsAddMoreService(false);
    setVisibleConfirm(true);
  };

  const handleQuickBook = async (quickServices?: IUIQuickServiceItem[]) => {
    if (!allSetting?.quickBookCheckIn || !customer || !timeSlot || !quickServices?.length) return;
    const payload: IBodyApiMakeQuickServicesAppointment = {
      bookingType: 'ONLINE',
      customerId: customer.id || '',
      customerName: customer.name || '',
      customerPhone: customer.phone || '',
      services: quickServices.map(o => ({
        serviceId: o.masterId,
        serviceName: o.serviceNameValue,
      })),
      staffId: staff ? (staff.id !== ANY_BODY.id ? staff.id : '') : '',
      startTime: timeSlot?.format('MM/DD/YYYY HH:mm:ss'),
      note: '',
      repeat: 1,
      serviceId: quickServices.map(o => o.masterId),
    };
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await appointmentApis.makeNewQuickBookAppointment(payload);
      if (res?.data?.data) {
        const data: IBookingOnlineData = {
          date: timeSlot.format('ddd, MM-DD-YYYY'),
          time: `${timeSlot?.format(FORMAT_TIME)} - ${timeEnd?.format(FORMAT_TIME)}`,
          name: customer.name,
          phone: customer.phone,
          service: quickServices.map(o => ({
            duration: 0,
            id: o.id,
            name: o.serviceName,
          })),
          staff: staff?.firstName || 'Available Staff',
        };
        navigate(`/store/${shop_id}/booking/online/completed`, { state: data, replace: true });
      }
    } catch (error) {
      const msg = get(error, 'response.data.message', '');
      toast.error(msg || 'An error occurred, please try again!');
    }
    finally {
      setLoadingPage(false);
    }
  };

  const handleSubmit = async (quickServices?: IUIQuickServiceItem[]) => {
    if (allSetting?.quickBookCheckIn) return handleQuickBook(quickServices);
    if (!customer || !timeSlot) return;
    const payload: IBodyApiMakeAppointment = {
      bookingType: 'ONLINE',
      customerId: customer.id || '',
      customerName: customer.name || '',
      customerPhone: customer.phone || '',
      serviceId: services.map(o => o.id),
      staffId: staff ? (staff.id !== ANY_BODY.id ? staff.id : '') : '',
      startTime: timeSlot?.format('MM/DD/YYYY HH:mm:ss'),
      repeat: 1,
    };
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await appointmentApis.makeNewAppointment(payload);
      if (res?.data?.data) {
        const data: IBookingOnlineData = {
          date: timeSlot.format('ddd, MM-DD-YYYY'),
          time: `${timeSlot?.format(FORMAT_TIME)} - ${timeEnd?.format(FORMAT_TIME)}`,
          name: customer.name,
          phone: customer.phone,
          service: services.map(o => ({
            duration: o.duration,
            id: o.id,
            name: o.serviceName,
          })),
          staff: staff?.firstName || 'Available Staff',
        };
        navigate(`/store/${shop_id}/booking/online/completed`, { state: data, replace: true });

      }
    } catch (error) {
      const msg = get(error, 'response.data.message', '');
      toast.error(msg || 'An error occurred, please try again!');
    }
    finally {
      setLoadingPage(false);
    }
  };

  const tabs = useMemo(() => {
    return (
      [
        {
          label: <LabelTab label='Customer' valid={!!customer} />,
          key: APPOINTMENT_TABS.CUSTOMER,
          children: (
            <CustomerTab
              tab={tab}
              setTab={setTab}
              setStaff={setStaff}
              customer={customer}
              setCustomer={setCustomer}
            />
          ),
          disabled: !customer,
        },
        {
          label: <LabelTab label='Staff' valid={!!staff} />,
          key: APPOINTMENT_TABS.STAFF,
          children: (
            <StaffTab
              tab={tab}
              setTab={setTab}
              staff={staff}
              setStaff={setStaff}
            />
          ),
          disabled: !customer || !staff
        },
        {
          label: <LabelTab label='Time' valid={!!customer && !!staff && !!timeSlot} />,
          key: APPOINTMENT_TABS.TIME,
          children: <TimeTab
            staffId={staff?.id || ''}
            tab={tab}
            setTab={setTab}
            timeSlot={timeSlot}
            setTimeSlot={setTimeSlot}
          />,
          disabled: !customer || !staff || !timeSlot
        },
        {
          label: <LabelTab label='Services' valid={!!customer && !!staff && !!timeSlot && !!services.length} />,
          key: APPOINTMENT_TABS.SERVICES,
          children: (
            <ServicesTab
              tab={tab}
              setTab={setTab}
              services={services}
              setServices={setServices}
              onDone={handleDone}
              isAddMoreService={isAddMoreService}
            />
          ),
          disabled: !customer || !staff || !timeSlot || !services.length
        },
      ]
    );
  }, [staff, timeSlot, services, customer, isAddMoreService]);

  const handleAddNewService = () => {
    setIsAddMoreService(true);
    setTab(APPOINTMENT_TABS.SERVICES);
    setVisibleConfirm(false);
  };

  return (
    <BookingOnlinePageStyled>
      <ShopInfo />
      <NewAppointmentV2Styled style={{ display: visibleConfirm ? 'none' : 'block' }}>
        <Tabs
          activeKey={tab}
          onChange={val => setTab(val as APPOINTMENT_TABS)}
          type="card"
          // @ts-ignore
          items={tabs}
        />
      </NewAppointmentV2Styled>
      {
        visibleConfirm && timeSlot && customer && staff && (
          <NewAppointmentV2Styled>
            <Confirm
              timeStart={timeSlot}
              customer={customer}
              services={services}
              staff={staff}
              timeEnd={timeEnd}
              onBack={() => setVisibleConfirm(false)}
              onDone={handleSubmit}
              onAddNewService={handleAddNewService}
              allSetting={allSetting}
            />
          </NewAppointmentV2Styled>
        )
      }
    </BookingOnlinePageStyled>
  );
};

export default BookingOnlinePage;

const NewAppointmentV2Styled = styled.div`
  border-radius: 20px;
  background: #FFF;
  margin: 0 12%;
  padding: 1rem;

  .section-label {
    color: var(--text-text-2, #767676);
    font-family: Open Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-tabs-nav-wrap {
    border-radius: 10px;
    border: 0.5px solid var(--line-line-1, #DDD);
    background: var(--fill-fill-1, #F6F7FC);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .ant-tabs-nav-list {
    width: 100%;
    background: transparent;
    gap: 8px;
    padding: 8px;
    
    
    .ant-tabs-tab {
      flex:1;
      border: none;
      background: transparent;
      .ant-tabs-tab-btn {
        width: 100%;
        text-align: center;
        color: var(--text-text-3, #1D2129);
        font-family: Open Sans;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
        .row-label-tab {
          gap: 4px;
          justify-content: center;
          flex-wrap: nowrap;
        }
        .label-tab {
          color: var(--text-text-3, #1D2129);
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 30px;
          text-transform: capitalize;
           &.valid {
            color: #0561AE;
           }
        }
      }

      &.ant-tabs-tab-disabled {
        background: transparent;
      }

      &.ant-tabs-tab-active {
        border-radius: 8px;
        background: #0561AE !important;
        .label-tab {
          color: var(--text-text-1, #FFF);
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 30px; /* 166.667% */
          text-transform: capitalize;

           &.valid {
            color: #fff;
           }
        }
        .tick-valid-icon {
          path {
            fill: #fff;  
          }
        }
        .ant-tabs-tab-btn {
          color: #fff;
          .label-tab {
            &.valid {
              color: #fff;
             }
          }
        }
      }
      
    }
  }
  @media only screen and (max-width: 992px) {
    margin: 0 5%;
    padding: 1rem;
  }

  @media only screen and (max-width: 768px) {
    margin: 0 8px;
    padding: 16px 8px;

    .ant-tabs-nav-list {
      .ant-tabs-tab {
        padding: 5px 2px;
        .ant-tabs-tab-btn {
          .label-tab {
            font-size: 14px;
            line-height: normal;
          }
        }
  
  
      }


    }
    


  }

`;
