import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

// @ts-ignore
type MyState = RootState['report']; // ex: RootState['booking']
// @ts-ignore
const getCurrentState = (state: RootState): MyState => state['report']; // ex: state['booking']

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const summaryLoading = () => selector('summaryLoading') as MyState['summaryLoading'];
const summaryReport = () => selector('summaryReport') as MyState['summaryReport'];

const reportSelectors = {
  summaryLoading,
  summaryReport,
};
export default reportSelectors;
