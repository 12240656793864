import { createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';

const getGetDetailAppointment = createAsyncAction<{ aptId: string, shopId: string }>(PREFIX_ACTIONS + 'getGetDetailAppointment');

const aptActions = {
  getGetDetailAppointment,
};

export default aptActions;
