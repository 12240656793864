import React from 'react';
import styled from 'styled-components';

export type IUIQuickServiceCategoryItem = {
  id: string;
  categoryName: string;
  code: string;
  services: IUIQuickServiceItem[];
}

export type IUIQuickServiceItem = {
  id: string;
  masterId: string;
  serviceName: string;
  serviceNameValue: string;
};
type Props = {
  data: IUIQuickServiceCategoryItem;
  onAddService: (s: IUIQuickServiceItem) => void;
  onSubtractService: (s: IUIQuickServiceItem) => void;
  serviceIds: string[];
};
const Category = ({ data, onAddService, onSubtractService, serviceIds = [] }: Props) => {
  return (
    <Container>
      <div className="header-category">
        {iconCate}
        <div className="category-name">{data.categoryName}</div>
        {iconActive}
      </div>
      <Services>
        {data.services.map(o => {
          const count = serviceIds?.filter(s => s === o.id)?.length;
          return (
            <button type='button' key={o.id} className="service-item" onClick={() => onAddService(o)}>
              <div className="service-name-container">
                <span className='service-name'>{o.serviceName}</span>
                {!!count && <div className="count-number"><span>{count}</span></div>}
              </div>
              <div className="service-actions">
                <button onClick={(e) => [e.stopPropagation(), onAddService(o)]}><span>+</span></button>
                <button onClick={(e) => [e.stopPropagation(), onSubtractService(o)]}><span>-</span></button>
              </div>
            </button>
          );
        })}
      </Services>
    </Container>
  );
};

export default Category;

const Services = styled.div`
display: flex;
padding-left: 16px;
flex-direction: column;
align-items: flex-start;
gap: 8px;
align-self: stretch;
.service-item {
  display: flex;
  padding: 2px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  .service-name-container {
    display: flex;
    align-items: center;
    gap: 4px;
    span.service-name {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.36px;
      text-transform: capitalize;
    }
    .count-number {
      display: flex;
      width: 24px;
      height: 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 100px;
      background: #0561AE;
      span {
        color: #FFF;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px; /* 187.5% */
        text-transform: capitalize;
      }
    }
  }

  .service-actions {
    display: flex;
    align-items: center;
    gap: 8px;
    button {
      display: flex;
      width: 38px;
      height: 38px;
      padding: 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 100px;
      border: 1px solid #86909C;
      background: #E3E9ED;
      span {
        color: #86909C;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 166.667% */
        text-transform: capitalize;
      }
    }
  }
}
`;

const Container = styled.div`
display: flex;
padding-bottom: 8px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;
  .header-category {
    display: flex;
    height: 48px;
    padding: 4px 8px;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 2px;
    border: 1px solid #CECECE;
    background: #F7F7F7;
    .category-name {
      flex: 1 0 0;
      color: #566A7F;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
`;

const iconCate = <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
  <rect x="1" y="1" width="2" height="2" fill="#1D2129" />
  <rect x="1" y="5" width="2" height="2" fill="#1D2129" />
  <rect x="5" y="1" width="2" height="2" fill="#1D2129" />
  <rect x="5" y="5" width="2" height="2" fill="#1D2129" />
</svg>;

const iconActive = <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="12.3334" cy="12" r="12" fill="#0561AE" />
  <path fillRule="evenodd" clipRule="evenodd" d="M19.1852 6.37336C19.4524 6.60716 19.4794 7.01329 19.2456 7.28048L10.2456 17.5662C10.1285 17.7001 9.96106 17.7795 9.78323 17.7854C9.60541 17.7913 9.43308 17.7233 9.30727 17.5974L5.45013 13.7403C5.19908 13.4892 5.19908 13.0822 5.45013 12.8312C5.70118 12.5801 6.10822 12.5801 6.35927 12.8312L9.73053 16.2024L18.278 6.43384C18.5118 6.16664 18.918 6.13957 19.1852 6.37336Z" fill="white" />
</svg>
  ;
