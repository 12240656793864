import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

type MyState = RootState['bill'];

const getCurrentState = (state: RootState): MyState => state['bill'];

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const billDetail = () => selector('billDetail') as MyState['billDetail'];
const receiptLoading = () => selector('receiptLoading') as MyState['receiptLoading'];
const billCoupons = () => selector('coupons') as MyState['coupons'];

const billSelectors = {
  billDetail,
  billCoupons,
  receiptLoading,
};
export default billSelectors;
