import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import PaymentDetailsView from './components/PaymentDetailsView';
import paymentSelectors from 'features/payment/services/selectors';
import { formatCurrency } from 'utils/formatCurrency';
import paymentApis from 'features/payment/services/apis';
import { get } from 'lodash';
import toast from 'utils/toast';
import { useParams } from 'react-router-dom';
import {
  HeaderContentBoxStyled,
  HeaderStyled,
} from 'features/payment/components/Styled';

type ICreditPaymentProps = any;
const CreditPayment: React.FC<ICreditPaymentProps> = () => {
  const creditDetails = paymentSelectors.getDetailBill();
  const { ticketId } = useParams();
  const [loading, setLoading] = useState(false);

  const handlePay = async () => {
    try {
      setLoading(true);
      const res = await paymentApis.payBill(ticketId || '');
      if (res?.data?.message === 'Success') {
        const newUrl = res?.data?.data?.information;
        window.location.href = newUrl;
      }
      setLoading(false);
    } catch (error) {
      const msg = get(error, 'response.data.message', '');
      toast.error(msg || 'An error occurred, please try again!');
      setLoading(false);
    }
  };

  return (
    <>
      <HeaderContentBoxStyled>
        <HeaderStyled>Summary</HeaderStyled>
      </HeaderContentBoxStyled>
      <PaymentDetailsView />
      <FooterBoxStyled>
        <TotalBoxStyled>
          <TotalContentStyled>Total</TotalContentStyled>
          <TotalCurrencyStyled>
            {formatCurrency(creditDetails?.ticketInfo?.total || 0)}
          </TotalCurrencyStyled>
        </TotalBoxStyled>
        <Button
          width="100%"
          ntype="PRIMARY_DEFAULT"
          size="large"
          onClick={handlePay}
          loading={loading}
        >
          PAY
        </Button>
      </FooterBoxStyled>
    </>
  );
};

export default CreditPayment;
const FooterBoxStyled = styled.div`
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  border-top: 1px solid #e5e5e5;
  background: #fff;
`;

const TotalBoxStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TotalContentStyled = styled.div`
  color: #1d2129;

  /* Body/Body_regular */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.5%; /* 19.25px */
`;

const TotalCurrencyStyled = styled.div`
  color: #d2464f;
  text-align: right;

  /* Headline/H6 */
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 137.5%; /* 24.75px */
`;
