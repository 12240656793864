import { LoadingFull } from 'components/Loading';
import billActions from 'features/receipt/services/actions';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import shopActions from 'services/shop/actions';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';

const PrivateLayout = () => {
  const { shop_id = '', bill_id = '' } = useParams();
  const dispatch = useAppDispatch();
  const loadingShop = shopSelectors.getShopInfoLoading();

  useEffect(() => {
    if (shop_id) {
      dispatch(shopActions.getShopInfo.fetch(shop_id));
    }

    if (bill_id && location.pathname.endsWith('/receipt')) {
      dispatch(billActions.fetchReceiptData({ billId: bill_id, shopId: shop_id }));
    }
  }, []);

  if (loadingShop) return <LoadingFull />;

  return (
    <div><Outlet /></div>
  );
};

export default PrivateLayout;
