import { Carousel, Col, Row } from 'antd';
import { first } from 'lodash';
import { useEffect, useMemo } from 'react';
import shopSelectors from 'services/shop/selectors';
import { ICategoryItemData } from 'services/shop/types/categories';
import styled from 'styled-components';
function paginate<Model = any>(arr: Model[], size: number) {
  // @ts-ignore
  return arr.reduce((acc, val, i) => {
    // @ts-ignore
    const idx = Math.floor(i / size); const page = acc[idx] || (acc[idx] = []); page.push(val);
    return acc;
  }, []) as Model[][];
}
type Props = {
  selectedServices: string[];
  activeCategory: ICategoryItemData | null;
  setActiveCategory: (activeCategory: ICategoryItemData) => void;
};
const Categories = ({ selectedServices = [], activeCategory, setActiveCategory }: Props) => {
  const categories = shopSelectors.data.categories();
  const carouselItems = useMemo(() => paginate<ICategoryItemData>(categories, 4), [categories]);

  useEffect(() => {
    if (!activeCategory) {
      const item = first(categories);
      if (item) setActiveCategory(item);
    }
  }, []);

  const renderItem = (item: ICategoryItemData) => {
    const amountSelect = item.services.filter(o => selectedServices.includes(o.id))?.length || 0;
    return (
      <Col span={6} key={item.id}>
        <CategoryItem
          label={item.categoryName}
          onClick={() => setActiveCategory(item)}
          active={activeCategory?.id === item.id}
          amountSelect={amountSelect}
        />
      </Col>
    );
  };



  return (
    <WrapCarousel>
      <Carousel infinite={false}>
        {carouselItems.map((items, index) => (
          <div key={index}>
            <Row gutter={[16, 16]} style={{ width: '100%', paddingLeft: '1rem' }}>
              {items.map(renderItem)}
            </Row>
          </div>
        ))}
      </Carousel>
    </WrapCarousel>
  );
};

export default Categories;

const WrapCarousel = styled.div`
.ant-carousel {
  .slick-dots-bottom {
    position: relative;
    bottom: unset;
  }

  .slick-dots {
    li {
      background: #E5E5E5;
      button {
        
      }
      &.slick-active {
        button {
          background: #FF8890;
        }
      }
    }
  }
}

`;

type CategoryItemProps = {
  active?: boolean;
  label: string;
  amountSelect?: number;
  onClick?: () => void;
};
const CategoryItem = ({ active, amountSelect, label, onClick }: CategoryItemProps) => {

  if (amountSelect) {
    return (
      <CategoryItemStyled selected active={active} onClick={onClick}>
        <div className='content'>
          <div className='tick-icon'>{active ? <TickIconWhite /> : <TickIconBlack />}</div>
          <div className='label-item text-overflow'>
            {label || '-'}
          </div>
          <div className='service-text'>{amountSelect} service{amountSelect > 1 ? 's' : ''}</div>
        </div>
      </CategoryItemStyled>
    );
  }

  return (
    <CategoryItemStyled active={active} onClick={onClick}>
      <div className="label-item">{label || '-'}</div>
    </CategoryItemStyled>
  );
};

const CategoryItemStyled = styled.button <{ selected?: boolean, active?: boolean }>`
    width: 100%;  
    display:flex;
    align-items: center;
    justify-content: center;
    height: 8rem;
    padding: 0 4px;
    gap: 0.5rem;

    border-radius: 5px;
    border-bottom: 2px solid var(--line-line-1, #DDD);
    background: var(--fill-fill-1, #F6F7FC);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .content {
      max-width: 85%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }

    .tick-icon {
      width: 1rem;
    }

    .label-item {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 137.5%; /* 19.25px */
    }

    .service-text {
      color: var(--text-text-2, #767676);
      font-family: Poppins;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
    }

    &:hover {
      background-color: #E5E6EB;
    }

    ${({ selected }) => selected && `
      flex-direction: column;
      justify-content: center;
      padding: 0 4px;
      border-bottom: 1px solid var(--line-line-1, #DDD);
      background: #FFF1F6;
      &:hover {
        background: #FFF1F6;
      }
    `}

    ${({ active }) => active && `
      border-bottom: 1px solid var(--info-infor-3, #0561AE);
      background: #FF8890;
      .label-item, .service-text {
        color: #fff;
      }
      &:hover {
        background: #FF8890;
      }
    `}

`;

const TickIconWhite = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
    <path d="M5.72692 11.7369C5.40676 11.7369 5.10261 11.6088 4.8785 11.3847L0.348172 6.85445C-0.116057 6.39022 -0.116057 5.62184 0.348172 5.15761C0.812402 4.69338 1.58078 4.69338 2.04501 5.15761L5.72692 8.83943L13.955 0.611356C14.4192 0.147126 15.1876 0.147126 15.6518 0.611356C16.1161 1.07559 16.1161 1.84397 15.6518 2.30819L6.57534 11.3847C6.35122 11.6088 6.04707 11.7369 5.72692 11.7369Z" fill="white" />
  </svg>
);

const TickIconBlack = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12" fill="none">
    <path d="M6.06041 11.7369C5.74025 11.7369 5.4361 11.6088 5.21199 11.3847L0.681668 6.85445C0.217439 6.39022 0.217439 5.62184 0.681668 5.15761C1.1459 4.69338 1.91428 4.69338 2.37851 5.15761L6.06041 8.83943L14.2885 0.611356C14.7527 0.147126 15.5211 0.147126 15.9853 0.611356C16.4496 1.07559 16.4496 1.84397 15.9853 2.30819L6.90883 11.3847C6.68472 11.6088 6.38057 11.7369 6.06041 11.7369Z" fill="#292D32" />
  </svg>
);
