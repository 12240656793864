import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import TipItemBox from './components/TipItemBox';
import paymentSelectors from 'features/payment/services/selectors';
import Button from 'components/Button';
import paymentApis from 'features/payment/services/apis';
import { useNavigate } from 'react-router-dom';
import {
  HeaderContentBoxStyled,
  HeaderStyled,
} from 'features/payment/components/Styled';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';

type ISuccessProps = {};

const Success: React.FC<ISuccessProps> = () => {
  const [tip, setTip] = useState(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const creditDetails = paymentSelectors.getDetailBill();
  const billTotal = creditDetails?.ticketInfo?.total || 0;

  const handleClickTipItem = (value: number, onChoose: boolean) => {
    if (onChoose) {
      setTip(0);
    } else {
      const tip = (billTotal * (value / 100)).toFixed(2);
      setTip(Number(tip));
    }
  };
  const onChange = (value: number | string | null) => {
    setTip(Number(value || 0));
  };

  const handleAddTip = async () => {
    try {
      setLoading(true);
      const billId = creditDetails?.ticketInfo?.billId || '';
      await paymentApis.addTipCreditCard(billId, tip);
      navigate('success');
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTip(Number(creditDetails?.ticketInfo?.tip || 0));
  }, [creditDetails?.ticketInfo?.tip]);

  return (
    <>
      <HeaderContentBoxStyled>
        <HeaderStyled>Add Tip</HeaderStyled>
      </HeaderContentBoxStyled>
      <BillBoxStyled>
        <BillFirstContentStyled>YOUR BILL</BillFirstContentStyled>
        <BillSecondContentStyled>
          {formatCurrency(billTotal)}
        </BillSecondContentStyled>
        <BillThirdContentStyled>
          Tip: {formatCurrency(tip)}
        </BillThirdContentStyled>
      </BillBoxStyled>
      <InputBoxStyled>
        <InputBoxTitleStyled>Select a Tip</InputBoxTitleStyled>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <TipItemBox tip={tip} value={18} clickItem={handleClickTipItem} />
          </Col>
          <Col span={12}>
            <TipItemBox tip={tip} value={20} clickItem={handleClickTipItem} />
          </Col>
          <Col span={12}>
            <TipItemBox tip={tip} value={25} clickItem={handleClickTipItem} />
          </Col>
          <Col span={12}>
            <TipItemBox tip={tip} value={30} clickItem={handleClickTipItem} />
          </Col>
        </Row>
      </InputBoxStyled>
      <InputBoxStyled>
        <InputBoxTitleStyled>Or enter custom tip</InputBoxTitleStyled>
        <div>
          <CurrencyInputPrecision
            value={String(tip) || ''}
            onChange={onChange}
          />
          <TipNoticePercentage>
            {tip
              ? `${formatCurrency(tip || 0)} = ${(
                  (tip / billTotal) *
                  100
                ).toFixed(2)}%`
              : ''}
          </TipNoticePercentage>
        </div>
      </InputBoxStyled>
      <InputBoxStyled>
        <Button
          loading={loading}
          ntype="PRIMARY_DEFAULT"
          onClick={handleAddTip}
        >
          NEXT
        </Button>
        <Button ntype="SECONDARY_MOBILE" onClick={() => navigate('success')}>
          No Tip
        </Button>
      </InputBoxStyled>
    </>
  );
};

export default Success;
const BillBoxStyled = styled.div`
  display: flex;
  padding: 1rem 0;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  background: #488c98;
`;

const BillFirstContentStyled = styled.div`
  color: #fff;
  text-align: center;

  /* Headline/H6_regular */
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.5%; /* 24.75px */
`;
const BillSecondContentStyled = styled.div`
  color: #fff;
  text-align: center;

  /* Headline/H3 */
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 137.5%; /* 44px */
`;
const BillThirdContentStyled = styled.div`
  color: #ffdc7c;
  text-align: center;

  /* Headline/H6 */
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 137.5%; /* 24.75px */
`;

const InputBoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
`;
const InputBoxTitleStyled = styled.div`
  color: #1d2129;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.5%; /* 24.75px */
`;

const TipNoticePercentage = styled.div`
  display: flex;
  justify-content: end;
  color: #d2464f;
  margin-top: 0.25rem;
  /* Body/Small_1_regular */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.5%; /* 16.5px */
`;
