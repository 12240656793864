import { QRCode } from 'antd';
import { LoadingFull } from 'components/Loading';
import Text from 'components/Text';
import NotFoundPage from 'features/NotFound';
import { get } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import shopApis from 'services/shop/apis';
import shopSelectors from 'services/shop/selectors';
import { ISocialMedia } from 'services/shop/types/socialMedia';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import ShopInfo from 'widgets/ShopInfo';
import divider_bg from './divider-bg.png';
import icon_facebook from './icon-facebook.png';
import icon_google from './icon-google.png';
import icon_instagram from './icon-instagram.png';
import icon_yelp from './icon-yelp.png';
import { IReviewResData } from './review';
import top_icon from './top-icon.svg';

type ISocialMediaUI = {
  id: string;
  image: any;
  label: string;
} & ISocialMedia;

const SOCIAL_INFO = {
  GOOGLE: { id: 'google', image: icon_google, label: 'Google Review' },
  YELP: { id: 'yelp', image: icon_yelp, label: 'Yelp Review' },
  INSTAGRAM: { id: 'instagram', image: icon_instagram, label: 'Instagram Review' },
  FACEBOOK: { id: 'facebook', image: icon_facebook, label: 'Facebook Review' },
};

const ReviewDetail = () => {
  const { review_id = '', shop_id = '' } = useParams();
  const [reviewData, setReviewData] = useState<IReviewResData | null>(null);
  const [loading, setLoading] = useState(false);
  const socialData = shopSelectors.data.socialMedia();
  const setLoadingPage = useSetLoadingPage();

  const getReviewDetail = async () => {
    setLoading(true);
    try {
      const res: IResponseDataBody<any> = await shopApis.getReviewDetail(shop_id, review_id);
      if (res.data.data) {
        setReviewData(res.data.data);
      }
    } catch (error) { } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getReviewDetail();
  }, []);

  const couponData = useMemo(() => {
    const coupon = reviewData?.coupon;
    if (!coupon) return null;
    const days = moment(coupon.endTime).diff(moment(), 'days');

    return ({
      type: coupon.discountType,
      value: coupon.discountValue,
      name: coupon.couponName,
      subTitle: coupon.title,
      code: coupon.couponCode,
      description: coupon.description,
      validDate: coupon.endTime ? `${days} Days` : '',
      urlImage: coupon.urlImage,
    });
  }, [reviewData]);

  const socials = useMemo(() => {
    const data: ISocialMediaUI[] = [];
    socialData.map((o) => {
      const localData = get(SOCIAL_INFO, [o.sourceType], null);
      if (!localData) return;
      data.push({
        ...o,
        ...localData,
      });
    });
    return data;
  }, [socialData]);

  const handleChooseSocial = async (o: ISocialMediaUI) => {
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await shopApis.updateChannelReview(review_id, o.sourceType);
      if (res.data.data) {
        window.open(o.urlSocial, '_blank');
      }
    } catch (error) { } finally {
      setLoadingPage(false);
    }
  };

  const Coupon = () => {
    if (!couponData) return null;
    return (
      <>
        <Text className="sub_title" mb={1.5}>
          Leave a review on our social website and <br />get coupon up to {couponData.type === 'PERCENT' ? `${couponData.value}%` : formatCurrency(couponData.value)} off
        </Text>

        {couponData.urlImage ? <div className='coupon-image' style={{backgroundImage: `url(${couponData.urlImage})`}} /> : (
          <CouponStyled>
            <div className="circle" style={{ left: -25 }} />
            <div className="content">
              <div className="coupon-info">
                <div className="coupon-money">
                  {couponData.type !== 'PERCENT' ? (
                    <div className="coupon-money-top">
                      <span className="dollar">$</span>
                      <span className="value">{couponData.value}</span>
                      <span className="suffix">00</span>
                    </div>
                  ) : (
                    <div className="coupon-percent-top">
                      <span className="percent">{couponData.value}</span>
                      <span className="percent-suffix">%</span>
                    </div>
                  )}
                  <div className={`coupon-money-bottom ${couponData.type === 'PERCENT' ? 'percent' : ''}`}>
                    <span>OFF</span>
                  </div>
                </div>
                <div className="coupon-divider"></div>
                <div className="coupon-name">
                  <img className='top-icon' src={top_icon} alt="top_icon" />
                  <span className="name">{couponData.name}</span>
                  <span className="sub-name">{couponData.subTitle}</span>
                </div>
                <div className="coupon-QR">
                  <div className="coupon-QR-image">
                    <QRCode color='#fff' bordered={false} size={70} value={couponData.code} />
                  </div>
                  <div className="coupon-QR-value">{couponData.code}</div>
                </div>
              </div>
            </div>
            <div className="coupon-note">
              <span className="coupon-disc">
                {couponData.description}
              </span>
              <div className="valid-box" style={reviewData?.coupon?.endTime ? {} : { width: '3rem' }}>
                {
                  reviewData?.coupon?.endTime ? <>
                    <span className="normal">VALID DATE</span>
                    <span className="value">{couponData.validDate}</span>
                  </> : <>
                    <span className="value" >Expire</span>
                    <span className="value">Date</span>
                  </>
                }

              </div>
            </div>
            <div className="circle" style={{ right: -25 }} />
          </CouponStyled>
        )}


      </>
    );
  };

  const Socials = () => {
    return (
      <>{socials?.map(o => (
        <SocialStyled key={o.id} onClick={() => handleChooseSocial(o)}>
          <img className='icon' src={o.image} />
          <span className="social-label">{o.label}</span>
        </SocialStyled>
      ))}</>
    );
  };

  if (loading) return <LoadingFull />;

  if (!reviewData) return <NotFoundPage />;

  return (
    <PageStyled>
      <ShopInfo />
      <ContainerBox>
        <div className="title_page">
          Thank You For<br />Your Business
        </div>
        <Coupon />
      </ContainerBox>
      <SocialContainer>
        <Socials />
      </SocialContainer>
    </PageStyled>
  );
};

export default ReviewDetail;

const PageStyled = styled.div`
    background: #E5E5E5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    .banner {
        margin-bottom: 2rem;
    }
    @media only screen and (max-width: 768px) {
      .banner {
        margin-bottom: 8px;
      }
    }
`;

const SocialContainer = styled.div`
  background: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 16px;
  grid-gap: 8px;
`;

const SocialStyled = styled.div`
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    border: 2px solid rgba(201, 205, 212, 0.50);
    background: #FFF;
    box-shadow: 0px 12px 24px -8px rgba(0, 0, 0, 0.15);
    .icon {
      display: flex;
      width: 35px;
      height: 35px;
      justify-content: center;
      align-items: center;
    } 
    .social-label {
      color: #566A7F;
      text-align: center;
      font-family: Poppins;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.7px;
      text-transform: uppercase;
    }
`;

const CouponStyled = styled.div`
  width: 100%;
  background: #1A0F00;
  border-radius: 16px;
  position: relative;
  .circle {
    position: absolute;
    top: 60px;
    background: #E5E5E5;
    width: 45px;
    height: 45px;
    border-radius: 100%;
  }
  padding: 12px 0;
  .content {
    margin-left: 26px;
    margin-right: 30px;
    .coupon-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 12px;
      .coupon-money {
        display: flex;
        padding: 6px 0;
        flex-direction: column;
        .coupon-money-top {
          display: flex;
          align-items: flex-start;
          height: 40px;
        }
        .coupon-percent-top {
          display: flex;
          align-items: flex-start;
          height: 40px;
          span.percent {
            color: #FFF;
            font-family: Inter;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          span.percent-suffix {
            color: #FFF;
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        span.dollar {
          color: #FFF;
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 19.244px; /* 96.221% */
        }
        span.value {
          color: #FFF;
          font-family: Archivo;
          font-size: 45.571px;
          font-style: normal;
          font-weight: 600;
          line-height: 1;
          transform: translateY(-5px);
        }
        span.suffix {
          color: #FFF;
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 15.636px; /* 78.18% */
          letter-spacing: -1.2px;
          text-decoration-line: underline;
        }

        .coupon-money-bottom {
          padding-left: 10px;
          span {
            color: #fff;
            font-family: Montserrat;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          &.percent {
            padding-left: 5px;
          }
        }
      }

      .coupon-divider {
        background: url(${divider_bg});
        width: 3.5px;
        align-self: stretch;
        background-size: contain;
        margin-left: 5px;
        margin-right: 10px;
        background-repeat: repeat-y;
      }


      .coupon-name {
        display: flex;
         flex-direction: column;
         gap: 4px;
         transform: translateY(-5px);
         flex: 1;
         .top-icon {
          width: 22px;
         }
         span.name {
          color: #FFF;
          font-family: Archivo;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
         }
         span.sub-name {
          color: #FFF;
          font-family: Archivo;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
         }
      }


      .coupon-QR {
        .coupon-QR-image {
          border: 1px solid #FFF;
          border-radius: 5px;
          margin-bottom: 4px;
          .ant-qrcode {
            padding: 0;
            margin: 0;
            width: 55px !important;
            height: 54px !important;
            overflow: unset;
          }
        }

        .coupon-QR-value {
          color: #FFF;
          text-align: center;
          font-family: Poppins;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

      }

    }
  }
  
  .coupon-note {
    padding: 4px;
    background: rgba(255, 255, 255, 0.30);
    margin: 0 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    .coupon-disc {
      flex: 1;
      color: #FFF;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 0 10px;
      white-space: break-spaces;
    }

    .valid-box {
      background: #190F00;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-self: stretch;
      padding: 4px;

      span.normal {
        color: #fff;
        text-align: center;
        font-family: Poppins;
        font-size: 7px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }

      span.value {
        color: #fff;
        text-align: center;
        font-family: Poppins;
        font-size: 9px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
`;

const ContainerBox = styled.div`
  padding:16px;
    .title_page {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      margin-bottom: 8px;
    }
    .sub_title {
      color: #566A7F;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .coupon-image {
      width: 100%;
      height: 152.646px;
      border-radius: 5px;
      background: rgba(255, 255, 255, 0.30);
      background-size: cover;
      background-repeat: no-repeat;
    }
`;
