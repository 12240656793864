import fetch from 'services/request';
import { IBodyAPIBuyGiftCard } from './types/api';

const baseURL = 'api/v1/gift-card';

const getGiftCardDetail = (id: string, shopId?: string) => {
  return fetch({
    url: baseURL + `/gift-card-to-buy/${id}`,
    shopId,
  });
};

const giftCardToBuy = (shopId?: string) => {
  return fetch({
    url: baseURL + '/gift-card-to-buy',
    shopId,
  });
};

const buyGiftCard = (body: IBodyAPIBuyGiftCard, shopId?: string) => {
  return fetch({
    method: 'post',
    url: baseURL + '/buy',
    shopId,
    body,
  });
};

const getPaymentGiftCardInfo = (id: string, shopId?: string) => {
  return fetch({
    url: baseURL + '/get-payment-gift-card-info/' + id,
    shopId,
  });
};

const giftCardApis = {
  giftCardToBuy,
  getGiftCardDetail,
  buyGiftCard,
  getPaymentGiftCardInfo,
};

export default giftCardApis;
