import { Col, Row } from 'antd';
import { LoadingFull } from 'components/Loading';
import Text from 'components/Text';
import NotFoundPage from 'features/NotFound';
import CouponDiscount from 'features/coupon/template/Discount';
import CouponGiftCard from 'features/coupon/template/GiftCard';
import CouponLoyaltyPoint from 'features/coupon/template/LoyaltyPoint';
import React, { useCallback, useMemo } from 'react';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { PHONE_MASKED } from 'utils/mask';
import { momentTimezone } from 'utils/time';
import VoidReceipt from './VoidReceipt';
import { OTHER_PAYMENT, PAYMENT_TYPE, ParamsStatus } from './services/constants';
import billSelectors from './services/selectors';
import { IPaymentCreditCardInfo } from './services/types/payment';
import ReceiptPageStyled, { CardBox, CountingBox, FinalBox, IconLogo, MoneyBox, PaddingBox, PriceBoxStyled, PrintBodyStyled, ServiceBoxStyled, ShopText, SpaceBetweenBox, StaffHeaderBox, StyledDivider, TicketBox, TicketCodeBox, TotalBox } from './styles';
import RefundReceipt from './RefundReceipt';

type IReceiptPageProps = any;
const ReceiptPage: React.FC<IReceiptPageProps> = () => {
  const coupons = billSelectors.billCoupons();
  const shopInfo = shopSelectors.getShopInfo();
  const data = billSelectors.billDetail();
  const isLoading = billSelectors.receiptLoading();

  const describePayment = useMemo(() => [
    { name: 'Service Charge', price: data?.cardFee },
    { name: 'Sale Tax', price: data?.saleTax },
    { name: 'Use Tax', price: data?.useTax },
    { name: 'Disc. By Item', price: data?.discountItem },
    { name: 'Disc. By Ticket', price: data?.discountTicket },
  ], [data]);

  const havePayments = useMemo(() => {
    let loyalty = false;
    let giftCard = false;
    let creditCard = false;
    let check = false;
    data?.payments.map(o => {
      if (o.paymentType === PAYMENT_TYPE.LOYALTY_POINT) {
        loyalty = true;
      }
      if (o.paymentType === PAYMENT_TYPE.GIFT_CARD) {
        giftCard = true;
      }
      if (o.paymentType === PAYMENT_TYPE.CREDIT_CARD) {
        creditCard = true;
      }
      if (o.paymentType === PAYMENT_TYPE.CHECK && o.otherMethod === OTHER_PAYMENT.CHECK) {
        check = true;
      }
    });

    return ({
      loyalty,
      giftCard,
      isHaveSignature: creditCard || check,
    });

  }, [data]);

  const moneyCard = useMemo(() => {
    return [
      { name: 'Loyalty Program', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.LOYALTY_POINT)?.amount },
      { name: 'Gift Card', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.GIFT_CARD)?.amount },
      { name: 'Cash', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CASH)?.amount },
      { name: 'Check', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CHECK)?.amount },
      { name: 'Cash pay', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CHECK && o.otherMethod === OTHER_PAYMENT.CASH_APPS)?.amount },
      { name: 'Zelle', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CHECK && o.otherMethod === OTHER_PAYMENT.ZELLE)?.amount },
      { name: 'Paypal', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CHECK && o.otherMethod === OTHER_PAYMENT.PAYPAL)?.amount },
      { name: 'Venmo', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CHECK && o.otherMethod === OTHER_PAYMENT.VENMO)?.amount },
    ].filter(o => !!o.price);
  }, [data]);

  const creditCard = useMemo(() => {
    const creditData = data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CREDIT_CARD) as (IPaymentCreditCardInfo | undefined);
    if (!creditData) return null;
    return {
      endCode: creditData.last4,
      cardType: creditData.cardType,
      authCode: creditData.authCode,
      trans: creditData.transNumber,
      entryType: creditData.entryType,
      amount: creditData?.amount,
    };
  }, [data]);

  const Coupons = useCallback(() => {
    if (!coupons) return null;
    return <>
      <pre />
      <CouponsStyled>
        {coupons?.DISCOUNT && (<CouponDiscount data={coupons.DISCOUNT} />)}
        {coupons?.GIFT_CARD && (<CouponGiftCard data={coupons.GIFT_CARD} />)}
        {coupons?.LOYALTY_POINT && (<CouponLoyaltyPoint data={coupons.LOYALTY_POINT} />)}
      </CouponsStyled>
    </>;
  }, [coupons]);

  if (isLoading) return <LoadingFull />;

  if (!data) return <NotFoundPage />;

  if (data?.billStatus === ParamsStatus.VOID) return <VoidReceipt data={data} />;
  if (data?.billStatus === ParamsStatus.REFUND) return <RefundReceipt data={data} />;

  return (
    <ReceiptPageStyled>
      <PrintBodyStyled className="box">
        <TicketBox>
          <CountingBox>
            <Row justify={'space-between'} align={'middle'}>
              <Col>
                <IconLogo />
                <TicketCodeBox>
                  #{data.ticketNumber}
                </TicketCodeBox>
              </Col>
              <Col>
                <ShopText className='bold' style={{ fontSize: '1.5rem' }}>{shopInfo?.shopName || ''}</ShopText>
                <ShopText>
                  {shopInfo?.address || ''}{', ' + shopInfo?.city || ''}
                </ShopText>
                <ShopText>
                  {PHONE_MASKED.resolve(shopInfo?.phone || '')}
                </ShopText>
              </Col>
            </Row>
            <Text printMode mb={1} />
            <SpaceBetweenBox>
              <Text printMode variant="CONTENT_2" className='customer_name'>{data.customerName || 'Unknown Customer'}</Text>
              {!!data?.customerPoint && <Text printMode variant="CONTENT_1" color="text_2">
                {data?.customerPoint || 0} Point{(data?.customerPoint || 0) > 1 ? 's' : ''}
              </Text>}
            </SpaceBetweenBox>
            <Text printMode variant="CONTENT_1" color="text_2">
              {momentTimezone(data.startTime).format('MM-DD-YYYY LT')}
            </Text>
            <Text printMode mb={1} />
            {data?.items?.map((item) => (
              <div key={item.staffId}>
                <StaffHeaderBox>{item.staffName}</StaffHeaderBox>
                <ServiceBoxStyled>
                  {item.services.map((service) => (
                    <div key={service.itemId}>
                      <SpaceBetweenBox>
                        <Text printMode variant="CONTENT_1" color="text_3">
                          {service.itemName}
                        </Text>
                        <PriceBoxStyled>
                          <Text printMode variant="CONTENT_1" color="text_3">
                            {formatCurrency(service.price)}
                          </Text>
                        </PriceBoxStyled>
                      </SpaceBetweenBox>
                    </div>
                  ))}
                </ServiceBoxStyled>
              </div>
            ))}

            <StyledDivider my={0.5} />
            <PaddingBox>
              {describePayment.map((item) => (
                <SpaceBetweenBox key={item.name}>
                  <Text printMode variant="BODY_1" color="text_3">
                    {item.name}
                  </Text>
                  <Text printMode variant="CONTENT_1" color="text_3">
                    {formatCurrency(item.price)}
                  </Text>
                </SpaceBetweenBox>
              ))}
            </PaddingBox>
            <StyledDivider mt={0.5} mb={1} />
            <SpaceBetweenBox>
              <Text printMode variant="BODY_1" color="text_3">
                Subtotal
              </Text>

              <Text printMode variant="CONTENT_1" color="text_3">
                {formatCurrency(data?.subTotal)}
              </Text>
            </SpaceBetweenBox>
            <StyledDivider mt={0.5} mb={1} />
            <SpaceBetweenBox>
              <Text printMode variant="CONTENT_2" color="text_3">
                Tip
              </Text>
              <Text printMode variant="H7" color="text_3">
                {formatCurrency(data?.tip)}
              </Text>
            </SpaceBetweenBox>
          </CountingBox>
          <TotalBox>
            <SpaceBetweenBox>
              <Text printMode variant="CONTENT_2" color="text_3">
                Total
              </Text>
              <Text printMode variant="H7" color="text_3">
                {formatCurrency(data?.total)}
              </Text>
            </SpaceBetweenBox>
          </TotalBox>
          <CardBox>
            {moneyCard.length > 0 && <MoneyBox>
              {moneyCard.map((item) => (
                <SpaceBetweenBox key={item.name}>
                  <Text printMode variant="BODY_1" color="text_3" style={{ fontWeight: '600' }}>
                    {item.name}
                  </Text>
                  <Text printMode variant="CONTENT_1" color="text_3">
                    {formatCurrency(item.price)}
                  </Text>
                </SpaceBetweenBox>
              ))}
            </MoneyBox>}

            {(havePayments.loyalty || havePayments.giftCard) &&
              <>
                {havePayments.loyalty &&
                  <SpaceBetweenBox my={0.5}>
                    <Text printMode variant="BODY_1" color="text_3">
                      Available Lotalty Points
                    </Text>
                    <Text printMode variant="CONTENT_1" color="text_3">
                      {formatCurrency(data?.availableLoyaltyPoint)}
                    </Text>
                  </SpaceBetweenBox>}

                {havePayments.giftCard &&
                  <SpaceBetweenBox mb={0.5}>
                    <Text printMode variant="BODY_1" color="text_3">
                      Available Gift Card
                    </Text>

                    <Text printMode variant="CONTENT_1" color="text_3">
                      {(data?.availableGiftCard)} point
                    </Text>
                  </SpaceBetweenBox>}
              </>}

            {creditCard && <>
              <PaddingBox>
                <SpaceBetweenBox>
                  <Text printMode variant="BODY_1" color="text_3" style={{ fontWeight: '600' }}>
                    Credit Card {creditCard?.endCode || ''}
                  </Text>
                  <Text printMode variant="CONTENT_1" color="text_3">
                    {formatCurrency(creditCard?.amount)}
                  </Text>
                </SpaceBetweenBox>
                <Text my={0.5} printMode variant="BODY_1" color="text_3">
                  Card Type: {creditCard?.cardType}
                </Text>
                <Text mb={0.5} printMode variant="BODY_1" color="text_3">
                  authCode: {creditCard?.authCode}
                </Text>
                <Text mb={0.5} printMode variant="BODY_1" color="text_3">
                  Trans Number #: {creditCard?.trans}
                </Text>
                <Text mb={0.5} printMode variant="BODY_1" color="text_3">
                  Entry Type: {creditCard?.entryType}
                </Text>
              </PaddingBox>
            </>}
          </CardBox>
          {havePayments.isHaveSignature && <>
            <Divider mb={1} />
            <CardBox>
              <SignatureBox>
                {!data.signature && <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <line x1="0.353553" y1="0.446447" x2="11.5536" y2="11.6464" stroke="black" />
                    <line x1="11.5537" y1="0.353553" x2="0.353749" y2="11.5536" stroke="black" />
                  </svg>
                </div>}
                {data.signature ?
                  <img className='img' src={data.signature?.includes('http') ? data.signature : 'data:image/png;base64,' + data.signature} />
                  :
                  <div className='blank' />}
              </SignatureBox>
              <Text printMode mt={0.5} variant="CONTENT_2" textAlign='center'>
                Signature
              </Text>
            </CardBox>
          </>}
          <FinalBox border={!havePayments.isHaveSignature}>
            <Text style={{ color: 'black', fontSize: 16 }}>Thank you for your business.</Text>
            <Text className='bold' printMode variant='BODY_1' color='text_3'>©Powered by Fozito</Text>
          </FinalBox>
          <Coupons />
        </TicketBox>
      </PrintBodyStyled>
    </ReceiptPageStyled>
  );
};

export default ReceiptPage;

const Divider = styled(Text)`
  border-bottom: 1px solid black;
  /* border-bottom-style: dotted; */
`;

const SignatureBox = styled.div`
  padding: 0.5rem 0;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: center;
  .img {
    width: 100%;
    height: 215px;
    margin: auto;
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
    background: transparent;
  }
  .blank {
    width: 100%;
    height: 4.5rem;
  }
  align-items: end;
`;

const CouponsStyled = styled.div`
  display: flex;  
  padding-bottom: 1rem;
  flex-direction: column;
  align-items: center;
  .SellingBox {
    padding: 0;
    display: flex;
    justify-content: center;
  }
  .CouponInfoBoxStyled {
    margin-bottom: 1rem;
  }
`;