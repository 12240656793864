import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import paymentActions from '../services/actions';
import { ContainerBoxStyled } from './Styled';
type IWrappingPaymentProps = any;
const WrappingPayment: React.FC<IWrappingPaymentProps> = () => {
  const { ticketId = '' } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (ticketId) {
      dispatch(paymentActions.getDetailBill.fetch(ticketId));
    }
  }, []);

  return (
    <ContainerBoxStyled>
      <Outlet />
    </ContainerBoxStyled>
  );
};

export default WrappingPayment;
