import React from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { CheckIcon } from './CheckIcon';
import LineSubtract from '../../../components/LineSubtract';
import paymentSelectors from 'features/payment/services/selectors';
import ConfirmItemReview from './ConfirmItemReview';
import moment from 'moment';

type IPaymentSuccessViewProps = any;
const PaymentSuccessView: React.FC<IPaymentSuccessViewProps> = () => {
  const transactionBillDetails = paymentSelectors.getTransactionBillDetails();
  const cardDetails = transactionBillDetails?.payments?.find(
    (item) => item.paymentType === 'CREDIT_CARD'
  );
  const timeLine = cardDetails?.createDated
    ? moment(cardDetails?.createDated, 'MM-DD-YYYY HH:mm').format(
        'MM-DD-YYYY hh:mm A'
      )
    : '--';
  const creditDetails = paymentSelectors.getDetailBill();

  return (
    <PaymentSuccessViewStyled>
      <ItemContainerHeader>
        <CheckIcon />
        <FirstHeaderContent>Payment Success!</FirstHeaderContent>
        <SecondHeaderContent>
          {formatCurrency(creditDetails?.ticketInfo?.total || 0)}
        </SecondHeaderContent>
      </ItemContainerHeader>
      <LineSubtract />
      <ItemContainerBody>
        <ConfirmItemReview
          label="Tran#"
          hasBox={true}
          value={
            cardDetails?.transNumber ? `#${cardDetails?.transNumber}` : '--'
          }
        />
        <ConfirmItemReview label="Timeline" value={timeLine || '--'} />
        <ConfirmItemReview
          label="Card Type"
          value={cardDetails?.cardType || '--'}
        />
        <ConfirmItemReview
          label="authCode"
          value={cardDetails?.authCode || '--'}
        />
        <ConfirmItemReview
          label="Method"
          value={cardDetails?.entryType || '--'}
        />
      </ItemContainerBody>
    </PaymentSuccessViewStyled>
  );
};

export default PaymentSuccessView;
type PaymentSuccessViewStyledProps = {};
const PaymentSuccessViewStyled = styled.div<PaymentSuccessViewStyledProps>`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 1.5rem;
`;
const ItemContainerHeader = styled.div`
  padding: 1rem;
  background: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
`;
const FirstHeaderContent = styled.div`
  color: #1d2129;
  text-align: center;

  /* Headline/H6 */
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 137.5%; /* 24.75px */
`;
const SecondHeaderContent = styled.div`
  color: #1d2129;
  text-align: center;

  /* Headline/H4_semibold */
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 137.5%; /* 33px */
`;

const ItemContainerBody = styled.div`
  padding: 1rem;
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;
