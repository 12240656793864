import fetch from 'services/request';

const getInfo = (id: string, shopId?: string) => {
  return fetch({
    method: 'post',
    url: `/api/v1/gift-card/detail/${id}`,
    shopId,
  });
};

const giftCardPaidApis = {
  getInfo,
};

export default giftCardPaidApis;
