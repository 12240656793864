import fetch from 'services/request';
import { IConfirmCheckIn, ISignUpForm } from './types/api';

const getCustomerInfo = (phoneNumber: string, shopId?: string) => {
  return fetch({
    method: 'get',
    url: '/api/v1/check-in/get-info-by-phone',
    params: { phoneNumber },
    shopId,
  });
};

const customerSignUp = (body: ISignUpForm) => {
  return fetch({
    method: 'post',
    url: 'api/v1/customer/create',
    body: body,
    autoToast: false,
  });
};

const confirmWalkIn = (body: IConfirmCheckIn, station_number: string) => {
  return fetch({
    method: 'post',
    url: 'api/v1/check-in/confirm',
    body: body,
    station_number,
  });
};

const getInfoPrintWaiting = (type: string, id: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/check-in/get-info-print-waiting/' + id,
    params: { type }
  });
};

const getCustomerAppointment = (customerId: string, station_number: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/check-in/get-info-appointment-by-customer',
    params: { customerId },
    station_number,
  });
};

const checkInApis = {
  getCustomerInfo,
  customerSignUp,
  confirmWalkIn,
  getInfoPrintWaiting,
  getCustomerAppointment
};

export default checkInApis;
