import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { IDEMOModelResDataItem } from './types/demoData';
import uiSelector from 'services/UI/selectors';
import { PATH_LOADING } from './constants';
import { PaymentDetailsView } from './types/paymentDetailsView';
import { TransactionBillDetailsType } from './types/transactionBillDetails';
/**
 * please change reducer name
 */
// @ts-ignore
type MyState = RootState['payment']; // ex: RootState['booking']
// @ts-ignore
const getCurrentState = (state: RootState): MyState => state['payment']; // ex: state['booking']

const selector = <T = MyState>(key: keyof T, defaultValue?: any) =>
  useAppSelector((state) => get(getCurrentState(state), key, defaultValue));

const getDemoData = () => selector('demoData') as IDEMOModelResDataItem[];

const getDemoDataLoading = () =>
  uiSelector.getLoading(PATH_LOADING.getData) as boolean;

const getDetailBill = () => selector('creditDetails') as PaymentDetailsView;
const getTransactionBillDetails = () => selector('transactionBillDetails') as TransactionBillDetailsType;

const paymentSelectors = {
  getDemoData,
  getDemoDataLoading,
  getDetailBill,
  getTransactionBillDetails
};
export default paymentSelectors;
