import fetch from 'services/request';
export type IApiGetSalonSummaryParams = {
  startDate?: string, endDate?: string;
};
const getSalonSummary = (params: IApiGetSalonSummaryParams) => fetch({
  method: 'get',
  url: 'api/v1/print-report/summary-salon',
  params,
});
const reportApis = {
  getSalonSummary,
};

export default reportApis;
