import fetch from 'services/request';

import { IApiGetAppointments, IBodyApiMakeAppointment, IBodyApiMakeLockTime, IBodyApiMakeQuickServicesAppointment, IBodyApiUpdateAppointmentDetail, IBodyApiUpdateAppointmentWithDrop } from './types/api';
import { APPOINTMENT_STATUS, PROLONGED_TIME_STATUS } from './constants';

export const getAppointments = async (params: IApiGetAppointments) => {
  return fetch({
    method: 'get',
    url: '/api/v1/appointment/all',
    params,
  });
};

const getLockBreakTimes = (params: IApiGetAppointments) => {
  return fetch({
    method: 'get',
    url: '/api/v1/appointment/lock-time',
    params
  });
};

const makeNewAppointment = (body: IBodyApiMakeAppointment) => {
  return fetch({
    method: 'post',
    url: '/api/v1/appointment/booking',
    body,
  });
};

const makeNewQuickBookAppointment = (body: IBodyApiMakeQuickServicesAppointment) => {
  return fetch({
    method: 'post',
    url: '/api/v1/appointment/quick-book-online',
    body,
  });
};

const makeLockTime = (body: IBodyApiMakeLockTime) => {
  return fetch({
    method: 'post',
    url: '/api/v1/appointment/lock-time',
    body,
  });
};

const getStaffTimeSlot = (staffId: string | null | undefined, date: string) => {
  const params: { staffId?: string, date?: string } = { staffId: staffId || '', date };
  if (!params.staffId) {
    delete params.staffId;
  }

  return fetch({
    method: 'get',
    url: '/api/v1/appointment/available-time-slot-staff',
    params,
  });
};

const addMoreTimeClockTime = (clockId: string) => {
  return fetch({
    method: 'post',
    url: 'api/v1/appointment/lock-time/prolonged-time',
    body: { clockId },
  });
};

const addMoreTimeAppointment = (body: { status: PROLONGED_TIME_STATUS, appointmentId: string }) => {
  return fetch({
    method: 'post',
    url: 'api/v1/appointment/prolonged-time',
    body,
  });
};

const updateAppointmentWithDrop = (body: IBodyApiUpdateAppointmentWithDrop) => {
  return fetch({
    method: 'post',
    url: 'api/v1/appointment/update-with-drop',
    body,
  });

};

const deleteAppointment = (appointmentId: string) => {
  return fetch({
    method: 'delete',
    url: 'api/v1/appointment/' + appointmentId,
  });
};

const updateDetailAppointment = (body: IBodyApiUpdateAppointmentDetail) => {
  return fetch({
    method: 'put',
    url: '/api/v1/appointment/booking',
    body,
  });
};

const getCustomerInfo = (phoneNumber: string, shopId?: string) => {
  return fetch({
    method: 'get',
    url: '/api/v1/check-in/get-info-by-phone',
    params: { phoneNumber },
    shopId,
  });
};

const getAppointmentDetail = (appointmentId: string, shopId?: string) => {
  return fetch({
    method: 'get',
    url: `/api/v1/appointment/detail/${appointmentId}`,
    shopId,
  });
};

const confirmAppointment = (appointmentId: string) => {
  return fetch({
    method: 'post',
    url: '/api/v1/appointment/update-status',
    autoToast: true,
    body: {
      appointmentId,
      status: APPOINTMENT_STATUS.CONFIRM,
    }
  });
};

const cancelAppointment = (appointmentId: string, reason: string) => {
  return fetch({
    method: 'post',
    url: '/api/v1/appointment/update-status',
    autoToast: true,
    body: {
      appointmentId,
      status: APPOINTMENT_STATUS.CANCEL,
      reason
    }
  });
};

const appointmentApis = {
  getAppointments,
  getLockBreakTimes,
  makeNewAppointment,
  makeNewQuickBookAppointment,
  makeLockTime,
  getStaffTimeSlot,
  addMoreTimeAppointment,
  addMoreTimeClockTime,
  updateAppointmentWithDrop,
  deleteAppointment,
  updateDetailAppointment,
  getCustomerInfo,
  getAppointmentDetail,
  confirmAppointment,
  cancelAppointment,
};

export default appointmentApis;
