import { Avatar, Col, Row } from 'antd';
import { Moment } from 'moment';
import { IServiceItemData } from 'services/shop/types/categories';
import { IStaffItemData } from 'services/shop/types/staff';
import styled from 'styled-components';
import { DATE_FORMAT, FORMAT_TIME } from 'utils/format';
import { ICustomerInfo } from './CustomerTab';
import { ButtonActions } from './helpers';
import aptSelectors from './services/selectors';
import { IShopAllSetting } from 'services/shop/types/setting';
import { useMemo } from 'react';
import { IUIQuickServiceItem } from './QuickServices/Category';
type Props = {
  timeStart: Moment | null;
  timeEnd: Moment | null;
  customer: ICustomerInfo | null;
  services: IServiceItemData[],
  staff: IStaffItemData | null,
  onBack: () => void;
  onDone: (quickServices?: IUIQuickServiceItem[]) => void;
  onAddNewService: () => void;
  allSetting: IShopAllSetting | null;
};
const Confirm = ({ allSetting, onBack, onDone, customer, services, staff, timeStart, timeEnd, onAddNewService }: Props) => {
  const quickServices = aptSelectors.quickServices();
  const servicesUI = useMemo(() => {
    if (allSetting?.quickBookCheckIn) return quickServices.map(o => ({ name: o.serviceNameValue, id: o.id, duration: 0 }));
    return services.map(o => ({ name: o.serviceName, id: o.id, duration: o.duration || 0 }));
  }, [allSetting, quickServices, services]);

  return (
    <ConfirmStyled>
      <p className="title">Review and confirm selections below</p>
      <Row>
        <Col xs={24} sm={24} md={12} className='border-top'>
          <p className="section-label">
            YOUR INFORMATION
          </p>
          <div className="customer-info">
            <p className='name'>Name: {customer?.name}</p>
            <p className='phone'>Phone: {customer?.phone}</p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} className='border-bottom'>
          <p className="section-label">
            BOOKING
          </p>
          <BoxTimeInfo align={'middle'}>
            <Row align={'middle'} justify={'start'} style={{ flex: 1, gap: '8px' }}>
              <IconClock />
              <p>{timeStart?.format(FORMAT_TIME)} - {timeEnd?.format(FORMAT_TIME)}</p>
            </Row>
            <div className="divider" />
            <Row align={'middle'} justify={'start'} style={{ flex: 1, gap: '8px' }}>
              <IconCalendar />
              <p>{timeStart?.format(DATE_FORMAT)}</p>
            </Row>
          </BoxTimeInfo>
          <RowStyled>
            <label>Staff</label>
            <span style={{ flex: 1 }} />
            <Avatar size={'large'} className='avatar-box' src={staff?.urlImage}>{staff?.firstName?.[0] || ''}</Avatar>
            <span className='staff-name'> {staff?.firstName}</span>
          </RowStyled>
          <p className="section-label" style={{ marginTop: 16 }}>
            SERVICES:
          </p>
          <Services>
            {servicesUI.map((o, i) => (
              <RowStyled key={o.id} className='row-styled'>
                <label>{i + 1}. {o.name}</label>
                {!allSetting?.quickBookCheckIn && <>
                  <span style={{ flex: 1 }} />
                  <span className='duration'> {o.duration}m</span>
                </>}
              </RowStyled>
            ))}
          </Services>
          <button className='add-more-service' onClick={onAddNewService}>+ Add more services</button>
        </Col>
      </Row>

      <ButtonActions
        confirm
        onBack={onBack}
        onOk={() => onDone(quickServices)}
        disabledOk={(!customer || !servicesUI.length || !staff || !timeStart)}
      />
    </ConfirmStyled>
  );
};

export default Confirm;

const ConfirmStyled = styled.div`
  .title {
    color: var(--text-text-3, #1D2129);
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 166.667% */
    text-transform: capitalize;
    margin-bottom: 1.5rem;
  }
  .section-label {
    color: var(--text-text-3, #1D2129);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 1.5rem;
  }
  .border-top {
    border-color: #DADADA;
    border-style: solid;
    border-width:0;
    border-right-width: 1px;
    padding-right: 8px;
  }
  .border-bottom {
    padding-left: 8px;
  }
  label.section-label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .customer-info {
    border-radius: 5px;
    background: rgb(195 228 255 / 50%);
    padding: 1rem 1.5rem;
    .name {
      color: var(--text-text-3, #1D2129);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px; /* 166.667% */
      text-transform: capitalize;
    }

    .phone {
      color: var(--text-text-3, #1D2129);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .add-more-service {
    color: #0561AE;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    margin-top: 1rem;
  }

  @media only screen and (max-width: 768px) {
    .border-top {
      border-right-width: 0px;
      border-bottom-width: 1px;
      padding-bottom: 16px;
      margin-bottom: 16px;
      padding-right: 0;
    }
    .section-label {
      margin-bottom: 0.5rem;
      font-size: 16px;
    }
  }
`;

const BoxTimeInfo = styled(Row)`
  background: rgb(195 228 255 / 50%);
  padding: 10px 16px;
  border-radius: 5px;
  p {
    color: var(--text-text-3, #1D2129);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .divider {
    width: 1px;
    background: #1D2129;
    align-self: stretch;
    margin: 0 16px;
  }
  @media only screen and (max-width: 768px) {
    p {
      font-size: 14px;
    }
  }
`;
const Services = styled.div`
  max-height: 30vh;
  overflow-y: auto;
`;
const RowStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 1rem 0;
  border-bottom: 1px solid #DDD;
  label {
    color: var(--text-text-3, #1D2129);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .avatar-box {
  }

  .staff-name {
    color: var(--text-text-3, #1D2129);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .duration {
    color: var(--text-text-3, #1D2129);
    text-align: right;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  @media only screen and (max-width: 768px) {
   
  }
`;

const IconClock = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
    <path d="M10.9688 18.75C9.23817 18.75 7.54644 18.2368 6.10751 17.2754C4.66858 16.3139 3.54707 14.9473 2.88481 13.3485C2.22254 11.7496 2.04926 9.9903 2.38688 8.29296C2.7245 6.59563 3.55786 5.03653 4.78157 3.81282C6.00528 2.58911 7.56438 1.75575 9.26171 1.41813C10.959 1.08051 12.7184 1.25379 14.3172 1.91606C15.9161 2.57832 17.2827 3.69983 18.2441 5.13876C19.2056 6.57769 19.7188 8.26942 19.7188 10C19.7188 12.3206 18.7969 14.5462 17.1559 16.1872C15.515 17.8281 13.2894 18.75 10.9688 18.75ZM10.9688 2.5C9.48539 2.5 8.03535 2.93987 6.80198 3.76398C5.56861 4.58809 4.60731 5.75943 4.03966 7.12988C3.472 8.50032 3.32348 10.0083 3.61286 11.4632C3.90225 12.918 4.61656 14.2544 5.66545 15.3033C6.71435 16.3522 8.05072 17.0665 9.50558 17.3559C10.9604 17.6453 12.4684 17.4968 13.8389 16.9291C15.2093 16.3614 16.3807 15.4002 17.2048 14.1668C18.0289 12.9334 18.4688 11.4834 18.4688 10C18.4688 8.01088 17.6786 6.10323 16.2721 4.6967C14.8655 3.29018 12.9579 2.5 10.9688 2.5Z" fill="#1D2129" />
    <path d="M13.8375 13.75L10.3438 10.2563V4.375H11.5938V9.7375L14.7188 12.8688L13.8375 13.75Z" fill="#1D2129" />
  </svg>
);
const IconCalendar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.75 3.75C3.05964 3.75 2.5 4.30964 2.5 5V16.25C2.5 16.9404 3.05964 17.5 3.75 17.5H16.25C16.9404 17.5 17.5 16.9404 17.5 16.25V5C17.5 4.30964 16.9404 3.75 16.25 3.75H3.75ZM1.25 5C1.25 3.61929 2.36929 2.5 3.75 2.5H16.25C17.6307 2.5 18.75 3.61929 18.75 5V16.25C18.75 17.6307 17.6307 18.75 16.25 18.75H3.75C2.36929 18.75 1.25 17.6307 1.25 16.25V5Z" fill="#1D2129" />
    <path d="M11.5625 10C12.0803 10 12.5 9.58027 12.5 9.0625C12.5 8.54473 12.0803 8.125 11.5625 8.125C11.0447 8.125 10.625 8.54473 10.625 9.0625C10.625 9.58027 11.0447 10 11.5625 10Z" fill="#1D2129" />
    <path d="M14.6875 10C15.2053 10 15.625 9.58027 15.625 9.0625C15.625 8.54473 15.2053 8.125 14.6875 8.125C14.1697 8.125 13.75 8.54473 13.75 9.0625C13.75 9.58027 14.1697 10 14.6875 10Z" fill="#1D2129" />
    <path d="M11.5625 13.125C12.0803 13.125 12.5 12.7053 12.5 12.1875C12.5 11.6697 12.0803 11.25 11.5625 11.25C11.0447 11.25 10.625 11.6697 10.625 12.1875C10.625 12.7053 11.0447 13.125 11.5625 13.125Z" fill="#1D2129" />
    <path d="M14.6875 13.125C15.2053 13.125 15.625 12.7053 15.625 12.1875C15.625 11.6697 15.2053 11.25 14.6875 11.25C14.1697 11.25 13.75 11.6697 13.75 12.1875C13.75 12.7053 14.1697 13.125 14.6875 13.125Z" fill="#1D2129" />
    <path d="M5.3125 13.125C5.83027 13.125 6.25 12.7053 6.25 12.1875C6.25 11.6697 5.83027 11.25 5.3125 11.25C4.79473 11.25 4.375 11.6697 4.375 12.1875C4.375 12.7053 4.79473 13.125 5.3125 13.125Z" fill="#1D2129" />
    <path d="M8.4375 13.125C8.95527 13.125 9.375 12.7053 9.375 12.1875C9.375 11.6697 8.95527 11.25 8.4375 11.25C7.91973 11.25 7.5 11.6697 7.5 12.1875C7.5 12.7053 7.91973 13.125 8.4375 13.125Z" fill="#1D2129" />
    <path d="M5.3125 16.25C5.83027 16.25 6.25 15.8303 6.25 15.3125C6.25 14.7947 5.83027 14.375 5.3125 14.375C4.79473 14.375 4.375 14.7947 4.375 15.3125C4.375 15.8303 4.79473 16.25 5.3125 16.25Z" fill="#1D2129" />
    <path d="M8.4375 16.25C8.95527 16.25 9.375 15.8303 9.375 15.3125C9.375 14.7947 8.95527 14.375 8.4375 14.375C7.91973 14.375 7.5 14.7947 7.5 15.3125C7.5 15.8303 7.91973 16.25 8.4375 16.25Z" fill="#1D2129" />
    <path d="M11.5625 16.25C12.0803 16.25 12.5 15.8303 12.5 15.3125C12.5 14.7947 12.0803 14.375 11.5625 14.375C11.0447 14.375 10.625 14.7947 10.625 15.3125C10.625 15.8303 11.0447 16.25 11.5625 16.25Z" fill="#1D2129" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5 1.25C5.34518 1.25 5.625 1.52982 5.625 1.875V3.125C5.625 3.47018 5.34518 3.75 5 3.75C4.65482 3.75 4.375 3.47018 4.375 3.125V1.875C4.375 1.52982 4.65482 1.25 5 1.25Z" fill="#1D2129" />
    <path fillRule="evenodd" clipRule="evenodd" d="M15 1.25C15.3452 1.25 15.625 1.52982 15.625 1.875V3.125C15.625 3.47018 15.3452 3.75 15 3.75C14.6548 3.75 14.375 3.47018 14.375 3.125V1.875C14.375 1.52982 14.6548 1.25 15 1.25Z" fill="#1D2129" />
    <path fillRule="evenodd" clipRule="evenodd" d="M1.875 5.625H18.125V6.875H1.875V5.625Z" fill="#1D2129" />
  </svg>
);
