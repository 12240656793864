import fetch from 'services/request';
import { IApiSubmitReviewParam, IApiTransactionBillParam } from './types/api';

const baseURL = 'api/v1';

export const getDemoData = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ data: { data: [] } });
    }, 1000);
  });
  // return fetch({
  //   method: 'get',
  //   url: `${baseURL}/getDemoData`,
  //   params: params as any
  // });
};

const getDetailBill = (ticketId: string) => {
  return fetch({
    method: 'get',
    url: `${baseURL}/bill/detail/${ticketId}`,
  });
};

const addTipCreditCard = (billId: string, tip: number) => {
  return fetch({
    method: 'post',
    url: 'api/v1/payment/add-tip-credit-card',
    body: { billId, tip },
  });
};

const payBill = (billId: string) => {
  return fetch({
    method: 'post',
    url: 'api/v1/mobile-app/owner/payment/pay-bill',
    body: { billId },
  });
};
const submitReview = (params: IApiSubmitReviewParam) => {
  return fetch({
    method: 'post',
    url: 'api/v1/mobile-app/owner/review/submit-review-bill',
    body: params,
  });
};

const getTransactionBillDetail = (param: IApiTransactionBillParam) => {
  return fetch({
    method: 'get',
    url: 'api/v1/bill/transaction-detail/' + param.billId,
    shopId: param.shop_id,
  });
};

const paymentApis = {
  getDemoData,
  getDetailBill,
  addTipCreditCard,
  payBill,
  submitReview,
  getTransactionBillDetail,
};

export default paymentApis;
