import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { ISummaryReportResData } from './types/report';

interface IState {
  summaryReport: ISummaryReportResData | null;
  summaryLoading: boolean;
}

const initialState: IState = {
  summaryReport: null,
  summaryLoading: true,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {
    reportInit: () => undefined,
  },
  extraReducers(builder) {
    builder
      .addCase(actions.getSalonSummary.fetch, (state) => {
        state.summaryReport = null;
        state.summaryLoading = true;
      })
      .addCase(actions.getSalonSummary.success, (state, { payload }) => {
        state.summaryReport = payload;
        state.summaryLoading = false;
      })
      .addCase(actions.getSalonSummary.fail, (state) => {
        state.summaryLoading = false;
      })
      ;
  },
});

export const reportUIActions = Slice.actions;
const reportServiceReducer = Slice.reducer;
export default reportServiceReducer;
