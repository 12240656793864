import { Row } from 'antd';
import Button from 'components/Button';
import Text from 'components/Text';
import checkInActions from 'features/checkIn/services/actions';
import checkInSelectors from 'features/checkIn/services/selectors';
import { remove } from 'lodash';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ICategoryItemData, IServiceItemData } from 'services/shop/types/categories';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ShopInfo from 'widgets/ShopInfo';
import Categories from './Categories';
import Services from './Services';

const ChooseServicesCheckIn = () => {
  const [activeCategory, setActiveCategory] = useState<ICategoryItemData | null>(null);
  const dispatch = useAppDispatch();

  const serviceIds = checkInSelectors.getSelectedServices();
  const setServiceIds = (data: string[]) => {
    dispatch(checkInActions.setSelectedServices(data));
  };

  const { shop_id = '', station_number = '', } = useParams();
  const navigate = useNavigate();

  const handleChooseService = (o: IServiceItemData) => {
    const temp = [...serviceIds];
    const idx = temp.indexOf(o.id);
    if (idx !== -1) {
      remove(temp, s => s === o.id);
    } else {
      temp.push(o.id);
    }
    setServiceIds(temp);
  };

  const handleBack = () => navigate(-1);
  const handleContinue = () => {
    if (serviceIds.length === 0) return;
    navigate(`/store/${shop_id}/check-in/${station_number}/choose-staff`);
  };

  return (
    <Container>
      <ShopInfo />
      <ServicesStyled>
        <Row className='container'>
          <Text className="label" pb={1}>Categories</Text>
        </Row>
        <Categories activeCategory={activeCategory} selectedServices={serviceIds} setActiveCategory={setActiveCategory} />
        <Row className='container'>
          <Text mt={0.5} pb={1} className="label">Services</Text>
        </Row>
        <div className="container">
          <Services selectedServices={serviceIds} category={activeCategory} onChooseService={handleChooseService} />
        </div>
      </ServicesStyled>
      <Actions>
        <Button ntype='WHITE' onClick={handleBack}>Back</Button>
        <Button ntype='BLACK' onClick={handleContinue} disabled={serviceIds.length === 0}>Continue</Button>
      </Actions>
    </Container>
  );
};

export default ChooseServicesCheckIn;
const Container = styled.div``;
const ServicesStyled = styled.div`
  min-height: 80vh;
  padding-top: 1rem;
  padding-bottom: 1rem;
  .container {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .arrow-btn {
    width: 2.5rem;
    height: 2.5rem;
  }
  .services {
    padding-bottom: 1rem;
  }

  .label {
    color: var(--Text-Text-3, #1D2129);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 137.5%; /* 22px */
    text-transform: uppercase;
}
`;
const Actions = styled(Row)`
  position: sticky;
  bottom:0;
  right:0;
  left:0;
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 12px;
  border-top: 1px solid #E5E5E5;
  background: #FFF;
  button {
    flex: 1;
  }
`;
