import { createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IApiGetDemoDataParam, IApiTransactionBillParam } from './types/api';

const getDemoData = createAsyncAction<IApiGetDemoDataParam>(
  PREFIX_ACTIONS + 'getDemoData'
);
const getDetailBill = createAsyncAction<string>(
  PREFIX_ACTIONS + 'getDetailBill'
);
const getTransactionBillDetails = createAsyncAction<IApiTransactionBillParam>(
  PREFIX_ACTIONS + 'getTransactionBillDetails'
);

const paymentActions = {
  getDemoData,
  getDetailBill,
  getTransactionBillDetails,
};

export default paymentActions;
