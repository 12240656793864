export const RejectIcon = () => {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28.5" cy="28" r="28" fill="#A22323" fillOpacity="0.12" />
      <circle cx="27.835" cy="28.335" r="13.335" fill="#D61A1A" />
      <path
        d="M23.5 23.293L33.5 33.293"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M33.5 23L23.5 33"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
