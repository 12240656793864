import React from 'react';
import styled from 'styled-components';
type ILineSubtractProps = any;
const LineSubtract: React.FC<ILineSubtractProps> = () => {
  return (
    <LineSubtractStyled>
      <LeftCircle>
        <CircleStyled />
      </LeftCircle>
      <RightCircle>
        <CircleStyled />
      </RightCircle>
      <LineDash />
    </LineSubtractStyled>
  );
};

export default LineSubtract;
type LineSubtractStyledProps = {};
const LineSubtractStyled = styled.div<LineSubtractStyledProps>`
  width: 100%;
  position: relative;
`;
const LineDash = styled.div`
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right, white 50%, #DADADA 50%);
  background-size: 20px 4px;
`;
const CircleStyled = styled.div`
background: #f7f7f7;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  back
`;
const RightCircle = styled.div`
  position: absolute;
  top: -0.625rem;
  right: -0.625rem;
`;
const LeftCircle = styled.div`
  position: absolute;
  top: -0.625rem;
  left: -0.625rem;
`;
