
export const NAME_REDUCER = 'shop';

export const PREFIX_ACTIONS = 'shop_feature_';

export const PATH_LOADING = {
  getShopInfo: `loading.${NAME_REDUCER}.getShopInfo`,
  getStaffList: `loading.${NAME_REDUCER}.getStaffList`,
  getCategories: `loading.${NAME_REDUCER}.getCategories`,
};
