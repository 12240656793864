import React from 'react';
import styled from 'styled-components';
import Icon from 'assets/Icons';
import { formatCurrency } from 'utils/formatCurrency';
import paymentSelectors from 'features/payment/services/selectors';

interface ITipItemBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  tip: number;
  value: number;
  clickItem: (value: number, onChoose: boolean) => void;
}

const TipItemBox: React.FC<ITipItemBoxProps> = ({
  value,
  tip,
  clickItem,
  ...props
}) => {
  const creditDetails = paymentSelectors.getDetailBill();
  const billTotal = creditDetails?.ticketInfo?.total || 0;
  const isSelected = Number(((billTotal * value) / 100).toFixed(2)) === tip;
  const tipCalculatingByBill = (billTotal * (value / 100)).toFixed(2);

  const handleClick = () => {
    clickItem(value, isSelected);
  };

  return (
    <TipItemBoxStyled {...props} onClick={handleClick}>
      {isSelected && (
        <TipItemIconBox>
          <Icon type="checkOutline" />
        </TipItemIconBox>
      )}
      <TipItemStyled selected={isSelected}>
        {`${value}%`}{' '}
        <TipContentStyled selected={isSelected}>
          {tipCalculatingByBill && `(${formatCurrency(tipCalculatingByBill)})`}
        </TipContentStyled>
      </TipItemStyled>
    </TipItemBoxStyled>
  );
};

export default TipItemBox;
const TipItemBoxStyled = styled.div`
  position: relative;
`;

const TipItemIconBox = styled.div`
  position: absolute;
  right: -0.75rem;
  top: -0.75rem;
`;
interface TipItemStyledProps {
  selected?: boolean;
}
const TipItemStyled = styled.div<TipItemStyledProps>`
  cursor: pointer;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 5px;
  border: 4px solid #c6e9ef;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 137.5%; /* 24.75px */

  color: ${(props) => (props.selected ? '#fff' : '#1D2129')};
  background: ${(props) => (props.selected ? '#488C98' : '#ebfcff')};
  border-color: ${(props) => (props.selected ? '#88b9c7' : '#c6e9ef')};

  &:hover {
    background: ${(props) => (props.selected ? '#39737c' : '#d0f0f7')};
    border-color: ${(props) => (props.selected ? '#769ba5' : '#a9d8e6')};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
`;
const TipContentStyled = styled.div<TipItemStyledProps>`
  color: ${(props) => (props.selected ? '#fff' : '#488c98')};
  /* Headline/H7_regular */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.5%; /* 22px */
`;
