import { Col, Form, Input, Row, Spin } from 'antd';
import PhoneInput from 'components/PhoneInput';
import Text from 'components/Text';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IResponseDataBody } from 'services/response';
import styled from 'styled-components';
import { APPOINTMENT_TABS } from './helpers';
import appointmentApis from './services/apis';
import { ITabItemProps } from './types';
import shopSelectors from 'services/shop/selectors';
import { IStaffItemData } from 'services/shop/types/staff';
import { ANY_BODY } from './services/constants';
export type ICustomerInfo = {
  id: string | null;
  phone: string;
  name: string;
};

type IFormCustomerValues = {
  phone: string;
  name: string;
};

interface Props extends ITabItemProps {
  customer: ICustomerInfo | null,
  setCustomer: (customer: ICustomerInfo) => void,
  setStaff: (staff: IStaffItemData) => void,
}
const CustomerTab = ({ tab, customer, setCustomer, setTab, setStaff }: Props) => {
  const staffs = shopSelectors.data.staffsBookingOnline();
  const _staffs = useMemo(() => staffs.filter(o => !o.anyBody), [staffs]);
  const { shop_id = '' } = useParams();
  const [form] = Form.useForm();

  const [cusInfo, setCusInfo] = useState<ICustomerInfo | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (tab !== APPOINTMENT_TABS.CUSTOMER) return;
    form.setFieldsValue(customer);
  }, [tab]);

  const handleFinish = (values: IFormCustomerValues) => {
    const cusData: ICustomerInfo = {
      id: cusInfo?.id || null,
      name: values.name,
      phone: values.phone,
    };

    setCustomer(cusData);
    if (!_staffs.length) {
      setStaff(ANY_BODY);
      return setTab(APPOINTMENT_TABS.TIME);
    }
    setTab(APPOINTMENT_TABS.STAFF);
  };

  const _debounce = useCallback(debounce(async (text: string) => {
    try {
      const res: IResponseDataBody<ICustomerInfo | null> = await appointmentApis.getCustomerInfo(text, shop_id);
      const resData = res?.data?.data;
      if (resData) {
        setCusInfo(resData);
        form.setFieldValue('name', resData.name);
        form.validateFields();
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  }, 200), []);

  const handleChangePhoneInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const rawPhone = e.target.value?.replace(/[^0-9]/g, '');

    if (rawPhone?.length < 10) return;
    setLoading(true);
    _debounce(rawPhone);
  };



  return (
    <CustomerTabStyled>
      <Spin spinning={loading}>
        <Form form={form} layout='vertical' onFinish={handleFinish} validateTrigger={['onSubmit']}  >
          <Row>
            <Col span={24}>
              <Form.Item label="Enter your phone number" name={'phone'} rules={[
                {
                  validateTrigger: ['onSubmit'],
                  validator(rule, value) {
                    if (!value) return Promise.reject(new Error('Please enter your phone number'));
                    const rawPhone = value?.replace(/[^0-9]/g, '');

                    if (rawPhone?.length < 10) return Promise.reject(new Error('Invalid phone number'));

                    return Promise.resolve();
                  },
                }
              ]}>
                <PhoneInput isNormal onChange={handleChangePhoneInput} />
              </Form.Item>
            </Col>
            <Text m={0.5} />
            <Col span={24}>
              <Form.Item label='Enter your name' name={'name'} rules={[{ required: true, message: 'Please enter your name' }]}>
                <Input placeholder='Name' />
              </Form.Item>
            </Col>
          </Row>
          <div className='actions'>
            <button className='btn btn-primary' type='submit'>NEXT</button>
          </div>
        </Form>
      </Spin>
    </CustomerTabStyled>
  );
};

export default CustomerTab;

const CustomerTabStyled = styled.div`
  padding: 0 27%;
  .actions {
    margin-top: 1rem;
    padding: 0 1rem;
    margin-bottom: 1rem;
  }

  form {
    .ant-form-item-label {
      display: flex;
      justify-content: center;
      label {
        color: var(--Text-1, #121212);
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .ant-form-item-required {
        &::before {
          content: none !important;
        }
      }
    }

    .ant-form-item-control-input-content {
      flex:1;
      display: flex;
      input {
        width: unset;
        flex: 1;
        height: 56px;
        flex-shrink: 0;  

        border-radius: 5px;
        border: 1px solid var(--Line, #DCDCDC);
        background: var(--system-background-light-primary, #FFF);

        color: var(--Text-1, #121212);
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &::placeholder {
          color: var(--text-text-2, #BEBFC3);
          text-align: center;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        &:hover ,&:focus {
          border: 1px solid #0561AE;
        }

      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 0;
    .actions {
      margin-top: 0;
      padding: 0 8px;
      margin-bottom: 0;
    }
  }

`;