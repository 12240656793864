import styled from 'styled-components';

export const ContainerBoxStyled = styled.div`
  background: #f7f7f7;
  height: 100vh;
  max-width: 430px;
  overflow: auto;
  @media (min-width: 431px) {
    border-left: 1px solid #f1f1f1;
    border-right: 1px solid #f1f1f1;
    margin: auto;
  }
`;

export const HeaderContentBoxStyled = styled.div`
  display: flex;
  padding: 18px 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #fff;
`;

export const HeaderStyled = styled.div`
  color: #1d2129;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 137.5%; /* 24.75px */
`;
