
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { ISagaFunc } from 'services/actionConfigs';
import { IResponseDataBody } from 'services/response';
import actions from './actions';
import apis from './apis';
import { IBillDetailData, IPayloadGetDetail } from './types/bill';
import { ICouponPrintData } from 'features/coupon/types';


const getBillDetail: ISagaFunc<IPayloadGetDetail> = function* ({ payload }) {
  try {
    const res: IResponseDataBody<IBillDetailData> = yield call(apis.getBillDetail, payload.billId, payload.shopId);
    if (res.data.data) {
      yield put(actions.getBillDetail.success(res.data.data));
    }
  } catch (error) { }
  finally {
    yield put(actions.setReceiptLoading(false));
  }
};

const getBillCoupons: ISagaFunc<IPayloadGetDetail> = function* ({ payload }) {
  try {
    const res: IResponseDataBody<ICouponPrintData> = yield call(apis.getCouponPrint, payload.billId, payload.shopId);
    if (res.data.data) {
      yield put(actions.getBillCoupons.success(res.data.data));
    }
  } catch (error) { }
};

const fetchReceiptData: ISagaFunc<IPayloadGetDetail> = function* ({ payload }) {
  yield delay(100);
  yield put(actions.getBillDetail.fetch(payload));
  yield put(actions.getBillCoupons.fetch(payload));
};

export default function* billServiceSagas() {
  yield takeLatest(actions.fetchReceiptData, fetchReceiptData);
  yield takeLatest(actions.getBillDetail.fetch, getBillDetail);
  yield takeLatest(actions.getBillCoupons.fetch, getBillCoupons);
}
