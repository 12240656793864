import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { ISagaFunc } from 'services/actionConfigs';
import apis from './apis';
import actions from './actions';
import { IResponseDataBody } from 'services/response';
import { IInfoPrinterWaitingResData } from './types/ticket';

import { setLoadingPage } from 'services/UI/sagas';

const getInfoCheckInTicket: ISagaFunc<{ type: string, id: string }> = function* ({ payload }) {
  yield delay(100);
  yield setLoadingPage(true);
  try {
    const res: IResponseDataBody<IInfoPrinterWaitingResData> = yield call(apis.getInfoPrintWaiting, payload.type, payload.id);
    if (res.data.data) {
      yield put(actions.getInfoCheckInTicket.success(res.data.data));
    }
  } catch (error) { }
  finally {
    yield setLoadingPage(false);
  }
};

export default function* checkInServiceSagas() {
  yield takeLatest(actions.getInfoCheckInTicket.fetch, getInfoCheckInTicket);
}
