import axios, { AxiosRequestConfig } from 'axios';
import { get, isEmpty, set } from 'lodash';
import queryString from 'query-string';
import storage from 'utils/sessionStorage';
import toast from 'utils/toast';

const IS_DEV_ENV = false;

const DEV = 'https://api.bonafideadvisors.net/'; // DEVELOPMENT
const PRODUCT = 'https://api.fozito.com/';
const DOMAIN = IS_DEV_ENV ? DEV : PRODUCT;

const axiosInstance = axios.create({
    baseURL: DOMAIN,
    headers: {
        Accept: 'application/json',
    },
});

type mixType = string | number | null | any;

type IFetchArg = {
    method?: 'get' | 'post' | 'delete' | 'put';
    url: string;
    body?: Record<string, mixType | unknown> | FormData;
    params?: Record<string, mixType | Array<mixType>>;
    configs?: AxiosRequestConfig<Record<string, unknown | mixType>> | undefined;
    shopId?: string;
    autoToast?: boolean;
    station_number?: string;
};
const fetch = async ({
    method = 'get',
    url = '',
    body = {},
    params = {},
    configs = {},
    shopId,
    autoToast = false,
    station_number
}: IFetchArg) => {

    let urlWithParams = url;
    if (!isEmpty(params)) {
        const paramsPasser = queryString.stringify(
            { ...params || {} },
            { arrayFormat: 'bracket' }
        );
        urlWithParams = urlWithParams + '?' + paramsPasser;
    }

    const shop_id = storage.shop_id.get();
    if (shop_id) {
        set(configs, ['headers', 'x-api-key'], shop_id);
    }

    if (shopId) {
        set(configs, ['headers', 'x-api-key'], shopId);
    }

    // const token = storage.token.get();
    // if (token) {
    //     set(configs, 'headers.Authorization', 'Bearer ' + token);
    // }
    if (station_number) {
        set(configs, ['headers', 'station-number'], station_number);
    }

    const getAxios = async () => {
        switch (method) {
            case 'post':
                return await axiosInstance.post(urlWithParams, body, configs);
            case 'put':
                return await axiosInstance.put(urlWithParams, body, configs);
            case 'delete':
                return await axiosInstance.delete(urlWithParams, configs);
            default:
                return await axiosInstance.get(urlWithParams, configs);
        }
    };

    try {
        const res = await getAxios();
        if (autoToast) {
            if (method !== 'get') {
                if (!res?.data?.data && res?.data?.message) {
                    toast.error(decodeURI(res.data.message ?? ''));
                }
            }
        }
        return res;
    } catch (error) {
        if (autoToast) {
            if (method !== 'get') {
                const msg = get(error, 'response.data.message');
                toast.error(decodeURI(msg ?? ''));
            }
        }
        const status = get(error, 'response.status');
        if (status === 401 || status === 403) {
            // handle refresh token here
        }
        return Promise.reject(error);
    }
};

export default fetch;
