import { Form, Input, InputRef, Row } from 'antd';
import { LoadingFull } from 'components/Loading';
import Modal from 'components/Modal';
import NotFoundPage from 'features/NotFound';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { FORMAT_TIME } from 'utils/format';
import { momentTimezone } from 'utils/time';
import toast from 'utils/toast';
import ShopInfo from 'widgets/ShopInfo';
import aptActions from '../services/actions';
import appointmentApis from '../services/apis';
import { APPOINTMENT_STATUS } from '../services/constants';
import aptSelectors from '../services/selectors';
import iconCalendar from './iconCalendar.png';
import iconClock from './iconClock.png';

const BookingDetail = () => {
  const dispatch = useAppDispatch();
  const { shop_id = '', booking_id = '' } = useParams();
  const inputReasonRef = useRef<InputRef>(null);
  const [form] = Form.useForm();

  const data = aptSelectors.detail();
  const isLoading = aptSelectors.detailLoading();
  const [pageLoading, setPageLoading] = useState(false);
  const [visibleModalCancel, setVisibleModalCancel] = useState(false);

  const confirmed = data?.status === APPOINTMENT_STATUS.CONFIRM;
  const canceled = data?.status === APPOINTMENT_STATUS.CANCEL;

  useEffect(() => {
    dispatch(aptActions.getGetDetailAppointment.fetch({ aptId: booking_id, shopId: shop_id }));
  }, [booking_id]);

  const time = useMemo(() => {
    if (!data) return null;
    const { startTime, endTime } = data;

    return `${momentTimezone(startTime).format(FORMAT_TIME)} - ${momentTimezone(endTime).format(FORMAT_TIME)}`;
  }, [data]);

  const date = useMemo(() => momentTimezone(data?.startTime).format('ddd, MMM DD, YYYY'), [data]);

  const handleConfirm = async () => {
    if (!booking_id) return;
    setPageLoading(true);
    try {
      const res: IResponseDataBody<boolean> = await appointmentApis.confirmAppointment(booking_id);
      if (res.data.data) {
        toast.success('Appointment confirmed');
        dispatch(aptActions.getGetDetailAppointment.fetch({ aptId: booking_id, shopId: shop_id }));
      }
    } catch (error) { }
    finally {
      setPageLoading(false);
    }
  };

  const handleOpenCancel = () => {
    setVisibleModalCancel(true);
    form.resetFields();
    setTimeout(() => {
      inputReasonRef.current?.focus();
    }, 100);
  };

  const handleCancelApt = async (values: any) => {
    if (!booking_id) return;
    setVisibleModalCancel(false);
    form.resetFields();
    setPageLoading(true);
    try {
      const res: IResponseDataBody<boolean> = await appointmentApis.cancelAppointment(booking_id, values.reason);
      if (res.data.data) {
        toast.success('Appointment canceled');
        dispatch(aptActions.getGetDetailAppointment.fetch({ aptId: booking_id, shopId: shop_id }));
      }
    } catch (error) { }
    finally {
      setPageLoading(false);
    }
  };

  const getTitleApt = () => {
    if (canceled) {
      return 'Appointment canceled';
    }
    if (confirmed) {
      return 'Appointment confirmed';
    }
    return 'Confirm Appointment';
  };

  if (isLoading || pageLoading) return <LoadingFull />;

  if (!data) return <NotFoundPage />;

  return (
    <PageStyled>
      <ShopInfo />
      <ContainerBox>
        <p className='header-box'>{getTitleApt()}</p>
        <div className="divider"></div>
        <p className="label-box">INFORMATION</p>
        <BoxStyled>
          <div className="left-div" />
          <div className="box-content">
            <Row align={'middle'} justify={'center'}>
              <img src={iconClock} alt="" className='icon' />
              <p className="label">{time}</p>
            </Row>
            <Row align={'middle'} justify={'center'}>
              <img src={iconCalendar} alt="" className='icon' />
              <p className="label">{date}</p>
            </Row>
          </div>
        </BoxStyled>

        <p className="label-box">Services</p>
        <div className="divider" />
        <BoxServices>
          {data.services.map((o, i) => (
            <div key={o.serviceId}>
              <div className="service">
                <div className="service-name">{o.serviceName}</div>
                <div className="service-duration">{o.duration}m</div>
              </div>
              {i !== data.services.length - 1 && <div className="divider" />}
            </div>
          ))}
        </BoxServices>
        {!canceled && <Actions>
          <button disabled={confirmed} onClick={handleConfirm}><p>{confirmed ? 'CONFIRMED' : 'CONFIRM'}</p></button>
          <button className='outline' onClick={handleOpenCancel}><p>CANCEL</p></button>
        </Actions>}
      </ContainerBox>
      <Modal
        modalTitle='Reason'
        showClose okTitle={'OK'}
        hiddenCloseButton
        centered={false}
        visible={visibleModalCancel}
        onClose={() => setVisibleModalCancel(false)}
        onSubmit={() => form.submit()}
      >
        <WrapModal form={form} onFinish={handleCancelApt}>
          <Form.Item name={'reason'} rules={[{ required: true, message: 'Please enter your reason' }]}>
            <Input.TextArea
              ref={inputReasonRef}
              rows={4}
              placeholder='Enter your reason'
            />
          </Form.Item>
        </WrapModal>
      </Modal>
    </PageStyled>
  );
};

export default BookingDetail;

const PageStyled = styled.div`
    background: #F7F7F7;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    .banner {
        margin-bottom: 2rem;
    }
    @media only screen and (max-width: 768px) {
      .banner {
        margin-bottom: 8px;
      }
    }
    .divider {
      background: #DDD;
      height: 1px;
      width: 100%;
      margin-bottom: 1rem;
    }
`;

const ContainerBox = styled.div`
  border-radius: 40px 40px 0px 0px;
  background: #FFF;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.16);
  padding: 0 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  .header-box {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 166.667% */
    text-transform: capitalize;
    margin: 1rem 0;
  }

  

  .label-box {
    color: #686868;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 8px;
  }

  .box-content {
    flex: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`;
const BoxStyled = styled.div`
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  overflow: hidden;
  margin-bottom: 1rem;
  .left-div {
    background: #6FABB6;
    width: 5px;
    height: 95px;
  }
  .icon {
    height: 32px;
    width: 32px;
    margin-right: 0.5rem;
  }
  .label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 166.667% */
    text-transform: capitalize;
  }
`;

const BoxServices = styled.div`
  flex:1;
  .service {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .service-name {
      color: #1D2129;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .service-duration {
      color: #1D2129;
      text-align: right;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: sticky;
  bottom: 1rem;
  right: 1rem;
  left: 1rem;

  button {
    display: flex;
    width: 100%;
    height: 48px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #FF8890;
    p {
      text-align: center;
      /* Content/Content 2 - 16 semibold */
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #FFFFFF;
    }
  }
  button.outline {
    background: #FFE5E7;
    p {
      color: #D2464F;
    }
  }
  button:disabled {
    opacity: 0.5;
  }
`;

const WrapModal = styled(Form)`
.ant-input {
  padding: 0.5rem;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  &:hover, &:focus, &:active {
    border-color: #FF8890;
  }
}
`;