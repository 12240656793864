import fetch from 'services/request';

export const getShopInfo = (shopId: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/shop/getInfoDetail',
    shopId,
  });
};

export const getStaffs = (shopId: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/staff/get-all-by-shop',
    params: {
      size: 1000,
    },
    shopId
  });
};
export const getStaffsBookingOnline = (shopId: string) => {
  return fetch({
    method: 'get',
    url: '/api/v1/staff/available-booking-online',
    shopId
  });
};


export const getCategories = (shopId: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/category/get-all-booking',
    shopId
  });
};

const getQuickServices = (shopId: string) => {
  return fetch({
    method: 'get',
    url: '/api/v1/service/quick-booking',
    shopId
  });
};

const getShopAllSetting = (shopId: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/tablet/check-in/setting',
    shopId,
  });
};

const getDetailCoupon = (shopId: string, couponId: string) => {
  return fetch({
    method: 'get',
    url: `api/v1/coupon/detail/${couponId}`,
    shopId
  });
};

const getReviewDetail = (shopId: string, reviewId: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/review/detail/' + reviewId,
    shopId,
  });
};

const updateChannelReview = (reviewId: string, channel: string) => {
  return fetch({
    method: 'post',
    url: 'api/v1/review/update-channel-review',
    body: { reviewId, channel },
    autoToast: true,
  });
};

const getSocialMedia = (shopId: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/review/social-media',
    shopId,
  });
};

const shopApis = {
  getShopInfo,
  getStaffs,
  getStaffsBookingOnline,
  getCategories,
  getDetailCoupon,
  getReviewDetail,
  getSocialMedia,
  updateChannelReview,
  getQuickServices,
  getShopAllSetting,
};

export default shopApis;
