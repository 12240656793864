import fetch from 'services/request';

const getBillDetail = (billId: string, shopId?: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/bill/transaction-detail/' + billId,
    shopId,
  });
};

const getCouponPrint = (billId: string, shopId: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/coupon/list-receipt-sms-email/' + billId,
    shopId,
  });
};

const billApis = {
  getBillDetail,
  getCouponPrint,
};

export default billApis;
