import { LoadingFull } from 'components/Loading';
import NotFoundPage from 'features/NotFound';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency, formatNumber, minusValue } from 'utils/formatCurrency';
import logo from './logo.svg';
import reportActions from './services/actions';
import reportSelectors from './services/selectors';
const Logo = () => <img src={logo} alt='logo' style={{ width: '100px', height: 'auto' }} />;
// Styled-components
const ReportContainer = styled.div`
  width: 320px;
  margin: auto;
  margin-top: 16px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 15px;
  font-family: Arial, sans-serif;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  color: #000;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 12px;

  h1 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }

  p {
    color: #1D2129;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 1px;
  }
`;

const Section = styled.div`
  &:last-child {
    margin-bottom: 0;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 1.8;

  span  {
    color: #1D2129;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }


  &.highlight {
    display: flex;
    height: 32px;
    padding: 4px 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: #232F3E;
    span {
      color: #FFF;
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      text-transform: capitalize;
    }
  }
  &.small-highlight {
    display: flex;
    height: 28px;
    padding: 0px 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: #232F3E;
    span {
      color: #FFF;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      text-transform: capitalize;
    }
  }

  &.subtotal {
    font-weight: bold;
    padding-left: 16px;
  }

  &.title-subtotal span {
    color: #1D2129;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

const Footer = styled.div`
  text-align: center;
  font-size: 10px;
  margin-top: 10px;
  border-top: 1px solid #232F3E;
  padding-top: 10px;
  display: flex;
  flex-direction: column;

  color: #1D2129;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px; /* 150% */
`;

// Component
const Report = () => {
  const dispatch = useAppDispatch();
  const { shop_id = '', } = useParams();
  const [searchText] = useSearchParams();
  const loading = reportSelectors.summaryLoading();
  const data = reportSelectors.summaryReport();

  useEffect(() => {
    dispatch(reportActions.getSalonSummary.fetch({
      shopId: shop_id,
      endDate: searchText?.get('endDate') || '', startDate: searchText?.get('startDate') || '',
    }));
  }, []);

  const dates = useMemo(() => {
    return ({
      start: moment(searchText.get('startDate'), 'MM-DD-YYYY'),
      end: moment(searchText.get('endDate'), 'MM-DD-YYYY'),
    });
  }, [searchText]);

  if (loading) return <LoadingFull />;

  if (!data) return <NotFoundPage />;

  const {
    saleItem, totalServices, feeItem, supplyFee, paymentItem,
    totalTipFee, statistics,

  } = data;

  return (
    <ReportContainer>
      <Header>
        <Logo />
        {dates.start.format('MM/DD/YYYY') === dates.end.format('MM/DD/YYYY') ? <p>{dates.end.format('MM/DD/YYYY')}</p> : <p>{dates.start.format('MM/DD/YYYY')} - {dates.end.format('MM/DD/YYYY')}</p>}
      </Header>

      <Section className=''>
        <Row className="highlight">
          <span>Gross Sales</span><span>{formatCurrency(saleItem?.crossSales)}</span>
        </Row>
        <Row>
          <span>Services</span><span>{formatCurrency(saleItem?.totalSaleService)}</span>
        </Row>
        <Row>
          <span>Total Services</span><span>{formatNumber(totalServices)}</span>
        </Row>
        <Row>
          <span>Products</span><span>{formatCurrency(saleItem?.totalSaleProduct)}</span>
        </Row>
        <Row>
          <span>Sell Gift Cards</span><span>{formatCurrency(saleItem?.totalSaleGiftCard)}</span>
        </Row>
        <Row>
          <span>VIP Programs</span><span>{formatCurrency(saleItem?.totalSaleVIP)}</span>
        </Row>
        <Row>
          <span>Sale Taxes</span><span>{formatCurrency(feeItem?.saleTax)}</span>
        </Row>
        <Row>
          <span>Use Taxes</span><span>{formatCurrency(feeItem?.useTax)}</span>
        </Row>
        <Row style={{ borderBottom: '1px solid #232F3E', marginBottom: 12 }}>
          <span>Supply Fees</span><span>{formatCurrency(supplyFee)}</span>
        </Row>
        <Row style={{ borderBottom: '1px dotted #232F3E', paddingTop: 4 }}>
          <span>Credit Card</span>
          <span>{formatCurrency(paymentItem?.totalCreditCard)}</span>
        </Row>
        <Row className="subtotal">
          <span>Credit Card Processing</span><span>{formatCurrency(paymentItem?.saleCreditCard)}</span>
        </Row>
        <Row className="subtotal">
          <span>Card Fees</span><span>{formatCurrency(paymentItem?.feeCreditCard)}</span>
        </Row>
        <Row className="subtotal">
          <span>Total Tips</span><span>{formatCurrency(paymentItem?.tipCreditCard)}</span>
        </Row>
        <Row className="subtotal" style={{ borderBottom: '1px solid #232F3E' }}>
          <span>Tip Fee</span><span>{formatCurrency(totalTipFee)}</span>
        </Row>
        <Row>
          <span>Cash</span><span>{formatCurrency(paymentItem?.totalCash)}</span>
        </Row>
        <Row>
          <span>Gift Card</span><span>{formatCurrency(paymentItem?.totalGiftCard)}</span>
        </Row>
        <Row>
          <span>Checks</span><span>{formatCurrency(paymentItem?.totalCheck)}</span>
        </Row>
        <Row>
          <span>Loyalty Point</span><span>{formatCurrency(paymentItem?.totalRedeemPoint)}</span>
        </Row>
        <Row>
          <span>Other(s)</span><span>{formatCurrency(paymentItem?.totalOther)}</span>
        </Row>
      </Section>

      <Section style={{ marginTop: 12 }}>
        <Row className="highlight">
          <span>Deductions</span><span>{minusValue(data.deductions)}</span>
        </Row>
        <Row style={{ borderBottom: '1px dotted #232F3E' }}>
          <span>Company Discounts</span><span> {formatCurrency(data?.ownerDiscount)}</span>
        </Row>
        <Row className="subtotal" >
          <span>Manual</span><span>{formatCurrency(data?.discountManual)}</span>
        </Row>
        <Row className="subtotal" >
          <span>Coupons</span><span>{formatCurrency(data?.discountCoupon)}</span>
        </Row>
        <Row className="subtotal" style={{ borderBottom: '1px dotted #232F3E' }} >
          <span>Cash Incentives</span><span>{formatCurrency(data?.discountCashIncentive)}</span>
        </Row>
        <Row>
          <span>Staff Discounts</span><span>{formatCurrency(data?.staffDiscount)}</span>
        </Row>
        <Row>
          <span>Refunds</span><span>{formatCurrency(data?.totalRefund)}</span>
        </Row>
        <Row>
          <span>Voids</span><span>{formatCurrency(data?.totalVoid)}</span>
        </Row>
        <Row>
          <span>Redeemed Gift Cards</span><span>{formatCurrency(paymentItem?.totalGiftCard)}</span>
        </Row>
        <Row>
          <span>Redeemed Loyalty Points</span><span>{formatCurrency(paymentItem?.totalRedeemPoint)}</span>
        </Row>
      </Section>

      <Section style={{ marginBottom: 16 }}>
        <Row className="highlight">
          <span>Total Net Sale</span><span>{formatCurrency(data?.netSales)}</span>
        </Row>
      </Section>

      <Section>
        <Row className="small-highlight">
          <span>STATISTICS</span><span></span>
        </Row>
        <Row>
          <span>Number Of Active Tickets</span><span>{formatNumber(statistics?.numberTicket)}</span>
        </Row>
        <Row>
          <span>Number Of Voided Tickets</span><span>{formatNumber(statistics?.numberTicketVoid)}</span>
        </Row>
        <Row>
          <span>Average Ticket</span><span>{formatCurrency(data?.statistics?.averageAmount)}</span>
        </Row>
        <Row>
          <span>Return Customers</span><span>{formatNumber(statistics?.returnCustomer)}</span>
        </Row>
        <Row>
          <span>Walk - In Customers</span><span>{formatNumber(statistics?.totalWalkIn)}</span>
        </Row>
      </Section>

      <Footer>
        <div>© 2024 Fozito Ltd.</div>
        <div>All Rights Reserved</div>
      </Footer>
    </ReportContainer>
  );
};

export default Report;