
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { ISagaFunc } from 'services/actionConfigs';
import actions from './actions';
import apis from './apis';

import uiActions from 'services/UI/actions';
import { IResponseDataBody } from 'services/response';
import { PATH_LOADING } from './constants';
import { IGiftCardDetail, IGiftCardItem } from './types/giftCard';

const getGiftCardToBuy: ISagaFunc<string> = function* ({ payload }) {
  yield put(uiActions.setLoading({ path: PATH_LOADING.getGiftCardToBuy, result: true }));
  yield delay(500);
  try {
    const resData: IResponseDataBody<IGiftCardItem[]> = yield call(apis.giftCardToBuy, payload);
    if (resData?.data?.data) {
      yield put(actions.getGiftCardToBuy.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getGiftCardToBuy.fail({}));
  } finally {
    yield put(uiActions.setLoading({ path: PATH_LOADING.getGiftCardToBuy, result: false }));
  }
};

const getGiftCardDetail: ISagaFunc<{ id: string, shopId?: string | null }> = function* ({ payload }) {
  yield put(uiActions.setLoading({ path: PATH_LOADING.getGiftCardDetail, result: true }));
  yield delay(500);
  try {
    const resData: IResponseDataBody<IGiftCardDetail> = yield call(apis.getGiftCardDetail, payload.id, payload.shopId || '');
    if (resData?.data?.data) {
      yield put(actions.getGiftCardDetail.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getGiftCardDetail.fail({}));
  } finally {
    yield put(uiActions.setLoading({ path: PATH_LOADING.getGiftCardDetail, result: false }));
  }
};

export default function* giftCardServiceSagas() {
  yield takeLatest(actions.getGiftCardToBuy.fetch, getGiftCardToBuy);
  yield takeLatest(actions.getGiftCardDetail.fetch, getGiftCardDetail);
}
