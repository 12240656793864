import { ISagaFunc } from 'services/actionConfigs';
import { IApiGetDemoDataParam, IApiTransactionBillParam } from './types/api';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import apis from './apis';
import actions from './actions';
import { AxiosResponse } from 'axios';
import { IDEMOModelResDataItem } from './types/demoData';
import uiActions from 'services/UI/actions';
import { PATH_LOADING } from './constants';
import { PaymentDetailsView } from './types/paymentDetailsView';
import { TransactionBillDetailsType } from './types/transactionBillDetails';

const getDemoData: ISagaFunc<IApiGetDemoDataParam> = function* () {
  yield put(uiActions.setLoading({ path: PATH_LOADING.getData, result: true }));
  try {
    const resData: AxiosResponse<{ data: IDEMOModelResDataItem[] }> =
      yield call(apis.getDemoData);
    if (resData?.data?.data) {
      yield put(actions.getDemoData.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getDemoData.fail({}));
  } finally {
    yield put(
      uiActions.setLoading({ path: PATH_LOADING.getData, result: false })
    );
  }
};

const getDetailBill: ISagaFunc<string> = function* ({ payload }) {
  yield put(uiActions.setLoadingPage(true));
  try {
    yield delay(200);
    const resData: AxiosResponse<{ data: PaymentDetailsView }> = yield call(
      apis.getDetailBill,
      payload
    );
    if (resData?.data?.data) {
      yield put(actions.getDetailBill.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getDetailBill.fail({}));
  } finally {
    yield put(uiActions.setLoadingPage(false));
  }
};
const getTransactionBillDetails: ISagaFunc<IApiTransactionBillParam> = function* ({ payload }) {
  yield put(uiActions.setLoadingPage(true));
  try {
    yield delay(200);
    const resData: AxiosResponse<{ data: TransactionBillDetailsType }> = yield call(
      apis.getTransactionBillDetail,
      payload
    );
    if (resData?.data?.data) {
      yield put(actions.getTransactionBillDetails.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getTransactionBillDetails.fail({}));
  } finally {
    yield put(uiActions.setLoadingPage(false));
  }
};

export default function* paymentServiceSagas() {
  yield takeLatest(actions.getDemoData.fetch, getDemoData);
  yield takeLatest(actions.getDetailBill.fetch, getDetailBill);
  yield takeLatest(actions.getTransactionBillDetails.fetch, getTransactionBillDetails);
}
