import styled from 'styled-components';
type Props = {
  children: any,
};
const CouponContainer = ({ children }: Props) => {
  return (
    <CouponInfoBoxStyled className='CouponInfoBoxStyled'>
      <SellingBox className='SellingBox'>
        <div className="box" >
          <BoxCoupon>
            {children}
          </BoxCoupon>
        </div>
      </SellingBox>
    </CouponInfoBoxStyled>
  );
};

export default CouponContainer;

const CouponInfoBoxStyled = styled.div`
  border-radius: 5px;
  background: var(--fill-fill-5, #E5E6EB);
  max-width: 374px;
  .divider {
    background: #86909C;
    width: 2px;
  }
`;
const BoxCoupon = styled.div`
  width: 100%;
`;

const SellingBox = styled.div`
border-radius: 5px;
  padding: 1rem;
  background: #fff;
.box {
  border-radius: 5px;
  display:flex;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='80%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='7' stroke-dasharray='7%2c 15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    .content {
      display: flex;
      padding-left: 12px;
      
      .currency {
        color: black;
        font-family: Roboto;
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .money {
        color: black;
        font-family: Roboto;
        font-size: 100px;
        font-style: normal;
        font-weight: 700;
        line-height: 102px;
      }
      .end {
        display: flex;
        flex-direction: column;
        .end-top {
          display: flex;
          gap: 8px;
          .value {
            color: black;
            font-family: Roboto;
            font-size: 35px;
            font-style: normal;
            font-weight: 400;
          }
          .percent {
            color: black;
            font-family: Roboto;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .label {
            color: black;
            font-family: Roboto;
            font-size: 55px;
            font-style: normal;
            font-weight: 700;
            align-self: center;
            &.absolute {
              line-height: 0px;
              position: absolute;
              top: 24px;
              left: 0;
            }
          }
        }

        .end-bottom {
          color: black;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          transform: translateY(-7px);
          white-space: nowrap;
        }
      }

      &.size2 {
        padding-left: 0;
        .end-bottom {
          font-size: 16px;
        }
      }
      &.percent {
        padding-left: 0;
        justify-content: center;
      }
    }
  }
`;