import { createAction, createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IPayloadGetDetail } from './types/bill';

const fetchReceiptData = createAction<IPayloadGetDetail>(PREFIX_ACTIONS + 'fetchReceiptData');
const getBillDetail = createAsyncAction<IPayloadGetDetail>(PREFIX_ACTIONS + 'getBillDetail');
const getBillCoupons = createAsyncAction<IPayloadGetDetail>(PREFIX_ACTIONS + 'getBillCoupons');
const setReceiptLoading = createAction<boolean>(PREFIX_ACTIONS + 'setReceiptLoading');

const billActions = {
  setReceiptLoading,
  fetchReceiptData,
  getBillDetail,
  getBillCoupons,
};

export default billActions;

