import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import Button from 'components/Button';
import styled from 'styled-components';
import PaymentSuccessView from './components/PaymentSuccessView';
import RatingView from './components/RatingView';
import paymentApis from 'features/payment/services/apis';
import { get } from 'lodash';
import toast from 'utils/toast';
import { useParams } from 'react-router-dom';
import {
  HeaderContentBoxStyled,
  HeaderStyled,
} from 'features/payment/components/Styled';
import { useAppDispatch } from 'store/hooks';
import paymentActions from 'features/payment/services/actions';
type IConfirmSuccessCusProps = any;
const ConfirmSuccessCus: React.FC<IConfirmSuccessCusProps> = () => {
  const { shop_id = '', ticketId = '' } = useParams();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const onFinish = async (data: { stars: number; note: string }) => {
    try {
      setLoading(true);
      const param = {
        billId: ticketId || '',
        stars: data?.stars || 0,
        message: data?.note || '',
      };
      await paymentApis.submitReview(param);
    } catch (error) {
      const msg = get(error, 'response.data.message', '');
      toast.error(msg || 'An error occurred, please try again!');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const param = {
      billId: ticketId || '',
      shop_id: shop_id || '',
    };
    dispatch(paymentActions.getTransactionBillDetails.fetch(param));
  }, []);

  return (
    <Form form={form} onFinish={onFinish} initialValues={{ stars: 5 }}>
      <HeaderContentBoxStyled>
        <HeaderStyled>Confirmation</HeaderStyled>
      </HeaderContentBoxStyled>
      <PaymentSuccessView />
      <RatingView />
      <ButtonBox>
        <Button
          loading={loading}
          htmlType="submit"
          width="100%"
          ntype="PRIMARY_DEFAULT"
        >
          DONE
        </Button>
      </ButtonBox>
    </Form>
  );
};

export default ConfirmSuccessCus;

const ButtonBox = styled.div`
  padding: 1rem 1rem 2rem;
`;
