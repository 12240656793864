import { QRCode } from 'antd';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { GiftCardItem } from './styles';
// import giftCardImg from './img/giftCardImg.jpg';
import { LoadingFull } from 'components/Loading';
import NotFoundPage from 'features/NotFound';
import { useAppDispatch } from 'store/hooks';
import giftCardPaidActions from './services/actions';
import giftCardPaidSelectors from './services/selectors';
import { IPaymentGiftCardInfo } from './services/types';

const GiftCardDetail = ({ }) => {
  const dispatch = useAppDispatch();
  const { id = '', shop_id = '' } = useParams();
  const data = giftCardPaidSelectors.detail();
  const loading = giftCardPaidSelectors.detailLoading();

  const result = useMemo(() => {
    const _res: IPaymentGiftCardInfo = {
      amount: data?.amount || 0,
      date: data?.createdDate ? moment(data?.createdDate) : null,
      authCode: '',
      customerName: data?.customerName || '',
      giftCardCode: data?.giftCode || '',
      expireDate: data?.expireDate ? moment(data?.expireDate) : null,
      transNum: '',
    };
    return _res;
  }, [data]);

  useEffect(() => {
    dispatch(giftCardPaidActions.getInfo.fetch({ id, shopId: shop_id }));
  }, []);


  if (loading) return <LoadingFull />;

  if (!data) return <NotFoundPage />;

  return (
    <>
      <Page>
        <div className='box' style={{
          'borderRadius': '12px',
          'background': '#FFF',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 4px',
          padding: '24px',
        }}>
          <GiftCardItem className='btn gift-card-item gift-card-img-result'>
            <img src={data?.urlImage || ''} />
          </GiftCardItem>
          <div style={{ margin: '32px 0' }}>
            <div className='circle' />
            <div style={{
              width: '100%',
              height: 1,
              backgroundImage: 'linear-gradient(to right, #DADADA 50%, rgba(255,256,255,0) 0%)',
              backgroundPosition: 'bottom',
              backgroundSize: '16px 1px',
              backgroundRepeat: 'repeat-x',
            }} />
            <div className='circle' />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span
                style={{
                  color: '#566A7F',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >Date:</span>
              <span
                style={{
                  color: '#1D2129',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >{result?.date?.format('ddd, MM-DD-YYYY')}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span
                style={{
                  color: '#566A7F',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >Time:</span>
              <span
                style={{
                  color: '#1D2129',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >{result?.date?.format('HH:mm A')}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span
                style={{
                  color: '#566A7F',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >Customer Name:</span>
              <span
                style={{
                  color: '#1D2129',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >{result?.customerName}</span>
            </div>
            <div style={{
              width: '100%',
              height: 1,
              backgroundImage: 'linear-gradient(to right, #DADADA 50%, rgba(255,256,255,0) 0%)',
              backgroundPosition: 'bottom',
              backgroundSize: '16px 1px',
              backgroundRepeat: 'repeat-x',
              margin: '2px 0',
            }} />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span
                style={{
                  color: '#566A7F',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >Amount:</span>
              <span
                style={{
                  color: '#1D2129',
                  textAlign: 'center',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >{formatCurrency(parseFloat(String(result?.amount ?? '')))}</span>
            </div>
          </div>
          <div style={{ height: 1, width: '100%', background: '#DADADA', margin: '16px 0' }} />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>QR Code:</span>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
              <QRCode size={75} value={result?.giftCardCode?.toString() || ''} />
              <div>
                {result?.giftCardCode}
              </div>
            </div>
          </div>
        </div>
      </Page >
    </>
  );
};

export default GiftCardDetail;

const Page = styled.div`
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: center;
  .box {
    width: 100%;
    max-width: 20rem;
  }
  @media only screen and (max-width: 600px) {
    .box {
      width: 100%;
      max-width: unset;
    } 
  }
`;