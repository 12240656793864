import { Navigate, Outlet, useParams } from 'react-router-dom';
import checkInSelectors from '../services/selectors';

const CheckInShieldLayout = () => {
  const { shop_id = '', station_number = '', } = useParams();
  const cus = checkInSelectors.getCustomerData();

  if (!cus) return <Navigate to={`/store/${shop_id}/check-in/${station_number}`} replace />;

  return (
    <div><Outlet /></div>
  );
};

export default CheckInShieldLayout;
