import giftCardPaidServiceReducer from 'features/GiftCardDetail/services/reducers';
import aptServiceReducer from 'features/booking/services/reducers';
import paymentServiceReducer from 'features/payment/services/reducers';
import checkInServiceReducer from 'features/checkIn/services/reducers';
import giftCardServiceReducer from 'features/shopping/GiftCard/services/reducers';
import uiServiceReducer from 'services/UI/reducer';
import shopServiceReducer from 'services/shop/reducers';
import billServiceReducer from 'features/receipt/services/reducers';
import reportServiceReducer from 'features/report/services/reducers';

const rootReducer = {
  ui: uiServiceReducer,
  shop: shopServiceReducer,
  giftCard: giftCardServiceReducer,
  appointment: aptServiceReducer,
  giftCardPaid: giftCardPaidServiceReducer,
  payment: paymentServiceReducer,
  checkIn: checkInServiceReducer,
  bill: billServiceReducer,
  report: reportServiceReducer,
};

export default rootReducer;
