import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
/**
 * please change reducer name
 */
// @ts-ignore
type MyState = RootState['checkIn']; // ex: RootState['booking']
// @ts-ignore
const getCurrentState = (state: RootState): MyState => state['checkIn']; // ex: state['booking']

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getCustomerData = () => selector('customer') as MyState['customer'];

const getSelectedServices = () => selector('selectedServices') as MyState['selectedServices'];

const getSelectedStaff = () => selector('selectedStaff') as MyState['selectedStaff'];

const getInfoWalkInTicket = () => selector('infoWalkInTicket') as MyState['infoWalkInTicket'];

const checkInSelectors = {
  getCustomerData,
  getSelectedServices,
  getSelectedStaff,
  getInfoWalkInTicket,
};
export default checkInSelectors;
