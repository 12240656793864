import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types';

const initialState: IState = {
  detail: null,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.getInfo.success, (state, { payload }) => {
        state.detail = payload;
      })
      ;
  },
});

const giftCardPaidServiceReducer = Slice.reducer;
export default giftCardPaidServiceReducer;
