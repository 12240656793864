const logo = () => (
    <svg width="133" height="36" viewBox="0 0 133 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="106786A8-CBC9-4063-BD57-061526D4AA1D 1">
            <g id="#f38375ff">
                <path id="Vector" d="M81.3437 4.27938C81.8877 4.21798 82.4434 4.2187 82.9859 4.29961C83.7346 4.47153 84.4797 4.86161 84.8862 5.53053C85.2964 6.15177 85.3322 6.9695 85.0566 7.64997C84.8021 8.18453 84.3744 8.64829 83.8311 8.90184C83.1153 9.28398 82.2752 9.31215 81.4833 9.2428C80.603 9.133 79.7227 8.67863 79.2664 7.90497C78.8029 7.13275 78.8694 6.10048 79.4017 5.37739C79.8594 4.76843 80.5972 4.40507 81.3437 4.27938Z" fill="#F38375" />
                <path id="Vector_2" d="M79.6619 11.8204C81.2778 11.8168 82.8929 11.8088 84.5088 11.8233C84.5176 18.1346 84.5176 24.446 84.5088 30.7574C82.8936 30.7473 81.2778 30.7552 79.6626 30.7523C79.6794 26.3906 79.6655 22.0282 79.6699 17.6658C79.6648 15.7169 79.6801 13.7686 79.6619 11.8204Z" fill="#F38375" />
            </g>
            <g id="#000000ff">
                <path id="Vector_3" d="M2.92969 5.70068C10.4885 5.70646 18.0481 5.69996 25.607 5.70357C25.6026 7.19166 25.607 8.67902 25.6048 10.1671C19.7642 10.1649 13.9243 10.1657 8.08444 10.1664C8.08517 12.3472 8.08371 14.5273 8.08517 16.7075C13.044 16.7067 18.0028 16.706 22.9616 16.7082C22.9623 18.1045 22.9594 19.5009 22.963 20.8979C18.0035 20.9008 13.044 20.8994 8.08444 20.8979C8.0859 24.1775 8.08298 27.4564 8.0859 30.7359C6.36692 30.7417 4.64794 30.7367 2.92896 30.7381C2.93115 22.3925 2.92969 14.047 2.92969 5.70068Z" fill="white" />
                <path id="Vector_4" d="M93.5121 5.96169C95.1148 5.95952 96.7168 5.95952 98.3196 5.96097C98.3218 7.9186 98.3218 9.87622 98.3196 11.8339C99.8616 11.8317 101.403 11.8346 102.945 11.8324C102.941 13.0222 102.942 14.2126 102.943 15.4024C101.402 15.398 99.8609 15.4002 98.3196 15.4009C98.3232 20.5124 98.3203 25.6239 98.321 30.7354C96.7176 30.7427 95.1141 30.7419 93.5114 30.7354C93.515 25.6232 93.5121 20.511 93.5121 15.3988C91.9708 15.4017 90.4302 15.3988 88.8889 15.4002C88.8926 14.2112 88.894 13.0214 88.8882 11.8324C90.4288 11.8346 91.9693 11.831 93.5107 11.8346C93.515 9.87695 93.5121 7.91932 93.5121 5.96169Z" fill="white" />
                <path id="Vector_5" d="M56.8622 11.8344C63.1049 11.8337 69.3469 11.8229 75.5897 11.8272C71.6596 16.8173 67.7267 21.8046 63.7879 26.7875C67.5037 26.7817 71.2202 26.7991 74.936 26.7897C74.9316 28.1073 74.9389 29.4256 74.9331 30.7432C68.2707 30.7389 61.609 30.744 54.9473 30.7411C58.8429 25.7495 62.7584 20.7723 66.6614 15.7858C63.3952 15.7822 60.1298 15.7901 56.8637 15.7815C56.8673 14.466 56.8702 13.1506 56.8622 11.8344Z" fill="white" />
            </g>
            <g id="#747a7aff">
                <path id="Vector_6" d="M40.0001 11.4804C40.7064 11.4139 41.4127 11.497 42.1197 11.497C43.2333 11.6147 44.3578 11.7657 45.4151 12.1464C46.234 12.3956 47.0039 12.772 47.7614 13.1635C48.2418 13.4705 48.7507 13.7436 49.1646 14.1409C49.4292 14.2889 49.6062 14.5454 49.8636 14.7058C50.4353 15.3104 50.9859 15.9461 51.4063 16.6663C52.1163 17.851 52.5353 19.2105 52.6274 20.5844C52.7056 21.0973 52.6895 21.6246 52.6098 22.1361C52.6128 22.5536 52.5002 22.9574 52.4365 23.367C52.3897 23.4609 52.3788 23.5642 52.3634 23.6653C52.041 24.7901 51.5335 25.8722 50.8024 26.7961C49.5791 28.4323 47.7841 29.5714 45.8757 30.283C43.4951 31.1513 40.8914 31.29 38.3907 30.9808C36.8816 30.7923 35.4171 30.3242 34.0659 29.6386C33.8919 29.557 33.7405 29.4284 33.5497 29.3865C33.3135 29.1785 33.0313 29.0217 32.7563 28.8693C32.5304 28.6273 32.2204 28.4828 31.9879 28.2473C31.7627 28.009 31.4695 27.8406 31.2852 27.5647C30.829 27.1349 30.4641 26.6242 30.1183 26.1069C29.3447 24.8681 28.8782 23.4465 28.7766 21.9945C28.7934 21.3451 28.7225 20.6884 28.8439 20.047C28.8607 19.7031 28.9616 19.3708 29.0142 19.032C29.1882 18.4917 29.3513 17.9463 29.5962 17.432C30.1483 16.2451 30.984 15.1991 31.974 14.3395C32.4324 13.9935 32.8631 13.6012 33.3837 13.3462C34.0681 12.8644 34.8614 12.5711 35.6364 12.2656C37.033 11.7621 38.5172 11.5028 40.0001 11.4804ZM39.5021 15.7409C37.9213 15.9323 36.3581 16.5897 35.2686 17.7614C34.4826 18.6275 33.962 19.7522 33.9138 20.921C33.8012 22.118 34.1653 23.3381 34.8687 24.3147C35.1327 24.6976 35.4895 25.0032 35.8214 25.3261C37.457 26.6523 39.6864 27.0598 41.749 26.8337C43.5287 26.6581 45.3332 25.8924 46.4351 24.4578C47.4763 23.1351 47.8141 21.2996 47.2752 19.7067C46.9476 18.6094 46.1975 17.6733 45.274 16.9993C44.224 16.2589 42.9635 15.8298 41.681 15.7221C41.2409 15.733 40.8029 15.6289 40.3642 15.6925C40.0783 15.7294 39.788 15.7048 39.5021 15.7409Z" fill="#747A7A" />
            </g>
            <g id="#6babb7ff">
                <path id="Vector_7" d="M117.095 11.4997C117.998 11.4195 118.905 11.5011 119.807 11.5633C121.969 11.772 124.074 12.5197 125.869 13.7289C126.336 14.0829 126.794 14.4484 127.226 14.8428C127.693 15.3738 128.166 15.9069 128.531 16.5144C129.312 17.772 129.776 19.2211 129.856 20.6948C129.859 21.4316 129.889 22.1749 129.737 22.9009C129.463 24.4879 128.746 25.9962 127.684 27.2171C126.963 28.0557 126.086 28.7521 125.134 29.3213C123.976 29.9498 122.753 30.4865 121.452 30.7466C119.678 31.1403 117.837 31.1944 116.032 31.0124C114.5 30.847 112.984 30.4569 111.609 29.7627C110.249 29.127 109.007 28.2175 108.063 27.0552C107.013 25.8048 106.331 24.2589 106.099 22.6509C105.906 21.1477 106.017 19.5931 106.542 18.1621C106.91 17.1681 107.448 16.2348 108.141 15.4265C108.353 15.2242 108.528 14.9902 108.726 14.7764C109.357 14.2346 109.992 13.6863 110.727 13.2832C111.989 12.5731 113.354 12.0118 114.789 11.7583C115.548 11.6051 116.32 11.5163 117.095 11.4997ZM116.854 15.7299C115.31 15.8982 113.796 16.5202 112.67 17.5893C112.308 17.9692 111.986 18.3889 111.747 18.8549C111.022 20.1653 110.927 21.7985 111.491 23.1833C111.94 24.3355 112.834 25.2912 113.914 25.8994C115.403 26.7555 117.187 27.0025 118.883 26.84C120.17 26.7237 121.449 26.309 122.502 25.5585C123.534 24.8217 124.319 23.7287 124.607 22.4964C125.008 20.8862 124.569 19.0896 123.444 17.8544C122.557 16.8488 121.295 16.211 119.996 15.8975C118.969 15.6519 117.901 15.6605 116.854 15.7299Z" fill="#6BABB7" />
            </g>
            <g id="#ffdf7aff">
                <path id="Vector_8" d="M39.5026 15.7395C39.7885 15.7034 40.0787 15.728 40.3646 15.6911C40.8033 15.6276 41.2413 15.7316 41.6815 15.7207C42.9639 15.8284 44.2245 16.2575 45.2744 16.9979C46.1979 17.6719 46.9481 18.6081 47.2756 19.7053C47.8145 21.2982 47.4767 23.1337 46.4355 24.4564C45.3337 25.891 43.5291 26.6567 41.7495 26.8323C39.6868 27.0584 37.4575 26.651 35.8218 25.3247C35.4899 25.0018 35.1331 24.6962 34.8691 24.3134C34.1657 23.3367 33.8016 22.1166 33.9142 20.9197C33.9625 19.7509 34.4831 18.6261 35.2691 17.76C36.3585 16.5883 37.9218 15.931 39.5026 15.7395Z" fill="#FFDF7A" />
            </g>
        </g>
    </svg>

);

export default logo;
