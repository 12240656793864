import { createAction, createAsyncAction } from 'services/actionConfigs';
import { IStaffItemData } from 'services/shop/types/staff';
import { PREFIX_ACTIONS } from './constants';
import { IStateCustomerInfo } from './types/customer';

const setCustomerInfo = createAction<IStateCustomerInfo | null>(PREFIX_ACTIONS + 'setCustomerInfo');
const setSelectedServices = createAction<string[]>(PREFIX_ACTIONS + 'setSelectedServices');
const setSelectedStaff = createAction<IStaffItemData | null>(PREFIX_ACTIONS + 'setSelectedStaff');
const getInfoCheckInTicket = createAsyncAction<{ type: string, id: string }>(PREFIX_ACTIONS + 'getInfoCheckInTicket');
const reset = createAction(PREFIX_ACTIONS + 'reset');
const checkInActions = {
  setCustomerInfo,
  setSelectedServices,
  reset,
  setSelectedStaff,
  getInfoCheckInTicket,
};

export default checkInActions;

