import { Col, Row } from 'antd';
import Empty from 'components/Empty';
import Text from 'components/Text';
import { ICategoryItemData, IServiceItemData } from 'services/shop/types/categories';
import styled from 'styled-components';
type Props = {
  services: IServiceItemData[];
  activeCategory: ICategoryItemData | null;
  onChooseServices: (o: IServiceItemData) => void;
};
const Services = ({ activeCategory, onChooseServices, services = [] }: Props) => {
  return (
    <>
      <Text mb={1} className='section-label'>Services</Text>
      <div className='services'>
        <Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0 }}>
          {activeCategory?.services.length ? activeCategory?.services.map(o => (
            <Col key={o.id} xs={12} sm={12} md={12} lg={12} xl={8}>
              <ServiceItem
                onClick={() => onChooseServices(o)}
                selected={!!services.find(a => a.id === o.id)}
                name={o.serviceName}
                duration={o.duration}
              />
            </Col>
          )) : <Empty />}
        </Row>
      </div>
    </>
  );
};

export default Services;


type ServiceItemProps = {
  selected?: boolean;
  name?: string;
  duration?: number;
  onClick: () => void;
};
const ServiceItem = ({ selected = false, duration = 0, name = '', onClick }: ServiceItemProps) => {
  return (
    <ServiceItemStyled onClick={onClick}>
      <div className='content'>
        <Text variant='BODY_1' className='service-name text-overflow'>{name}</Text>
        <Text variant='BODY_1' className='service-price' style={{ fontWeight: '600' }}>({duration} minute{duration > 1 ? 's' : ''})</Text>
      </div>
      {selected ? <IconChecked /> : <IconCheckEmpty />}
    </ServiceItemStyled>
  );
};

const ServiceItemStyled = styled.button`
  width: 100%;
  border-radius: 5px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  text-align: left !important;

  border-radius: 5px;
  background: var(--fill-fill-1, #F6F7FC);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 75%;
    .service-name {
      color: var(--text-text-3, #1D2129);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .service-price {
      color: var(--text-text-3, #1D2129);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`;

const IconChecked = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <rect y="0.838867" width="24" height="24" rx="2" fill="#0561AE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.7969 6.98008C16.5899 6.77367 16.2507 6.78816 16.062 7.01149L10.4252 13.683L8.21622 11.3763C8.02224 11.1737 7.69963 11.1703 7.50139 11.3687L6.36968 12.5014C6.17754 12.6937 6.17424 13.0043 6.36226 13.2006L10.0807 17.0836C10.2194 17.2285 10.4238 17.2715 10.6015 17.2118C10.7445 17.2129 10.8873 17.1532 10.9876 17.0345L11.7292 16.1568L12.2729 15.6126L12.2283 15.566L17.9575 8.78508C18.1255 8.58633 18.1129 8.292 17.9286 8.10829L16.7969 6.98008Z" fill="white" />
  </svg>
);

const IconCheckEmpty = () => (
  <div style={{ width: '24px', height: '24px', background: '#fff', border: '1px solid #86909C' }} />
);