import { AutoComplete, Button, Col, Form, Input, Row } from 'antd';
import CurrencyInput from 'components/CurrencyInput';
import { LoadingFull } from 'components/Loading';
import PhoneInput from 'components/PhoneInput';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import giftCardActions from './services/actions';
import giftCardApis from './services/apis';
import giftCardSelectors from './services/selectors';
import { IBodyAPIBuyGiftCard } from './services/types/api';
import { IBuyGiftCardResData } from './services/types/giftCard';
type IBuyGiftCardFormValues = {
  name: string;
  email: string;
  phone: string;
  amount: string;
  message: string;
};

const BuyGiftCardPage = () => {
  const { shop_id = '', id = '' } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const detail = giftCardSelectors.detail();
  const loading = giftCardSelectors.detailLoading();
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    dispatch(giftCardActions.getGiftCardDetail.fetch({ id, shopId: shop_id }));
  }, []);

  const bankNameOptions = useMemo(() => {
    return detail?.amounts.map(o => ({ value: parseFloat(o)?.toFixed(2) }));
  }, [detail]);

  const handleBack = () => navigate(`/store/${shop_id}/shopping/gift-card`);

  const handleFinish = async (values: IBuyGiftCardFormValues) => {
    const phoneNumber = values.phone?.replace(/[^0-9]/g, '');
    const amount = parseFloat(values.amount || '');

    const body: IBodyAPIBuyGiftCard = {
      amount,
      customerName: values.name || '',
      email: values.email || null,
      giftCardTemplateId: id,
      message: values.message,
      phoneNumber: '+1' + phoneNumber,
    };
    setProcessing(true);
    try {
      const res: IResponseDataBody<IBuyGiftCardResData> = await giftCardApis.buyGiftCard(body, shop_id);
      const resData = res.data.data;
      if (resData) {
        window.location.replace(resData.information);
      }
    } catch (error) {
      setProcessing(false);
    }
    finally { }
  };

  if (loading || processing) return <LoadingFull />;

  return (
    <PageStyled className='container'>
      <div className="top-bar">
        <button onClick={handleBack}>
          <Arrow />
        </button>
        <p className='title'>Happy Day Gift Card</p>
      </div>
      <div className='body'>
        <GiftCardItem className='btn gift-card-item'>
          <img src={detail?.urlImage} />
          <div className="content">
            <p className="name">
              {detail?.giftName}
            </p>
            <p className="desc">
              Expires Date: {detail?.numberDayExpired || 0} Days
            </p>
          </div>
        </GiftCardItem>
        <Form onFinish={handleFinish} validateTrigger={['onSubmit']} layout='vertical'>
          <Row gutter={{ xs: 0, sm: 24 }}>
            <Col xs={24} sm={12}>
              <Form.Item label={<>Your Name <span className='require-input'>(*)</span></>} name={'name'}
                rules={[{ required: true, message: 'Please enter your name' }]}
              >
                <Input placeholder='Please enter...' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label={'Email'} name={'email'}
                rules={[{ type: 'email' }]}
              >
                <Input placeholder='Please enter...' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label={<>Phone <span className='require-input'>(*)</span></>} name={'phone'}
                rules={[{ required: true, message: 'Please enter your phone number' }]}
              >
                {/* <Input placeholder='(000) 000-0000' /> */}
                <PhoneInput isNormal style={{height:'3.5rem'}} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label={<>Amount <span className='require-input'>(*)</span></>} name={'amount'}
                rules={[{ required: true, message: 'Please enter your amount' }]}
              >

                <AutoComplete
                  options={bankNameOptions}
                  filterOption={(inputValue, option) => {
                    return String(option?.value).includes(inputValue?.toString());
                  }}
                >
                  <CurrencyInput />
                </AutoComplete>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={'Message'} name={'message'}>
                <Input.TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>
          <Button className='button button-submit-sm' htmlType='submit'>
            <p className="text">CHECK OUT</p>
          </Button>
          <div className="sticky-bottom">
            <Button className='button button-submit-xs' htmlType='submit'>
              <p className="text">CHECK OUT</p>
            </Button>
          </div>
        </Form>
      </div>
    </PageStyled>
  );
};

export default BuyGiftCardPage;

const PageStyled = styled.div`
  padding: 0 5%;
  background: #F7F7F7;
  min-height: 100vh;
  .top-bar {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem 0;
    .title {
      color: var(--text-text-3, #1D2129);
      font-family: Poppins;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .body {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }
 
  .ant-form {
    width: 100%;
    label {
      color: var(--text-text-3, #1D2129);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      .require-input {
        color: #F00;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .ant-form-item-control-input {
      .ant-input {
        border-radius: 5px;
        border: 1px solid var(--line-line-1, #DADADA);
        background: #FFF;
        display: flex;
        width: 100%;
        height: 46px;
        align-items: center;

        color: var(--text-text-2, #566A7F);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal; 
      }
      textarea.ant-input {
        height: unset;
      }
    }
    .ant-select-selector {
      border-radius: 5px;
      border: 1px solid var(--line-line-1, #DADADA);
      background: #FFF;
      display: flex;
      width: 100%;
      height: 46px;
      align-items: center;

      .ant-select-selection-search-input {
        color: var(--text-text-2, #566A7F);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        height: 100%;
      }
    }
  }


  .button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 2px;
    background: #0561AE;
    width: 100%;
    height: 60px;
    .text {
      color: var(--text-text-1, #FFF);
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &.button-submit-xs {
      display: none;
    }
  }
  .sticky-bottom {
    display: none;
  }
 
  @media only screen and (max-width: 768px) {
    padding: 0 16px;
    .top-bar {
      gap: 0.5rem;
    }
    .body {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0;
    }
    .gift-card-item {
      margin-bottom: 1rem;
      width: 60%;
      padding: 4px;
      gap: 0px;
      .content {
        text-align: left;
        padding: 0.5rem 0.5rem;
        .name {
          font-size: 12px;
        }
        .desc {
          font-size: 10px;
        }
      }
    }
    .ant-form {
      width: 100%;
      label {
        font-size: 14px;
        .require-input {
          color: #F00;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    .sticky-bottom {
      position: sticky;
      bottom:0;
      padding: 16px;
      padding-bottom: 1rem;
      background: #fff;
      margin: 0 -16px;
    }
    .button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 2px;
      background: #0561AE;
      width: 100%;
      height: 60px;
      .text {
        color: var(--text-text-1, #FFF);
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      &.button-submit-sm {
        display: none;
      }
      &.button-submit-xs {
        display: flex;
      }
    }

    .sticky-bottom {
      display: block;
    }

  }
`;

const Arrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M3.29289 11.2929C2.90237 11.6834 2.90237 12.3166 3.29289 12.7071L9.65685 19.0711C10.0474 19.4616 10.6805 19.4616 11.0711 19.0711C11.4616 18.6805 11.4616 18.0474 11.0711 17.6569L5.41421 12L11.0711 6.34315C11.4616 5.95262 11.4616 5.31946 11.0711 4.92893C10.6805 4.53841 10.0474 4.53841 9.65685 4.92893L3.29289 11.2929ZM20 11L4 11V13L20 13V11Z" fill="#8E9BA9" />
  </svg>
);

const GiftCardItem = styled.button`
border-radius: 10px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
padding: 10px;
width: 60%;
display: flex;
flex-direction: column;
align-items: start;
text-align: left;
gap: 16px;
  img {
    height: 100%;
    width: 100%;
  }
  .ant-image {
    width: 100%; 
    height: 9.375rem;  
  }
  .content {
    padding: 0.5rem 1rem;
  }
  .name {
    color: var(--text-text-3, #1D2129);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .desc {
    color: var(--text-text-2, #566A7F);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;