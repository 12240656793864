import ButtonAnthUI, { ButtonProps as ButtonAntProps } from 'antd/es/button';
import Icon, { typeIcon } from 'assets/Icons';
import Text, { ITextProps } from 'components/Text';
import { BUTTON_SIZES } from 'constants/size';
import React, { FC, memo, useMemo } from 'react';
import styled from 'styled-components';
import { ISpacing, generateCssSpacing } from 'styles/spacing';

export enum BUTTON_VARIANT_TYPES {
  PRIMARY = 'PRIMARY',
  PRIMARY_DEFAULT = 'PRIMARY_DEFAULT',
  PRIMARY_DEFAULT_MOBILE = 'PRIMARY_DEFAULT_MOBILE',
  OUTLINE_PRIMARY = 'OUTLINE_PRIMARY',
  SECONDARY = 'SECONDARY',
  SECONDARY_MOBILE = 'SECONDARY_MOBILE',
  DEFAULT = 'DEFAULT',
  DANGER = 'DANGER',
  YELLOW = 'YELLOW',
  LIGHT_BLUE = 'LIGHT_BLUE',
  TEXT = 'TEXT',
  CIRCLE = 'CIRCLE',
  SIDE_BAR = 'SIDE_BAR',
  ORANGE = 'ORANGE',
  DARK = 'DARK',
  BLACK = 'BLACK',
  WHITE = 'WHITE',
}

// enum BUTTON_SIZES {
//   LARGE:
// };
type Props = ButtonAntProps & ISpacing;
export interface ButtonProps extends Props {
  ntype?: keyof typeof BUTTON_VARIANT_TYPES;
  icon?: typeIcon;
  isSelected?: boolean;
  textWidth?: string;
  width?: string; // if just isModal, it will be default modal
  vertical?: boolean;
}

const BUTTON_VARIANT_CSS = {
  [BUTTON_VARIANT_TYPES.DEFAULT]: `
  background: #ffffff;
  border: 1px solid #86909C;
  &:hover {
    border: 1px solid #86909C !important;
    background-color: #E5E6EB !important;
  }
  &:disabled {
    background-color: #E5E6EB !important;
  }
    `,
  [BUTTON_VARIANT_TYPES.DANGER]: `
    background: #FFE5E7;
    border: 1px solid #F5767F;
    &:hover {
      border: 1px solid #F5767F !important;
      background-color: #FF8890 !important;
    }
    &:disabled {
      background-color: #FF8890 !important;
    }
    `,
  [BUTTON_VARIANT_TYPES.SECONDARY]: `
    background: #E5E6EB;
    border: 1px solid #86909C;
    &:hover {
      border: 1px solid #86909C !important;
      background-color: #ADADAD !important;
    }
    &:disabled {
      background-color: #ADADAD !important;
    }
    `,
  [BUTTON_VARIANT_TYPES.SECONDARY_MOBILE]: `
    border-radius: 5px;
    border: 1px solid #BEBFC3;
    background: #E5E5E5;
    box-shadow: none;
    div {
      color: #686868;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 137.5%; /* 22px */
    }
    &:hover {
      div {
        color: #FFF;
        transition: 0.3s;
      }
      border: 1px solid #86909C !important;
      background-color: #ADADAD !important;
    }
    &:disabled {
      background-color: #ADADAD !important;
    }
    `,
  [BUTTON_VARIANT_TYPES.PRIMARY]: `
    border-radius: 2px;
    background: #0561AE;
    &:disabled {
      opacity: 0.8;
    }
    `,
  [BUTTON_VARIANT_TYPES.PRIMARY_DEFAULT_MOBILE]: `
    border-radius: 2px;
    background: #0561AE;
    box-shadow: none;
    div {
      color: #FFF;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 137.5%; /* 22px */
    }
    &:disabled {
      opacity: 0.8;
    }
    `,
  [BUTTON_VARIANT_TYPES.PRIMARY_DEFAULT]: `
    border-radius: 5px;
    background: #FF8890;
    box-shadow: none;
    div {
      color: #FFF;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 137.5%; /* 22px */
    }
    &:hover {
      border-color: #FF8890 !important;
      background: #ed6b73;
    }
    &:disabled {
      opacity: 0.5;
    }
    `,
  [BUTTON_VARIANT_TYPES.OUTLINE_PRIMARY]: `
    background: #fff !important;
    border: 1.5px solid #F5767F !important;
    &:hover {
      border: 1.5px solid #F5767F !important;
      background-color: #E5E6EB !important;
    }
    &:disabled {
      background-color: #ADADAD !important;
    }
    `,
  [BUTTON_VARIANT_TYPES.YELLOW]: `
    background: #FEEDBF;
    border: 1px solid #FDCC43;
    &:hover {
      border: 1px solid #FDCC43 !important;
      background-color: #FDCC43 !important;
    }
    &:disabled {
      background-color: #FEEDBF !important;
    }
    `,
  [BUTTON_VARIANT_TYPES.LIGHT_BLUE]: `
    background: #C6E9EF;
    border: 1px solid #6FABB6;
    &:hover {
      border: 1px solid #6FABB6 !important;
      background-color: #6FABB6 !important;
    }
    &:disabled {
      background-color: #C6E9EF !important;
    }
    `,
  [BUTTON_VARIANT_TYPES.ORANGE]: `
    border: 1px solid var(--primary-primary-hover, #F5767F);
    background: var(--orange, #FF7D00);
    &:hover {
      border: 1px solid #F5767F !important;
      background-color: #F5767F !important;
    }
    &:disabled {
      background-color: #C6E9EF !important;
    }
    `,
  [BUTTON_VARIANT_TYPES.TEXT]: `
    background: #f6f7fc;
    border: 0px;
    box-shadow: none;
    &:hover {
      border: 0px solid #ffffff !important;
      background-color: #E5E6EB !important;
    }
    &:disabled {
      background-color: #E5E6EB !important;
    }
    `,
  [BUTTON_VARIANT_TYPES.CIRCLE]: `
    fill: var(--fill-fill-0, #FFF);
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    margin: auto;
    border: none;
    `,
  [BUTTON_VARIANT_TYPES.SIDE_BAR]: `
      background: #FFF;
      border: #fff;
      box-shadow: none;
      &:hover {
        border: 1px solid #F5767F !important;
        background-color: #FFE5E7 !important;
      }
      &:disabled {
        background-color: #FEEDBF !important;
      }
    `,
  [BUTTON_VARIANT_TYPES.DARK]: `
    border-radius: 5px;
    border: 1px solid var(--line-line-3, #86909C);
    background: var(--fill-fill-2, #ADADAD);

    &:hover {
      border: 1px solid var(--line-line-3, #86909C);
      background: var(--fill-fill-2, #ADADAD);
    }
    &:disabled {
      background-color: #ADADAD !important;
    }
    `,
  [BUTTON_VARIANT_TYPES.BLACK]: `
    border-radius: 5px;
    border: 1px solid #1D2129;
    background: #1D2129;

    &:hover {
      border: 1px solid rgba(29, 33, 41, 0.8);
      background: rgba(29, 33, 41, 0.8);
    }
    &:disabled {
      background-color: rgba(29, 33, 41, 0.8) !important;
    }
    `,
  [BUTTON_VARIANT_TYPES.WHITE]: `
    border-radius: 5px;
    border: 1px solid #1D2129;
    background: #FFF;

    &:hover {
      border: 1px solid #1D2129;
      background-color: #E5E6EB !important;
    }
    &:disabled {
      background-color: #E5E6EB !important;
    }
    `,
};

const ButtonStyled = styled(ButtonAnthUI)<ButtonProps>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  padding: 12px 16px;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  &:disabled {
    border: transparent !important;
    opacity: 0.5;
  }
  ${({ width }) => width && `width: ${width};`}

  &.${BUTTON_VARIANT_TYPES.SIDE_BAR}.selected {
    background: #ff8890;
    border: 1px solid #f5767f;
    &:hover {
      border: 1px solid #f5767f !important;
      background-color: #f5767f !important;
    }
  }

  ${Object.entries(BUTTON_VARIANT_CSS).map(
    ([key, css]) => `
      &.${key} {
        ${css}
      }
    `
  )}

  ${({ vertical }) =>
    vertical
      ? `
    flex-direction: column;
      svg {
        height: 100%;
        width: 100%;
      }
    gap: 4px;
  `
      : ''}
`;

const Button: FC<ButtonProps> = ({
  icon,
  ntype = BUTTON_VARIANT_TYPES.DEFAULT,
  children,
  isSelected,
  width,
  textWidth,
  ...props
}) => {
  const styles: React.CSSProperties = useMemo(() => {
    const shape =
      ntype !== BUTTON_VARIANT_TYPES.CIRCLE
        ? {
            ...props.style,
            height: props.size ? BUTTON_SIZES[props.size] : BUTTON_SIZES.middle,
          }
        : {};

    return {
      ...generateCssSpacing(props),
      ...shape,
    };
  }, [props]);

  const labelColor = useMemo(() => {
    switch (ntype) {
      case 'BLACK':
        return 'text_1' as ITextProps['color'];
        break;

      default:
        return props.disabled ? 'fill_4' : undefined;
    }
  }, [props]);

  return (
    <ButtonStyled
      {...props}
      width={width}
      // isSelected={isSelected}
      className={`${ntype} ${props.className} ${
        isSelected && ntype === BUTTON_VARIANT_TYPES.SIDE_BAR ? 'selected' : ''
      } `}
      style={styles}
    >
      {icon ? (
        props.vertical ? (
          <div style={{ width: '20px', height: '20px' }}>
            {' '}
            <Icon type={icon} />
          </div>
        ) : (
          <Icon type={icon} />
        )
      ) : null}
      {children && (
        <Text color={labelColor} variant="CONTENT_2" style={{ width: textWidth }}>
          {children}
        </Text>
      )}
    </ButtonStyled>
  );
};

export default memo(Button);
