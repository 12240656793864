import { get } from 'lodash';
import uiSelector from 'services/UI/selectors';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { PATH_LOADING } from './constants';

type MyState = RootState['giftCard'];
const getCurrentState = (state: RootState): MyState => state['giftCard'];

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const list = () => selector('list') as MyState['list'];

const getListLoading = () => uiSelector.getLoading(PATH_LOADING.getGiftCardToBuy) as boolean;

const detail = () => selector('detail') as MyState['detail'];

const detailLoading = () => uiSelector.getLoading(PATH_LOADING.getGiftCardDetail) as boolean;

const giftCardSelectors = {
  list,
  detail,
  getListLoading,
  detailLoading,
};
export default giftCardSelectors;
