const checkOutline = () => (
  <svg
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14.5" cy="14" r="14" fill="#FF8890" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.4939 7.43558C22.8056 7.70834 22.8372 8.18216 22.5644 8.49389L12.0644 20.4939C11.9277 20.6501 11.7324 20.7427 11.525 20.7496C11.3175 20.7565 11.1164 20.6771 10.9697 20.5303L6.46967 16.0303C6.17678 15.7374 6.17678 15.2626 6.46967 14.9697C6.76256 14.6768 7.23744 14.6768 7.53033 14.9697L11.4635 18.9028L21.4356 7.50613C21.7083 7.1944 22.1822 7.16282 22.4939 7.43558Z"
      fill="white"
    />
  </svg>
);

export default checkOutline;
